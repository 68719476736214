import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Avatar,
  Box,
  Text,
  Tag,
  TagLabel,
  Icon,
  chakra,
  Stack,
  useBreakpointValue,
  Button,
} from '@chakra-ui/react';
import { differenceInMinutes } from 'date-fns';
import { ReactComponent as ArrowForward } from '@src/icons/arrow-forward.svg';

import Link from '@components/atoms/Link';
import DateFormat from '@src/components/atoms/DateFormat';
import DaySuffix from '@src/components/atoms/DaySuffix';

const MeetingItem = ({ participant, times, meeting }: Meeting) => {
  const { startsAt, endsAt } = times;
  const duration = differenceInMinutes(endsAt, startsAt);
  const { t } = useTranslation();
  const btnVariant = useBreakpointValue({ base: 'solid', md: 'link' });
  const btnColor = useBreakpointValue({ base: 'white', md: 'primary' });
  const getStatusColor = (status: string) => {
    switch (status) {
      case 'pending':
        return 'yellow';
      case 'payed':
        return 'green';
      default:
        return 'blue';
    }
  };
  return (
    <chakra.div py={5}>
      <Stack
        w="full"
        color="blue.500"
        pos="relative"
        direction={{ base: 'column', md: 'row' }}
        alignItems={{ md: 'center' }}
      >
        <chakra.span
          pos="absolute"
          top={16}
          left={{ base: 5, md: 12 }}
          ml={-1}
          h="full"
          w="0.125rem"
          bg="blue.100"
          aria-hidden="true"
        />
        <Box d="flex" alignItems="center" flexShrink={0} pos="relative">
          <Box
            d="flex"
            flexDir="row"
            alignItems="center"
            backgroundColor="blue.100"
            px={3}
            py={1}
            minW="140px"
            rounded="lg"
            pr="30px"
          >
            <DateFormat
              date={startsAt}
              format="d"
              fontSize="3xl"
              as="strong"
              marginRight={1}
            />
            <Box
              d="flex"
              flexDir="column"
              as="span"
              fontSize="xs"
              textTransform="uppercase"
              lineHeight={'1em'}
            >
              <DaySuffix date={startsAt} />
              <DateFormat date={startsAt} format="MMMM" />
            </Box>
          </Box>
          <Box
            ml="-20px"
            backgroundColor="teal.50"
            minW="80px"
            w="80px"
            h="80px"
            borderRadius="40px"
            d="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Avatar
              size="lg"
              name={
                `${participant.firstName} ${participant.lastName}` as string
              }
              src={participant.avatar as string}
            />
          </Box>
        </Box>

        <Box pl={{ base: 8, md: 0 }} flex="1 1 0%">
          <Text fontSize="lg" color="blue.500" fontWeight="300" mb={1}>
            {participant.firstName} {participant.lastName}
          </Text>
          <Box lineHeight="1rem">
            {participant.title && (
              <Text fontSize="md" color="gray.500" fontWeight="400">
                {t(`fields.title_options.${participant.title}`)}
              </Text>
            )}
            {participant.location?.name && (
              <Text as="strong" fontSize="xs" color="gray.500">
                {participant.location.name}
              </Text>
            )}
          </Box>
        </Box>

        <Box
          d="flex"
          alignItems={{ md: 'flex-start' }}
          pl={{ base: 8, md: 0 }}
          flexDir={{ base: 'row', md: 'column' }}
          flex={{ md: '1 1 0%' }}
        >
          <Box as="span" textAlign="left" color="gray.600" fontWeight="light">
            <DateFormat date={startsAt} format="p" /> -{' '}
            <DateFormat date={endsAt} format="p" />
          </Box>
          <Tag
            ml={{ base: 3, md: 0 }}
            mt={{ base: 0, md: 1 }}
            size="sm"
            borderRadius="full"
            variant="outline"
            colorScheme="blue"
          >
            <TagLabel>{duration} min</TagLabel>
          </Tag>
        </Box>
        {participant.type === 'lawyer' && (
          <Box
            d="flex"
            alignItems={{ lg: 'flex-end' }}
            justifyContent={{ base: 'center', lg: 'flex-end' }}
          >
            <Button
              mt={{ base: 3, md: 0 }}
              as={Link}
              to={`/booking/new/${participant.id}`}
              color={btnColor}
              variant={btnVariant}
              fontSize="sm"
              colorScheme="primary"
            >
              <Text as="strong">
                {t('my_meetings.book_again')}
                <Icon ml={1} w={4} h={4} as={ArrowForward} />
              </Text>
            </Button>
          </Box>
        )}
        {participant.type === 'user' && (
          <Box
            d="flex"
            alignItems={{ lg: 'flex-end' }}
            justifyContent={{ base: 'center', lg: 'flex-end' }}
          >
            <Tag
              ml={{ base: 3, md: 0 }}
              mt={{ base: 0, md: 1 }}
              size="sm"
              borderRadius="full"
              variant="solid"
              colorScheme={getStatusColor(meeting.status)}
            >
              <TagLabel>{meeting.status} </TagLabel>
            </Tag>
          </Box>
        )}
      </Stack>
    </chakra.div>
  );
};

export default MeetingItem;
