import React, { ReactNode } from 'react';
import { Box } from '@chakra-ui/react';
import Header from './Header';
import Footer from '../Layout/Footer';
import Sidebar from '../Sidebar';

export interface SidebarLayoutProps {
  children: ReactNode;
}

const SidebarLayout = ({ children }: SidebarLayoutProps) => {
  return (
    <>
      <Box minH="100vh" d="flex">
        <Box d={{ base: 'none', lg: 'flex' }} flexShrink={{ lg: 0 }}>
          <Sidebar />
        </Box>
        <Box
          d="flex"
          flexDirection="column"
          flex="1 1 0%"
          backgroundColor="teal.50"
          color="black"
          pb={6}
        >
          <Header />
          <Box minH="100vh">{children}</Box>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default SidebarLayout;
