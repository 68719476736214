import React from 'react';
import { chakra, Text, Box } from '@chakra-ui/react';
import DateFormat from '@src/components/atoms/DateFormat';
import PayoutAmount from '@src/components/atoms/PayoutAmount';
import { useTranslation } from 'react-i18next';

export interface AppointmentLineProps {
  title?: string;
  id?: number;
  host?: { __typename?: 'LawyerType' } & LawyerFragment;
  meeting?: { __typename?: 'AppointmentMeetingType' } & MeetingFragment;
  user?: { __typename?: 'User' } & Pick<User, 'email'> & {
      profile: { __typename?: 'UserProfile' } & ProfileFragment;
    };
  times?: { __typename?: 'AppointmentTimes' } & Pick<
    AppointmentTimes,
    'startsAt' | 'endsAt'
  >;
  purchaseInfo?: Array<
    Maybe<
      { __typename?: 'PurchaseType' } & Pick<
        PurchaseType,
        'description' | 'invoiceUrl' | 'paid'
      > & {
          details: { __typename?: 'PurchaseDetailsType' } & Pick<
            PurchaseDetailsType,
            'total'
          >;
        }
    >
  >;
}

const AppointmentLineItemMobile = ({
  times,
  title,
  purchaseInfo,
  meeting,
  host,
}: AppointmentLineProps) => {
  const { t } = useTranslation();
  const payoutDate = meeting?.payoutDate
    ? meeting?.payoutDate.split('-')
    : null;
  return (
    <chakra.div rounded="xl" shadow="lg" bg="white" w="full" p={5}>
      <chakra.div>
        <chakra.div
          d="flex"
          justifyContent="space-between"
          mt={4}
          alignItems="center"
        >
          <Text fontWeight="light" fontSize="sm" textAlign="center">
            {title} (<DateFormat date={new Date(times?.startsAt)} />)
          </Text>
        </chakra.div>
        <chakra.div
          d="flex"
          flexDir="column"
          justifyContent="space-between"
          alignItems="center"
        >
          <Text fontWeight="semibold" ml={1}>
            <Box as="span">{t('common.payment_date')}:</Box>
            {payoutDate && (
              <span>{`${payoutDate[1]}/${payoutDate[2]}/${payoutDate[0]}`}</span>
            )}
            {!payoutDate && 'NA'}
          </Text>
          <Text fontWeight="semibold" ml={1}>
            <Box as="span">{t('common.status')}:</Box>
            {t(`meetings.status.${meeting?.status}`)}
          </Text>
          <Text fontWeight="semibold">
            <Box as="span">{t('common.amount')}:</Box>

            {purchaseInfo?.map((info) => {
              if (info) {
                return (
                  <PayoutAmount
                    amount={host?.hourlyRate || 0}
                    duration={meeting?.duration || 60}
                  />
                );
              } else {
                return t('meetings.no_payment_information');
              }
            })}
          </Text>
        </chakra.div>
      </chakra.div>
    </chakra.div>
  );
};

export default AppointmentLineItemMobile;
