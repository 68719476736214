import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CalendarIcon } from '@src/icons/calendar.svg';
// import { ReactComponent as ArrowForward } from '@src/icons/arrow-forward.svg';
import {
  Avatar,
  Box,
  Text,
  Button,
  Icon,
  Tag,
  TagLabel,
  Stack,
  useDisclosure,
  // useBreakpointValue,
} from '@chakra-ui/react';
import {
  isWithinInterval,
  formatDistanceStrict,
  differenceInMinutes,
  subMinutes,
  subHours,
  isAfter,
} from 'date-fns';
import { es } from 'date-fns/locale';

import DateFormat from '@src/components/atoms/DateFormat';
import Link from '@src/components/atoms/Link';
import CancelAppointmentModal from '@src/components/molecules/CancelAppointmentModal';

const MeetingItem = ({ times, participant, meeting, id }: Meeting) => {
  const { endsAt, startsAt } = times;
  const { t, i18n } = useTranslation();
  const currentDate = useMemo(() => new Date(), []);
  const cancelModal = useDisclosure();

  const isWithinRange = useMemo(() => {
    return isWithinInterval(currentDate, {
      start: subMinutes(startsAt, 10),
      end: endsAt,
    });
  }, [startsAt, endsAt, currentDate]);

  const isAfterStart = useMemo(() => {
    return isAfter(startsAt, currentDate);
  }, [startsAt, currentDate]);

  const isWithinCancelRange = useMemo(() => {
    return isWithinInterval(currentDate, {
      start: subHours(startsAt, 24),
      end: endsAt,
    });
  }, [startsAt, endsAt, currentDate]);

  const duration = differenceInMinutes(endsAt, startsAt);

  const canCancel = meeting.status === 'scheduled';
  const isCanceled = meeting.status === 'cancelled';
  return (
    <Box
      d="flex"
      backgroundColor={isWithinRange ? 'white' : 'blue.100'}
      rounded="xl"
      p={{ base: 2, md: 4 }}
      shadow={isWithinRange ? 'lg' : 'none'}
    >
      <CancelAppointmentModal
        appointmentId={id}
        isOpen={cancelModal.isOpen}
        onClose={cancelModal.onClose}
        otherParty={`${participant.firstName} ${participant.lastName}`}
      />
      <Stack
        d="flex"
        spacing={{ base: 4, md: 2 }}
        direction={{ base: 'column', md: 'row' }}
        w="full"
        p={4}
        borderRadius="2xl"
      >
        <Box d="flex" alignItems="center" w={{ md: '35%' }} flexShrink={0}>
          <Avatar
            name={`${participant.firstName} ${participant.lastName}` as string}
            src={participant.avatar as string}
            size="lg"
          />
          <Box pl={4}>
            <Text color="blue.600" fontWeight="300" fontSize="lg">
              {participant.firstName} {participant.lastName}
            </Text>
            {participant.title && (
              <Text fontSize="sm" color="gray.600">
                {t(`fields.title_options.${participant.title}`)}
              </Text>
            )}
          </Box>
        </Box>
        <Box
          d="flex"
          flexDirection="row"
          justifyContent={{ base: 'center', md: 'flex-start' }}
          w={{ md: '45%' }}
        >
          <Box pt={1}>
            <Icon color="gray.600" as={CalendarIcon} w={6} h={6} mr={2} />
          </Box>
          <Box>
            <Text fontSize="sm" as="strong" color="blue.600">
              <DateFormat date={startsAt} format="EEEE, LLLL do. yyyy" />
            </Text>
            <Stack
              direction={{ base: 'row', md: 'column' }}
              alignItems="flex-start"
              spacing={{ base: 3, md: 1 }}
            >
              <Text textAlign="left" color="gray.600">
                <DateFormat date={startsAt} format="p" /> -{' '}
                <DateFormat as="span" date={endsAt} format="p" />
              </Text>
              <Tag
                size="md"
                borderRadius="full"
                variant="outline"
                colorScheme="blue"
              >
                <TagLabel>{duration} min</TagLabel>
              </Tag>
            </Stack>
          </Box>
        </Box>
        <Box d="flex" justifyContent={{ base: 'center', md: 'flex-end' }}>
          <Box
            d="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            {isWithinRange && !isCanceled && (
              <Button
                as={Link}
                to={`/meeting/${meeting.roomId}`}
                colorScheme="blue"
                size="sm"
                px={6}
              >
                {t('home.join_meeting')}
              </Button>
            )}
            {isCanceled ? (
              <Text fontSize="sm" textAlign="center" color={'red.500'}>
                {t('meetings.is_canceled')}
              </Text>
            ) : !isAfterStart ? (
              <></>
            ) : (
              <Text color="primary" fontSize="sm" textAlign="center">
                {t('my_meetings.meeting_will_start')}{' '}
                {formatDistanceStrict(startsAt, currentDate, {
                  addSuffix: true,
                  locale: i18n.language === 'es' ? es : undefined,
                })}
              </Text>
            )}

            {isCanceled && participant.type === 'lawyer' && (
              <Button
                as={Link}
                to={`/booking/new/${participant.id}`}
                variant="solid"
                size="sm"
                mt={4}
                backgroundColor={'skyBlue.100'}
                color={'primary'}
              >
                <Text>{t('common.reschedule')}</Text>
              </Button>
            )}

            {canCancel && !isWithinCancelRange && (
              <Button
                variant="solid"
                size="sm"
                mt={4}
                backgroundColor={'skyBlue.100'}
                color={'primary'}
                onClick={() => cancelModal.onOpen()}
              >
                <Text>{t('common.cancel')}</Text>
              </Button>
            )}
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};

export default MeetingItem;
