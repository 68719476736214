import React from 'react';
import { Box, chakra, useMediaQuery } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import AppointmentLineItem from './AppointmentLineItem';
import AppointmentLineItemMobile from './AppointmentLineItemMobile';
import { useGetMyAppointmentsByStatusQuery } from '@src/apollo/hooks';

const thStyle = {
  px: 2,
  py: 3,
  bg: 'gray.50',
  textAlign: 'left',
  fontSize: 'xs',
  fontWeight: 'medium',
  color: 'gray.500',
  textTransform: 'uppercase',
  letterSpacing: '0.05em',
};

const AppointmentTable = ({ type }: { type: string }) => {
  const { t } = useTranslation();
  const [isLargerMobile] = useMediaQuery('(min-width: 48em)');
  const { data } = useGetMyAppointmentsByStatusQuery({
    variables: {
      meetingStatus: type,
    },
  });
  const appointments = data?.getMyAppointments || [];
  return (
    <Box className="divide-y" d="table" as="table" minW="100%">
      {isLargerMobile && (
        <thead>
          <tr>
            <Box sx={thStyle} px={2} as="th">
              {t('common.id')}
            </Box>
            <Box sx={thStyle} as="th">
              {t('common.description')}
            </Box>
            <Box sx={thStyle} as="th">
              {t('common.payment_date')}
            </Box>
            <Box sx={thStyle} as="th">
              {t('common.status')}
            </Box>
            <Box sx={thStyle} as="th">
              {t('common.amount')}
            </Box>
          </tr>
        </thead>
      )}
      <Box className="divide-y" bg="white" d="table-row-group" as="tbody">
        {appointments.length === 0 && (
          <tr>
            <chakra.td colSpan={5} alignItems="center" p={6}>
              <chakra.div
                d="flex"
                alignItems="center"
                justifyContent="center"
                color="gray.800"
              >
                {t('payouts.no_payouts')}
              </chakra.div>
            </chakra.td>
          </tr>
        )}
        {appointments?.map?.((appointment, index) => {
          return isLargerMobile ? (
            <AppointmentLineItem key={index} {...appointment} />
          ) : (
            <AppointmentLineItemMobile key={index} {...appointment} />
          );
        })}
      </Box>
    </Box>
  );
};

export default AppointmentTable;
