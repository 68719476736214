import React, { FC } from 'react';
import { Text, MenuList, MenuItem, Icon } from '@chakra-ui/react';
import {
  DEFAULT_VIDEO_CONSTRAINTS,
  SELECTED_VIDEO_INPUT_KEY,
  SELECTED_AUDIO_INPUT_KEY,
} from '@src/config/video';
import { LocalAudioStream, LocalVideoStream } from '@src/video';
import { useTranslation } from 'react-i18next';
import { useVideoContext } from '@src/context/VideoContext';
import useMediaStreamTrack from '@src/hooks/meeting/useMediaStreamTrack';
import { useDevices } from '@src/hooks/meeting/useDevice';
import { ReactComponent as CheckIcon } from '@src/icons/check.svg';

const DeviceSelectionMenu: FC = () => {
  const { audioInputDevices, videoInputDevices } = useDevices();
  const { t } = useTranslation();
  const { localStreams } = useVideoContext();

  const localVideoTrack = localStreams.find(
    (track) => track.kind === 'video'
  ) as LocalVideoStream;
  const mediaStreamTrack = useMediaStreamTrack(localVideoTrack);
  const localVideoInputDeviceId = mediaStreamTrack?.getSettings().deviceId;

  const localAudioTrack = localStreams.find(
    (track) => track.kind === 'audio'
  ) as LocalAudioStream;
  const mediaAudioStreamTrack = useMediaStreamTrack(localAudioTrack);
  const localAudioInputDeviceId = mediaAudioStreamTrack?.getSettings().deviceId;

  const replaceTrack = (newDeviceId: string) => {
    window.localStorage.setItem(SELECTED_VIDEO_INPUT_KEY, newDeviceId);
    if (localVideoTrack) {
      localVideoTrack.restart({
        ...(DEFAULT_VIDEO_CONSTRAINTS as Record<string, unknown>),
        deviceId: { exact: newDeviceId },
      });
    }
  };

  const replaceAudioTrack = (newDeviceId: string) => {
    window.localStorage.setItem(SELECTED_AUDIO_INPUT_KEY, newDeviceId);
    if (localAudioTrack) {
      localAudioTrack?.restart({ deviceId: { exact: newDeviceId } });
    }
  };

  return (
    <MenuList
      backgroundColor="blue.500"
      padding={4}
      border="none"
      borderRadius="15px"
    >
      <Text minH="48px" as="strong" color="white">
        {t('meeting_room.camera')}
      </Text>
      {videoInputDevices.map((device: MediaDeviceInfo) => {
        return (
          <MenuItem
            as="button"
            backgroundColor="blue.500"
            key={device.deviceId}
            minH="40px"
            color="white"
            onClick={() => replaceTrack(device.deviceId)}
            _focus={{
              backgroundColor: 'blue.500',
            }}
            _hover={{
              fontWeight: '800',
            }}
          >
            {localVideoInputDeviceId === device.deviceId && (
              <Icon as={CheckIcon} color="white" mr={3} />
            )}
            {device.label}
          </MenuItem>
        );
      })}
      <Text minH="48px" as="strong" color="white">
        {t('meeting_room.audio')}
      </Text>
      {audioInputDevices.map((device: MediaDeviceInfo) => {
        return (
          <MenuItem
            backgroundColor="blue.500"
            key={device.deviceId}
            minH="40px"
            color="white"
            onClick={() => replaceAudioTrack(device.deviceId)}
            _focus={{
              backgroundColor: 'blue.500',
            }}
            _hover={{
              fontWeight: '800',
            }}
          >
            {localAudioInputDeviceId === device.deviceId && (
              <Icon as={CheckIcon} color="white" mr={3} />
            )}
            {device.label}
          </MenuItem>
        );
      })}
    </MenuList>
  );
};

export default DeviceSelectionMenu;
