import React from 'react';
import { Box, Heading, Icon } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import SearchForm from '@components/molecules/SearchForm';
import { ReactComponent as Vector2 } from '@src/icons/hero-vector2.svg';
import { ReactComponent as Vector3 } from '@src/icons/hero-vector3.svg';
import { ReactComponent as Vector4 } from '@src/icons/hero-vector4.svg';
import { ReactComponent as Vector6 } from '@src/icons/hero-vector6.svg';

export const HeroSection: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Box pos="relative" overflow={{ sm: 'hidden' }} bg="white" marginTop="60px">
      <Box
        position="relative"
        zIndex={1}
        pb={{ base: 8, sm: 16, md: 20, lg: 28, xl: 36 }}
        w={{ lg: 'full' }}
        maxW={{ lg: '5xl' }}
        mx="auto"
      >
        <Box
          maxW={{ lg: '2xl' }}
          px={{ base: 4, sm: 6, lg: 8 }}
          mt={{ base: 10, sm: 12, md: 16, lg: 20 }}
        >
          <Box
            d="flex"
            flexDirection="column"
            alignItems={{ base: 'center', lg: 'flex-start' }}
            justifyContent="flex-end"
            data-testid="landing-hero"
          >
            <Heading
              as="h2"
              lineHeight="base"
              fontSize={{ base: '3xl', lg: '4xl' }}
              fontWeight="bold"
              color="black"
              textAlign={{ base: 'center', md: 'left' }}
            >
              {t('landing.header')}
            </Heading>
            <SearchForm />
          </Box>
        </Box>
      </Box>
      <Box
        overflow="hidden"
        pos={{ base: 'relative', lg: 'absolute' }}
        insetY={{ lg: 0 }}
        right={{ lg: 0 }}
        w={{ lg: '70%' }}
      >
        <Box
          as="img"
          w="full"
          h={{ base: 56, sm: 72, md: 96, lg: 'full' }}
          objectFit="cover"
          src="/images/hero.png"
          // className="clip-image-bottom-right"
        />
        <Box
          pos="absolute"
          insetX={0}
          top={0}
          d={{ base: 'block', lg: 'none' }}
          h="20%"
          aria-hidden="true"
          backgroundImage="linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%)"
        />
        <Icon
          as={Vector2}
          d={{ base: 'none', lg: 'block' }}
          pos="absolute"
          inset={0}
          h={{ lg: '80%', xl: '100%' }}
          w="100%"
        />
        <Icon
          as={Vector3}
          d={{ base: 'none', lg: 'block' }}
          pos="absolute"
          inset={0}
          h="100%"
          w="100%"
        />
        <Icon
          as={Vector4}
          d={{ base: 'none', lg: 'block' }}
          pos="absolute"
          bottom={0}
          insetY={0}
          h="120%"
          w="50%"
        />
        <Icon
          as={Vector6}
          d={{ base: 'none', lg: 'block' }}
          pos="absolute"
          inset={0}
          h="30%"
          w="100%"
        />
      </Box>
    </Box>
  );
};

export default HeroSection;
