import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';
import { addMinutes, parse, differenceInMinutes } from 'date-fns';
import { useParams, useHistory, Redirect } from 'react-router-dom';
import { Box, Spinner, Text, useDisclosure } from '@chakra-ui/react';
import MeetingStepper from '@src/components/molecules/Meetings/PaymentStepper';
import LawyerNameCard from '@src/components/molecules/LawyerNameCard';
import PaymentDetails from '@src/components/molecules/Meetings/PaymentDetails';
import useAnalytics from '@src/hooks/useAnalytics';
import {
  useCreateAppointmentMutation,
  GetMyAppointmentsDocument,
  MyPurchasesDocument,
  useGetLawyerQuery,
} from '@src/apollo/hooks';
import { useDefaultPaymentMethod, useToast } from '@src/hooks';
import MeetingTimeBox from '@src/components/molecules/Meetings/MeetingTimeBox';
import AddPaymentMethod from './AddPaymentMethod';
import { stripePromise } from '@src/utils';
export interface ParamTypes extends BaseRouteParams {
  lawyer_id: string;
  date: string;
  time: string;
  duration: string;
}
const BookingPayment: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const toast = useToast();
  const params = useParams<ParamTypes>();
  const showCreditCardForm = useDisclosure();
  const defaultPaymentMethod = useDefaultPaymentMethod();
  const { trackNewAppointment } = useAnalytics();
  const legarooBaseFee = parseInt(process.env.REACT_APP_BASE_FEE as string);
  const { data, loading: lawyerLoading } = useGetLawyerQuery({
    variables: { lawyerId: Number(params.lawyer_id) },
  });

  const displayPrice = data?.getLawyer?.hourlyRate ?? 0;

  const [paymentMethod, setPaymentMethod] = useState<
    PaymentMethodType | undefined | null
  >(defaultPaymentMethod as PaymentMethodType);

  const [createAppointment, { loading }] = useCreateAppointmentMutation();

  const { locale } = params;

  useEffect(() => {
    if (defaultPaymentMethod) {
      setPaymentMethod(defaultPaymentMethod);
    }
  }, [defaultPaymentMethod]);

  const appointment = useMemo(() => {
    const { date, time, duration, lawyer_id } = params;
    const startsAt = time.includes(':')
      ? parse(time, 'p', new Date(date))
      : parse(time, 'h a', new Date(date));
    const durationMin = parseInt(duration);
    const endsAt = addMinutes(startsAt, durationMin);
    return {
      startsAt,
      endsAt,
      hostId: Number(lawyer_id),
      duration: durationMin,
    };
  }, [params]);

  const fees = useMemo(() => {
    const { duration } = appointment;
    const price = duration === 60 ? displayPrice : displayPrice / 2;
    const serviceFee = price * 0.05;
    const fixedFee =
      appointment?.duration === 60 ? legarooBaseFee : legarooBaseFee / 2;

    return {
      lawyer: price,
      service: serviceFee + fixedFee,
      total: serviceFee + fixedFee + price,
    };
  }, [appointment, displayPrice, legarooBaseFee]);

  const lawyer = data?.getLawyer;

  const onCreateAppointment = useCallback(async () => {
    if (!paymentMethod) return;
    const { startsAt, endsAt, hostId } = appointment;
    const durationMinutes = differenceInMinutes(endsAt, startsAt);

    try {
      const { data } = await createAppointment({
        variables: {
          input: {
            startsAt,
            endsAt,
            hostId,
            title: 'Book Appointment',
            meeting: {
              duration: durationMinutes,
              public: false,
            },
          },
          paymentMethod: paymentMethod.id as string,
        },
        refetchQueries: [
          { query: MyPurchasesDocument },
          { query: GetMyAppointmentsDocument },
        ],
      });

      if (!data?.createAppointment?.successful) {
        data?.createAppointment?.messages?.forEach((error) => {
          toast.warning({
            title: 'Error',
            description: error?.message,
          });
        });
        return;
      }
      trackNewAppointment();
      history.push({
        pathname: `/${locale}/booking/thankyou`,
        state: {
          appointment: data.createAppointment.result,
          payWithCardLast4: paymentMethod.cardLast4,
          lawyerId: hostId,
        },
      });

      toast.success({
        title: t('meetings.submit.create.title'),
        description: t('meetings.submit.create.description'),
        isClosable: true,
      });
    } catch (error) {
      toast.warning({
        title: 'Error',
        description: error?.message,
      });
    }
  }, [
    history,
    toast,
    t,
    paymentMethod,
    createAppointment,
    trackNewAppointment,
    appointment,
    locale,
  ]);

  const onCreatePaymentMethod = useCallback(
    (paymentMethod) => {
      setPaymentMethod(paymentMethod);
      showCreditCardForm.onClose();
    },
    [setPaymentMethod, showCreditCardForm]
  );

  const goBack = useCallback(() => {
    history.push({
      pathname: `/${params.locale}/booking/new/${params.lawyer_id}`,
      state: {
        date: params.date,
        time: params.time,
        duration: params.duration,
      },
    });
  }, [history, params]);

  if (!lawyerLoading && !lawyer) return <Redirect to="/" />;

  return (
    <Box
      borderColor="blue.50"
      backgroundPosition="center"
      w="full"
      mx="auto"
      minHeight="100vh"
      marginBottom={20}
    >
      <Box maxW="5xl" mx="auto" px={6} marginTop="70px">
        <Box
          d="flex"
          justifyContent={{ base: 'center', lg: 'space-between' }}
          alignItems="center"
          pb={6}
        >
          <Text fontWeight="800">{t('meetings.about_to_schedule_with')}</Text>
          <MeetingStepper step={2} />
        </Box>
      </Box>
      <Box
        w="100%"
        d="flex"
        pos="relative"
        _before={{
          content: '""',
          insetX: 0,
          h: { base: '30%', lg: '60%' },
          position: 'absolute',
          top: 0,
          left: 0,
          background:
            'linear-gradient(180deg, #D2E3FB 0%, rgba(255, 255, 255, 0) 100%);',
        }}
      >
        <Box
          width="100%"
          maxW="5xl"
          mx="auto"
          d="flex"
          pos="relative"
          flexDir={{ base: 'column', lg: 'row' }}
        >
          {showCreditCardForm.isOpen ? (
            <Box mx="auto" w="full" px={6} width={{ base: '100%', lg: '58%' }}>
              <Elements stripe={stripePromise}>
                <AddPaymentMethod onSuccess={onCreatePaymentMethod} />
              </Elements>
            </Box>
          ) : (
            <Box mx="auto" w="full" px={6} width={{ base: '100%', lg: '58%' }}>
              <Box
                pt={{ base: 8, lg: 12 }}
                mb={6}
                d="flex"
                alignItems="center"
                justifyContent={{ base: 'center', lg: 'flex-start' }}
              >
                {lawyerLoading && <Spinner />}
                {lawyer && <LawyerNameCard lawyer={lawyer} />}
              </Box>
              <MeetingTimeBox
                date={appointment.startsAt}
                duration={appointment.duration}
              />
            </Box>
          )}
          <Box width={{ sm: '100%', lg: '42%' }}>
            <PaymentDetails
              paymentMethod={paymentMethod}
              serviceFees={fees}
              amount={fees.total}
              handleGoBack={goBack}
              onCreateAppointment={onCreateAppointment}
              onAddCreditCard={showCreditCardForm.onOpen}
              isLoading={loading}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BookingPayment;
