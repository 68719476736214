import { FieldMetaState } from 'react-final-form';
import {
  formatDuration as formatDateDuration,
  subMonths,
  setHours,
  setMinutes,
  setSeconds,
  setMilliseconds,
  addMinutes,
  isBefore,
  differenceInHours,
  roundToNearestMinutes,
  parseISO,
  lightFormat,
} from 'date-fns';
import { utcToZonedTime, format as formatz } from 'date-fns-tz';
import { ApolloError } from '@apollo/client';
import { Auth0Error } from 'auth0-js';
import { loadStripe, StripeError } from '@stripe/stripe-js';

export type ShowErrorFunc = (props: ShowErrorProps) => boolean | undefined;

export interface ShowErrorProps {
  // eslint-disable-next-line
  meta: FieldMetaState<any>;
}

export const showErrorOnChange: ShowErrorFunc = ({
  meta: { submitError, dirtySinceLastSubmit, error, touched, modified },
}: ShowErrorProps) =>
  !!(
    ((submitError && !dirtySinceLastSubmit) || error) &&
    (touched || modified)
  );

export function durationFormat(token: string) {
  switch (token) {
    case 'xMinutes':
      return 'min';
    case 'xHours':
      return 'h';
    default:
      return token;
  }
}

export function formatDuration(duration: number) {
  return formatDateDuration(
    { hours: Math.floor(duration / 60), minutes: duration % 60 },
    {
      format: ['hours', 'minutes'],
      locale: {
        code: 'en-US',
        formatDistance: (token, payload) =>
          `${payload} ${durationFormat(token)}`,
      },
    }
  );
}

export function getSubtitleForFilter(
  filter: string,
  range: [Date, Date] | null,
  formatLocale: (date: Date, formatStr: string) => string
) {
  const currentDate = new Date();
  switch (filter) {
    case 'currentMonth':
      return formatLocale(currentDate, 'MMMM yyyy');
    case 'pastMonth':
      return formatLocale(subMonths(currentDate, 1), 'MMMM yyyy');
    case 'pastSixMonths':
      return formatLocale(subMonths(currentDate, 6), 'MMMM yyyy');
    case 'withinInterval':
      if (range) {
        const [start, end] = range;
        return `${formatLocale(start, 'd MMMM yyyy')} - ${formatLocale(
          end,
          'd MMMM yyyy'
        )}`;
      }
      return null;
    default:
      return null;
  }
}

export function isLawyer(
  user?:
    | User
    | Viewer
    | Maybe<
        { __typename?: 'User' } & {
          profile: { __typename?: 'UserProfile' } & ProfileFragment;
        } & UserFragment
      >
) {
  return user?.roles?.includes('lawyer');
}

// eslint-disable-next-line
const rooFormatError = (error: any): string => {
  if (!error || !error.message) return '';
  if (error.graphQLErrors) {
    // eslint-disable-next-line
    return error.graphQLErrors.map(({ message }: any) => message).join('\n');
  }
  return error.message.replace(/GraphQL error: /g, '');
};

export function formatError(
  error: Auth0Error | StripeError | ApolloError | Error
) {
  if ((error as Auth0Error).errorDescription) {
    return rooFormatError(error) ?? (error as Auth0Error).errorDescription;
  }
  return rooFormatError(error) ?? (error as Error).message;
}

export const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLIC_ID as string
);

export function formatMoney(amount = 0) {
  const options = {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  };

  const formatter = Intl.NumberFormat('en-US', options);

  return `USD ${formatter.format(amount / 100)}`;
}

export function dollarToCents(amount: number | string) {
  const dollars =
    typeof amount === 'string'
      ? parseFloat(amount.replace(/[$,]/g, ''))
      : amount;
  return Math.round(100 * dollars);
}

export function centsToDollar(amount: number) {
  return amount / 100;
}

export function numberOfSlotHours(slots: Slot[]): number {
  return slots
    .filter((i) => i?.from && i?.to)
    .reduce(
      (acc, curr) => acc + Math.max(differenceInHours(curr.to, curr.from), 0),
      0
    );
}
export const setTheTime = (x: Date, time: string): Date => {
  const split = time.split(':');

  return setHours(
    setMinutes(
      setSeconds(setMilliseconds(x, 0), parseInt(split[2])),
      parseInt(split[1])
    ),
    parseInt(split[0])
  );
};

export const setTime = (x: Date, h = 0, m = 0, s = 0, ms = 0): Date =>
  setHours(setMinutes(setSeconds(setMilliseconds(x, ms), s), m), h);

export const generateTimeSlots = ({
  start,
  end,
  interval,
}: {
  start: number;
  end: number;
  interval: number;
}): Date[] => {
  const from = setTime(new Date(), start);
  const to = setTime(new Date(), end);
  const step = (x: Date): Date => addMinutes(x, interval);

  const blocks = [];

  let cursor = from;

  while (isBefore(cursor, to)) {
    blocks.push(cursor);
    cursor = step(cursor);
  }

  return blocks;
};

export function redirectRatingOrHome({
  viewer,
  homePath,
  ratingPath,
}: {
  viewer?: Maybe<
    { __typename?: 'User' } & {
      profile: { __typename?: 'UserProfile' } & ProfileFragment;
    } & UserFragment
  >;
  homePath: string;
  ratingPath: string;
}) {
  if (isLawyer(viewer)) {
    window.location.href = homePath;
  } else {
    window.location.href = ratingPath;
  }
}

export const formatDateZone = (date: Date) => {
  const rounded = roundToNearestMinutes(date, { nearestTo: 30 });
  const time = rounded.toISOString();

  const parsedTime = parseISO(time);
  const formatInTimeZone = (
    date: string | number | Date,
    fmt: string,
    tz: string
  ) => formatz(utcToZonedTime(date, tz), fmt, { timeZone: tz });
  const formattedTime = formatInTimeZone(parsedTime, 'yyyy-MM-dd', 'UTC');
  return formattedTime;
};

export const formatTimeZone = (date: Date) => {
  const rounded = roundToNearestMinutes(date, { nearestTo: 30 });
  const time = rounded.toISOString();

  const parsedTime = parseISO(time);
  const formatInTimeZone = (
    date: string | number | Date,
    fmt: string,
    tz: string
  ) => formatz(utcToZonedTime(date, tz), fmt, { timeZone: tz });
  const formattedTime = formatInTimeZone(
    parsedTime,
    'yyyy-MM-dd HH:mm:ss',
    'UTC'
  );
  return formattedTime;
};

export const asUTC = (date: Date) => {
  return addMinutes(date, date.getTimezoneOffset());
};

export const avatarFormatFilename = (filename: string, fileFormat: string) => {
  const date = lightFormat(new Date(), 'yyyyMMdd');
  const randomString = Math.random().toString(36).substring(2, 7);
  const cleanFileName = filename.toLowerCase().replace(/[^a-z0-9]/g, '-');
  const newFilename = `${date}-${randomString}-${cleanFileName}.${fileFormat}`;
  return newFilename.substring(0, 60);
};

interface CookieOptions {
  days?: number;
  domain?: string;
}
export function setCookie(
  name: string,
  value: string,
  options?: CookieOptions
) {
  const opts = { days: 1, ...options };
  let expires = '';
  if (opts.days) {
    const date = new Date();
    date.setTime(date.getTime() + opts.days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/';
}
export function getCookie(name: string) {
  const nameEQ = name + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    // eslint-disable-next-line
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    // eslint-disable-next-line
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

export function eraseCookie(name: string) {
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}
