import React from 'react';
import {
  Box,
  HStack,
  Icon,
  IconButton,
  Menu,
  Button,
  MenuButton,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import ToggleVideo from '@molecules/MeetingRoom/CallControls/Buttons/ToggleVideo';
import ToggleAudio from '@molecules/MeetingRoom/CallControls/Buttons/ToggleAudio';
import HangUpCallButton from '@molecules/MeetingRoom/CallControls/Buttons/HangUpButton';
import ToggleScreenShareButton from '@molecules/MeetingRoom/CallControls/Buttons/ToggleScreenShareButton';
import { ReactComponent as ScreenShareIcon } from '@src/icons/screen-share.svg';
// import { ReactComponent as AddUserIcon } from '@src/icons/add-user.svg';
import { ReactComponent as MenuIcon } from '@src/icons/menu.svg';
import DeviceSelectionMenu from '../CallControls/DeviceSelectionMenu';
import { useVideoContext } from '@src/context/VideoContext';
import { isMobile } from '@src/utils';
import useRoomState from '@src/hooks/meeting/useRoomState';

const MenuBar = () => {
  const { t } = useTranslation();
  const { isSharingScreen, toggleScreenShare } = useVideoContext();
  const roomState = useRoomState();
  const isReconnecting = roomState === 'reconnecting';
  return (
    <>
      {isSharingScreen && (
        <Box
          maxW="7xl"
          mx="auto"
          px={{ base: 2, sm: 6, lg: 8 }}
          pos="fixed"
          zIndex={8}
          bottom={{ base: '56px', sm: '82px' }}
          left={0}
          right={0}
        >
          <Box
            p={[2, 3]}
            rounded="lg"
            background="rgba(0, 0, 0, 0.5)"
            shadow="lg"
          >
            <Box
              d="flex"
              alignItems="center"
              justifyContent="space-between"
              flexWrap="wrap"
            >
              <Box w={0} flex="1 1 0%" d="flex" alignItems="center">
                <Box as="span" p={1} rounded="lg" bg="rgba(0, 0, 0, 0.7)">
                  <Icon as={ScreenShareIcon} color="white" h={6} w={6} />
                </Box>

                <Text ml={3} fontWeight="medium" color="white">
                  {t('meetings.you_are_sharing_your_screen')}
                </Text>
              </Box>
              <Box flexShrink={0} w={['full', 'auto']}>
                <Button
                  variant="link"
                  onClick={() => toggleScreenShare()}
                  color="blue.300"
                >
                  {t('meetings.stop_sharing')}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      <Box
        as="footer"
        backgroundColor={{ base: 'transparent', md: 'white' }}
        position="fixed"
        left={0}
        right={0}
        bottom={0}
        zIndex={10}
        display="flex"
        height={{ base: '56px', sm: '72px' }}
        p={{ base: 0, sm: '0 1.43em' }}
      >
        <Box
          w="full"
          d="flex"
          justifyContent="flex-end"
          alignItems="center"
          flexWrap="wrap"
          position="relative"
        >
          <Box
            flex="1 1 0%"
            position={{ base: 'relative', sm: 'absolute' }}
            inset={{ sm: 0 }}
            d="flex"
            justifyContent="center"
            alignItems="center"
            mt={{ base: -6, md: 0 }}
          >
            <HStack spacing={4}>
              <ToggleAudio
                variant="solid"
                boxShadow="0px 0px 18px 2px rgba(0, 0, 0, 0.12)"
                color="dove-gray.500"
                _focus={{ boxShadow: '0px 0px 18px 2px rgba(0, 0, 0, 0.25)' }}
              />

              <HangUpCallButton />

              <ToggleVideo
                variant="solid"
                boxShadow="0px 0px 18px 2px rgba(0, 0, 0, 0.12)"
                color="dove-gray.500"
                _focus={{ boxShadow: '0px 0px 18px 2px rgba(0, 0, 0, 0.25)' }}
              />
            </HStack>
          </Box>
          <Box d={{ base: 'none', lg: 'flex' }} alignItems="center">
            <HStack>
              {!isSharingScreen && !isMobile && (
                <ToggleScreenShareButton isDisabled={isReconnecting} />
              )}
              {/* <Button
              aria-label="add user"
              colorScheme="white"
              size="lg"
              color="dove-gray.500"
              display="flex"
              flexDirection="column"
              alignItems="center"
              width={12}
            >
              <Icon
                as={AddUserIcon}
                w={{ base: 5, sm: 6 }}
                h={{ base: 5, sm: 6 }}
              />
              <Text fontSize="xs" pt={1}>
                {t('common.add')}
              </Text>
            </Button> */}

              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label="menu"
                  color="dove-gray.500"
                  colorScheme="white"
                  size="lg"
                  w={12}
                >
                  <Icon
                    as={MenuIcon}
                    w={{ base: 5, sm: 6 }}
                    h={{ base: 5, sm: 6 }}
                  />
                </MenuButton>
                <DeviceSelectionMenu />
              </Menu>
            </HStack>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default MenuBar;
