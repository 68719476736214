/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/ban-types */
import {
  DEFAULT_VIDEO_CONSTRAINTS,
  SELECTED_AUDIO_INPUT_KEY,
  SELECTED_VIDEO_INPUT_KEY,
} from './constants';
import { useCallback, useState } from 'react';
import {
  LocalMediaStream,
  CreateStreamOptions,
  createVideoStream,
  createAudioStream,
  createLocalStreams,
  LocalVideoStream,
  LocalAudioStream,
  LocalStream,
} from '@src/video';

// import { useAudioInputDevices, useVideoInputDevices } from './useDevice';
import { getDeviceInfo, isPermissionDenied } from '@src/utils';

export default function useLocalStreams() {
  const [audioStream, setAudioStream] = useState<LocalAudioStream>();
  const [videoStream, setVideoStream] = useState<LocalVideoStream>();
  const [isAcquiringLocalStreams, setIsAcquiringLocalStreams] = useState(false);

  const getLocalAudioStream = useCallback((deviceId?: string) => {
    const options: CreateStreamOptions = {};

    if (deviceId) {
      options.deviceId = { exact: deviceId };
    }

    return createAudioStream(options).then((newStream: LocalAudioStream) => {
      setAudioStream(newStream);
      return newStream;
    });
  }, []);

  const getLocalVideoStream = useCallback(async () => {
    const selectedVideoDeviceId = window.localStorage.getItem(
      SELECTED_VIDEO_INPUT_KEY
    );

    const { videoInputDevices } = await getDeviceInfo();

    const hasSelectedVideoDevice = videoInputDevices.some(
      (device) =>
        selectedVideoDeviceId && device.deviceId === selectedVideoDeviceId
    );

    const options: CreateStreamOptions = {
      ...(DEFAULT_VIDEO_CONSTRAINTS as {}),
      name: `camera-${Date.now()}`,
      ...(hasSelectedVideoDevice && {
        deviceId: { exact: selectedVideoDeviceId! },
      }),
    };

    return createVideoStream(options).then((newStream: LocalVideoStream) => {
      setVideoStream(newStream);
      return newStream;
    });
  }, []);

  const removeLocalAudioStream = useCallback(() => {
    if (audioStream) {
      audioStream.stop();
      setAudioStream(undefined);
    }
  }, [audioStream]);

  const removeLocalVideoStream = useCallback(() => {
    if (videoStream) {
      videoStream.stop();
      setVideoStream(undefined);
    }
  }, [videoStream]);

  const getAudioAndVideoStreams = useCallback(async () => {
    const {
      audioInputDevices,
      videoInputDevices,
      hasAudioInputDevices,
      hasVideoInputDevices,
    } = await getDeviceInfo();
    if (!hasAudioInputDevices && !hasVideoInputDevices)
      return Promise.resolve();
    if (isAcquiringLocalStreams || audioStream || videoStream)
      return Promise.resolve();

    setIsAcquiringLocalStreams(true);

    const selectedAudioDeviceId = window.localStorage.getItem(
      SELECTED_AUDIO_INPUT_KEY
    );
    const selectedVideoDeviceId = window.localStorage.getItem(
      SELECTED_VIDEO_INPUT_KEY
    );

    const hasSelectedAudioDevice = audioInputDevices.some(
      (device) =>
        selectedAudioDeviceId && device.deviceId === selectedAudioDeviceId
    );
    const hasSelectedVideoDevice = videoInputDevices.some(
      (device) =>
        selectedVideoDeviceId && device.deviceId === selectedVideoDeviceId
    );

    // In Chrome, it is possible to deny permissions to only audio or only video.
    // If that has happened, then we don't want to attempt to acquire the device.
    const isCameraPermissionDenied = await isPermissionDenied('camera');
    const isMicrophonePermissionDenied = await isPermissionDenied('microphone');

    const shouldAcquireVideo =
      hasVideoInputDevices && !isCameraPermissionDenied;
    const shouldAcquireAudio =
      hasAudioInputDevices && !isMicrophonePermissionDenied;

    const localStreamConstraints = {
      video: shouldAcquireVideo && {
        ...(DEFAULT_VIDEO_CONSTRAINTS as {}),
        name: `camera-${Date.now()}`,
        ...(hasSelectedVideoDevice && {
          deviceId: { exact: selectedVideoDeviceId! },
        }),
      },
      audio:
        shouldAcquireAudio &&
        (hasSelectedAudioDevice
          ? { deviceId: { exact: selectedAudioDeviceId! } }
          : hasAudioInputDevices),
    };

    return createLocalStreams(localStreamConstraints)
      .then((tracks: LocalMediaStream[]) => {
        const videoStream = tracks.find(
          (track) => track.kind === 'video'
        ) as LocalVideoStream;

        const audioStream = tracks.find(
          (track) => track.kind === 'audio'
        ) as LocalAudioStream;

        if (videoStream) {
          setVideoStream(videoStream);
          // Save the deviceId so it can be picked up by the VideoInputList component. This only matters
          // in cases where the user's video is disabled.
          window.localStorage.setItem(
            SELECTED_VIDEO_INPUT_KEY,
            videoStream.mediaStreamTrack.getSettings().deviceId ?? ''
          );
        }
        if (audioStream) {
          setAudioStream(audioStream);
        }

        // These custom errors will be picked up by the MediaErrorSnackbar component.
        if (isCameraPermissionDenied && isMicrophonePermissionDenied) {
          const error = new Error();
          error.name = 'NotAllowedError';
          throw error;
        }

        if (isCameraPermissionDenied) {
          throw new Error('CameraPermissionsDenied');
        }

        if (isMicrophonePermissionDenied) {
          throw new Error('MicrophonePermissionsDenied');
        }
      })
      .finally(() => setIsAcquiringLocalStreams(false));
  }, [audioStream, videoStream, isAcquiringLocalStreams]);

  const localStreams = [audioStream, videoStream].filter(
    (track) => track !== undefined
  ) as LocalStream[];

  return {
    localStreams,
    getLocalVideoStream,
    getLocalAudioStream,
    isAcquiringLocalStreams,
    removeLocalAudioStream,
    removeLocalVideoStream,
    getAudioAndVideoStreams,
  };
}
