import React from 'react';
import { Box } from '@chakra-ui/react';
import SidebarLayout from '@src/components/organisms/RooLayout/SidebarLayout';
import ApplicationInformation from '@src/components/molecules/Admin/ApplicationInformation';

const LawyerApplication = () => {
  return (
    <SidebarLayout>
      <Box maxW="5xl" mx="auto" py={16} px={6}>
        <ApplicationInformation />
      </Box>
    </SidebarLayout>
  );
};

export default LawyerApplication;
