import React from 'react';
import { Box, Heading, Text, Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useGetAppointmentByRoomQuery } from '@src/apollo/hooks';
import { Redirect, useParams } from 'react-router-dom';
import Loader from '@atoms/Loader';
import Link from '@src/components/atoms/Link';

const MeetingRating: React.FC = () => {
  const { t } = useTranslation();
  const { meeting_id, locale } = useParams<
    RoomParamTypes & { locale: string }
  >();
  const { data, loading, error } = useGetAppointmentByRoomQuery({
    variables: { roomId: meeting_id },
    skip: !meeting_id,
  });

  if (loading) return <Loader />;
  if (error) return <Redirect to={`/${locale}`} />;

  const lawyerFullName = `${data?.getAppointmentByRoom.host.firstName} ${data?.getAppointmentByRoom.host.lastName}`;
  const lawyerId = data?.getAppointmentByRoom.host.id;
  return (
    <Box
      d="flex"
      alignItems="center"
      justifyContent="center"
      w="100vw"
      h="100vh"
      background="linear-gradient(180deg, #D2E3FB 0%, rgba(255, 255, 255, 0) 100%)"
    >
      <Box
        width="400px"
        height="300px"
        p={[6, 12]}
        background="linear-gradient(180deg, #D2E3FB 0%, rgba(255, 255, 255, 0) 100%)"
        rounded="2xl"
        textAlign="center"
        alignItems="center"
      >
        <Heading as="h3" size="lg">
          {t('meetings.rating_page.all_done')}
        </Heading>
        <Text variant="secondary" mt={3}>
          {t('meetings.rating_page.thankyou_for_booking', {
            name: lawyerFullName,
          })}
        </Text>
        {/* <Text m={1}>{t('meetings.rating_page.how_was_you_meeting')}</Text> */}
        {/* <Box m={4}>
          <Icon m={2} as={SolidStarIcon} color="orange.500" />
          <Icon m={2} as={SolidStarIcon} color="orange.500" />
          <Icon m={2} as={SolidStarIcon} color="orange.500" />
          <Icon m={2} as={SolidStarIcon} color="orange.500" />
          <Icon m={2} as={StarIcon} color="orange.500" />
        </Box> */}
        <Button
          as={Link}
          to={`/booking/new/${lawyerId}`}
          variant="solid"
          colorScheme="green"
          mt={3}
          mr={3}
        >
          {t('common.book_again')}
        </Button>
        <Button as={Link} to="/" colorScheme="blue" mt={3}>
          {t('common.done')}
        </Button>
      </Box>
    </Box>
  );
};

export default MeetingRating;
