import React from 'react';
import { Box, Icon, Text, Badge } from '@chakra-ui/react';
import DateFormat from '@src/components/atoms/DateFormat';
import { formatMoney } from '@src/utils';
import { ReactComponent as CreditCardSvg } from '@src/icons/credit-card.svg';

export type Payment = Pick<
  PurchaseType,
  'bankStatement' | 'description' | 'id' | 'paid' | 'refunded'
> & { date: PurchaseType['insertedAt'] } & {
  details: { __typename?: 'PurchaseDetailsType' } & Pick<
    PurchaseDetailsType,
    'total'
  > & {
      paymentSource: { __typename?: 'PaymentMethodType' } & Pick<
        PaymentMethodType,
        'cardLast4'
      >;
    };
};

const PaymentLineItem = ({
  id,
  bankStatement,
  date,
  details,
  refunded,
}: Payment) => {
  const displayAmount = formatMoney(details.total);
  const status = refunded ? 'refunded' : 'paid';
  return (
    <Box as="tr">
      <Box as="td" px={2} py={4}>
        <Box d="flex" alignItems="left">
          <Box>
            <Text fontSize="sm" color="gray.900">
              {bankStatement}{' '}
              <Text as="span" fontSize="xs" textStyle="secondary">
                ID: {id}
              </Text>
            </Text>
          </Box>
        </Box>
      </Box>

      <Box as="td" px={2} py={4} whiteSpace="nowrap">
        <DateFormat date={date} fontSize="sm" />
      </Box>

      <Box as="td" px={2} py={4} whiteSpace="nowrap" fontSize="sm">
        <Icon as={CreditCardSvg} mr={1} w={4} h={4} color="gray.600" />{' '}
        {details.paymentSource.cardLast4}
      </Box>

      <Box as="td" px={2} py={4} whiteSpace="nowrap">
        <Box d="flex" alignItems="center" flexDirection="column">
          <Text fontSize="sm" fontWeight="medium">
            {displayAmount}
          </Text>
          <Badge colorScheme={status === 'refunded' ? 'red' : 'green'}>
            {status}
          </Badge>
        </Box>
      </Box>
    </Box>
  );
};

export default PaymentLineItem;
