import React, { FC, useState } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import Loader from '@atoms/Loader';
import ApplicationForm from '@molecules/ApplicationForm';
import ApplicationResultPage from '@src/components/molecules/ApplicationForm/Steps/ResultPage';
import {
  useViewerQuery,
  useCreateLawyerApplicationMutation,
} from '@src/apollo/hooks';
import { useToast } from '@src/hooks';
import useAnalytics from '@src/hooks/useAnalytics';

const LawyerApplication: FC = () => {
  const { data: viewerData, loading: viewerLoading } = useViewerQuery();
  const { reportConversion } = useAnalytics();

  const toast = useToast();
  const [userFirstName, setFirstName] = useState('');
  const [
    applicationResult,
    setApplicationResult,
  ] = useState<ApplicationCreationPayload | null>(null);
  const { t } = useTranslation();
  const viewer = viewerData?.viewer;

  const [
    createLawyerApplication,
    { loading },
  ] = useCreateLawyerApplicationMutation();

  const createNewLawyerApplication = async (values: any) => {
    const {
      firstName,
      lastName,
      title,
      email,
      phone,
      password,
      countryId,
    } = values.input;

    const { data } = await createLawyerApplication({
      variables: {
        input: {
          firstName,
          lastName,
          title,
          email,
          phone,
          password: password || '',
          countryCode: countryId,
        },
      },
    });

    if (data?.createLawyerApplication?.successful) {
      const { createLawyerApplication } = data;
      reportConversion();
      if (!createLawyerApplication?.result?.confirmationRequired && viewer) {
        return window.location.reload();
      }
      setApplicationResult(
        createLawyerApplication as ApplicationCreationPayload
      );
      setFirstName(firstName);
    } else {
      data?.createLawyerApplication?.messages?.map((message) => {
        return toast.warning({
          title: 'Error',
          description: message?.message,
          isClosable: true,
        });
      });
    }
    window.scrollTo(0, 0);
  };

  if (viewerLoading || loading) return <Loader />;
  return (
    <>
      <Box maxW="5xl" px={6} pl={{ base: 6, md: 10, lg: 12, xl: 48 }}>
        <Box padding={{ base: 6, md: 10 }} w={'80%'}>
          {applicationResult && (
            <Text
              color="white"
              fontSize="2xl"
              fontWeight="bold"
              textAlign="left"
              mt={16}
              mb={1}
            >
              Hello, Welcome!
            </Text>
          )}
          <Text
            color="white"
            fontSize="3xl"
            fontWeight="200"
            textAlign="left"
            mt={1}
            mb={20}
          >
            {t('lawyer_application.title')}
          </Text>
        </Box>
      </Box>

      <Box
        backgroundColor="blue.50"
        backgroundPosition="center"
        w="full"
        h="auto"
        minHeight="100vh"
        zIndex={2}
      >
        <Box maxW="6xl" mx="auto" px={6}>
          <Box
            position="relative"
            top="-50"
            d="flex"
            w="full"
            flexDirection="column"
            justifyContent="top"
            bgColor="white"
            padding={{ base: 6, md: 50 }}
            rounded={15}
            shadow={'0px 0px 40px rgba(0, 0, 0, 0.15)'}
          >
            {!applicationResult && (
              <ApplicationForm
                viewer={viewer}
                onSaveData={createNewLawyerApplication}
              />
            )}
          </Box>
        </Box>
      </Box>
      {applicationResult && !viewer && (
        <Box
          backgroundColor={'rgba(210, 227, 251, 0.95)'}
          w="100vw"
          h="100vh"
          position={'fixed'}
          right="0"
          bottom="0"
          zIndex={'10000'}
          display={'flex'}
        >
          <ApplicationResultPage
            firstName={userFirstName}
            application={applicationResult}
          />
        </Box>
      )}
    </>
  );
};

export default LawyerApplication;
