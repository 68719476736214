import React, { useEffect } from 'react';
import { Form } from 'react-final-form';
import {
  Box,
  Alert,
  Modal,
  ModalOverlay,
  ModalContent,
  Text,
  ModalCloseButton,
  ModalBody,
  Button,
  Center,
  FormControl,
  HStack,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { useToast } from '@src/hooks';
import { useTranslation } from 'react-i18next';
import { generateTimeSlots, setTheTime, formatDateZone } from '@src/utils';
import { DatePicker, SelectSingle } from '@src/components/atoms/Fields';
import {
  GetMyLawyerProfileDocument,
  useAddAvailabilityBlockMutation,
} from '@src/apollo/hooks';

const timeSlots = generateTimeSlots({
  start: 0,
  end: 24,
  interval: 30,
});
const CalendarTimeModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const toast = useToast();

  const [
    addAvailabilityBlock,
    { data, error, loading },
  ] = useAddAvailabilityBlockMutation();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = (values: any) => {
    const date = formatDateZone(values?.date);
    addAvailabilityBlock({
      variables: {
        input: {
          blockStart: format(values.slotStart, 'HH:mm:ss'),
          blockEnd: format(values?.slotEnd, 'HH:mm:ss'),
          date: date,
        },
      },
      refetchQueries: [{ query: GetMyLawyerProfileDocument }],
    });
  };

  useEffect(() => {
    if (data?.blockAvailability?.successful) {
      toast.success({
        title: t('common.success'),
        description: t('availability.block_added'),
        isClosable: true,
      });
      onClose();
    } else {
      data?.blockAvailability?.messages?.forEach((error) => {
        toast.error({
          title: t('common.error'),
          description: t(`availability.${error?.message}`),
          isClosable: true,
        });
      });
    }
  }, [data, onClose]);

  useEffect(() => {
    if (error) {
      console.log('error');
      console.log(error);
    }
  }, [error]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody pt={10}>
          <Center width="80%" margin="auto" display={'flex'} flexDir={'column'}>
            <Text as="strong">{t('availability.select_date_time')}</Text>
          </Center>
          <Form
            padding={10}
            onSubmit={onSubmit}
            render={({ handleSubmit, submitting, submitError }) => {
              return (
                <Box as="form" onSubmit={handleSubmit} py={6}>
                  {submitError && (
                    <Box pb={6}>
                      <Alert
                        variant="roo"
                        status="error"
                        title="Error"
                        description={submitError}
                      />
                    </Box>
                  )}

                  <FormControl>
                    <DatePicker
                      name="date"
                      label={t('common.date')}
                      dateFormatCalendar="MMMM"
                      showYearDropdown
                      yearDropdownItemNumber={80}
                      scrollableYearDropdown
                      minDate={new Date()}
                    />
                  </FormControl>
                  <HStack align="center" spacing={{ base: 3 }} mt={4}>
                    <FormControl>
                      <SelectSingle<Date>
                        defaultValue={setTheTime(new Date(), '09:00:00')}
                        label={t('common.start')}
                        name="slotStart"
                        options={timeSlots}
                        getOptionKey={(item) =>
                          item ? format(item, 'HH:mm') : ''
                        }
                        getOptionLabel={(item) =>
                          item ? format(item, 'hh:mm a') : ''
                        }
                        hideError={true}
                      />
                    </FormControl>
                    <FormControl>
                      <SelectSingle<Date>
                        label={t('common.end')}
                        name="slotEnd"
                        options={timeSlots}
                        getOptionKey={(item) => {
                          return item ? format(item, 'HH:mm') : '';
                        }}
                        getOptionLabel={(item) =>
                          item ? format(item, 'hh:mm a') : ''
                        }
                        hideError={true}
                      />
                    </FormControl>
                  </HStack>

                  <Box
                    display={'flex'}
                    flexDir={'row'}
                    justifyContent={'center'}
                    mt={6}
                  >
                    <Button
                      colorScheme="primary"
                      isLoading={submitting || loading}
                      type="submit"
                    >
                      {t('common.add')}
                    </Button>
                  </Box>
                </Box>
              );
            }}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CalendarTimeModal;
