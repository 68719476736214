import React, { useCallback, useRef } from 'react';
import useLocalVideoToggle from '@src/hooks/meeting/useLocalVideoToggle';
import {
  ButtonProps,
  Icon,
  IconButton,
  useBreakpointValue,
} from '@chakra-ui/react';
import { ReactComponent as CameraIcon } from '@src/icons/camera.svg';
import { ReactComponent as CameraOffIcon } from '@src/icons/video-camera-off.svg';
import { useDevices } from '@src/hooks/meeting/useDevice';

export type ToggleVideoProps = ButtonProps;

const CallControlsButtonToggleVideo = ({
  isDisabled,
  variant = 'outline',
  color = 'currentcolor',
  ...props
}: ToggleVideoProps) => {
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
  const lastClickTimeRef = useRef(0);
  const { hasVideoInputDevices } = useDevices();
  const size = useBreakpointValue({ base: 'md', md: 'lg' });

  const toggleVideo = useCallback(() => {
    if (Date.now() - lastClickTimeRef.current > 500) {
      lastClickTimeRef.current = Date.now();
      toggleVideoEnabled();
    }
  }, [toggleVideoEnabled]);

  return (
    <IconButton
      isRound
      aria-label="camera-btn"
      colorScheme={isVideoEnabled ? 'white' : 'red'}
      variant={isVideoEnabled ? variant : 'solid'}
      color={isVideoEnabled ? color : 'white'}
      size={size}
      onClick={toggleVideo}
      isDisabled={!hasVideoInputDevices || isDisabled}
      _hover={{
        bg: isVideoEnabled
          ? variant === 'outline'
            ? 'rgba(255,255,255,0.4)'
            : 'white'
          : 'red.500',
      }}
      _focus={{ boxShadow: 'none' }}
      {...props}
    >
      <Icon
        as={isVideoEnabled ? CameraIcon : CameraOffIcon}
        w={{ base: 5, sm: 6 }}
        h={{ base: 5, sm: 6 }}
        transition="ease-in-out"
      />
    </IconButton>
  );
};

export default CallControlsButtonToggleVideo;
