import React, { useEffect } from 'react';
import { Form } from 'react-final-form';
import {
  Box,
  Alert,
  Modal,
  ModalOverlay,
  ModalContent,
  Text,
  ModalCloseButton,
  ModalBody,
  Button,
  Center,
  Icon,
  FormControl,
  Radio,
} from '@chakra-ui/react';
import { useToast } from '@src/hooks';
import { useTranslation } from 'react-i18next';
import { TextareaField, RadioGroup } from '@src/components/atoms/Fields';
import { useCancelAppointmentMutation } from '@src/apollo/hooks';
import { ReactComponent as HeaderIcon } from '@src/icons/cancelMeeting.svg';

const CancelAppointmentModal = ({
  appointmentId,
  isOpen,
  onClose,
}: {
  appointmentId: number;
  otherParty: string;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const [cancelAppointment, { data, loading }] = useCancelAppointmentMutation();

  const onSubmit = ({
    reason,
    other_reason,
  }: {
    reason: string;
    other_reason: string;
  }) => {
    if (reason === 'other') {
      reason = `other:${other_reason}`;
    }
    cancelAppointment({
      variables: {
        input: { appointmentId, reason },
      },
    });
  };

  useEffect(() => {
    if (data?.cancelAppointment?.successful) {
      toast.success({
        title: t('common.success'),
        description: t('meetings.appointment_has_been_canceled'),
        isClosable: true,
      });
      onClose();
    }
  }, [data, onClose]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody pt={10}>
          <Center width="80%" margin="auto" display={'flex'} flexDir={'column'}>
            <Box
              background={'skyBlue.50'}
              rounded="lg"
              width="80px"
              height="80px"
              p={4}
              mb={6}
            >
              <Icon as={HeaderIcon} boxSize="50px" />
            </Box>
            <Text as="strong">{t('meetings.hey_wait')}</Text>
            <Text textAlign={'center'}>
              {t('meetings.are_you_sure_cancel')}
            </Text>
          </Center>
          <Form
            padding={10}
            onSubmit={onSubmit}
            render={({ handleSubmit, submitting, submitError, values }) => {
              return (
                <Box as="form" onSubmit={handleSubmit} py={6}>
                  {submitError && (
                    <Box pb={6}>
                      <Alert
                        variant="roo"
                        status="error"
                        title="Error"
                        description={submitError}
                      />
                    </Box>
                  )}
                  <FormControl>
                    <RadioGroup
                      name="reason"
                      d="flex"
                      flexDir="column"
                      width="80%"
                      margin="auto"
                      my={4}
                    >
                      <Radio value="agenda" my={1}>
                        {t('meetings.cancel_reason.agenda')}
                      </Radio>
                      <Radio value="solved" my={1}>
                        {t('meetings.cancel_reason.solved')}
                      </Radio>
                      <Radio value="other" my={1}>
                        {t('meetings.cancel_reason.other')}
                      </Radio>
                    </RadioGroup>
                  </FormControl>

                  {values.reason === 'other' && (
                    <TextareaField
                      name="other_reason"
                      placeholder={t('fields.reason.placeholder')}
                      isRequired
                      size="lg"
                    />
                  )}
                  <Box
                    display={'flex'}
                    flexDir={'row'}
                    justifyContent={'space-around'}
                    mt={6}
                  >
                    <Button
                      colorScheme="primary"
                      isLoading={submitting || loading}
                      type="submit"
                    >
                      {t('meetings.cancel_meeting')}
                    </Button>
                    <Button
                      variant="outline"
                      colorScheme="blue"
                      onClick={onClose}
                      fontSize="sm"
                    >
                      {t('common.close')}
                    </Button>
                  </Box>
                </Box>
              );
            }}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CancelAppointmentModal;
