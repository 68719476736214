/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {
  FormControl,
  RadioGroup as ChakraRadioGroup,
  FormErrorMessage,
} from '@chakra-ui/react';
import { useField } from 'react-final-form';
import { showErrorOnChange } from '@src/utils';

export const RadioGroup = ({
  name,
  value,
  fieldProps,
  formControlProps,
  showError = showErrorOnChange,
  children,
  ...props
}: any) => {
  const {
    // eslint-disable-next-line
    input: { checked, ...input },
    meta,
  } = useField(name, { type: 'checkbox', value, ...fieldProps });

  const { error } = meta;

  const isError = showError({ meta });
  return (
    <FormControl isInvalid={isError} {...formControlProps}>
      <ChakraRadioGroup my={4} {...props} {...input} colorScheme="blue">
        {children}
      </ChakraRadioGroup>
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  );
};
