import * as React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { ChakraProvider } from '@chakra-ui/react';
import * as serviceWorker from './serviceWorker';
import apolloClient from './apollo/apolloClient';
import theme from './theme';

import LegarooProvider from '@src/LegarooProvider';
import Loader from '@components/atoms/Loader';
import { LanguageContextProvider } from '@src/context';

import 'fontsource-poppins/300-normal.css';
import 'fontsource-poppins/400-normal.css';
import 'fontsource-poppins/500-normal.css';
import 'fontsource-poppins/600-normal.css';
import 'fontsource-poppins/700-normal.css';

ReactDOM.render(
  <Router>
    <ApolloProvider client={apolloClient}>
      <ChakraProvider theme={theme}>
        <React.Suspense fallback={<Loader />}>
          <LanguageContextProvider>
            <Route path="/:locale?" component={LegarooProvider} />
          </LanguageContextProvider>
        </React.Suspense>
      </ChakraProvider>
    </ApolloProvider>
  </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
