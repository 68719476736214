import React, { useContext } from 'react';
import useMainParticipant from '@src/hooks/meeting/useMainParticipant';
import { SelectedParticipantContext } from '@src/context';
import { useVideoContext } from '@src/context/VideoContext';
import ParticipantTracks from './ParticipantTracks';
import MainParticipantInfo from './MainParticipantInfo';
import useScreenShareParticipant from '@src/hooks/meeting/useScreenShareParticipant';

export default function MainParticipant() {
  const mainParticipant = useMainParticipant();

  const { meeting } = useVideoContext();

  const localParticipant = meeting?.localParticipant;

  const { selectedParticipant } = useContext(SelectedParticipantContext);
  const screenShareParticipant = useScreenShareParticipant();

  const videoPriority =
    (mainParticipant === selectedParticipant ||
      mainParticipant === screenShareParticipant) &&
    mainParticipant !== localParticipant
      ? 'high'
      : null;

  if (!mainParticipant) return null;

  return (
    /* audio is disabled for this participant component because this participant's audio 
       is already being rendered in the <ParticipantStrip /> component.  */
    <MainParticipantInfo participant={mainParticipant}>
      <ParticipantTracks
        participant={mainParticipant}
        videoOnly
        enableScreenShare={mainParticipant !== localParticipant}
        videoPriority={videoPriority}
        isLocalParticipant={mainParticipant === localParticipant}
      />
    </MainParticipantInfo>
  );
}
