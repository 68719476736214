/* eslint-disable react/display-name */
import React from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';
import DataTable from 'react-data-table-component';
import Link from '@components/atoms/Link';
import SidebarLayout from '@src/components/organisms/RooLayout/SidebarLayout';
import { useGetAllAppointmentsQuery } from '@src/apollo/hooks';
import Loader from '@src/components/atoms/Loader';
import DateFormat from '@src/components/atoms/DateFormat';

const columns = [
  {
    name: 'ID',
    cell: (row: any) => (
      <Link fontSize="sm" to={`/admin/appointments/${row?.meeting?.roomId}`}>
        <Text fontSize="xs" color="blue.500">
          {row?.meeting?.roomId}{' '}
        </Text>
      </Link>
    ),
    selector: (row: any) => row?.meeting?.roomId,
  },
  {
    name: 'Lawyer',
    selector: (row: any) => {
      return `${row?.host?.firstName} ${row?.host?.lastName}`;
    },
    sortable: true,
  },
  {
    name: 'Client',
    selector: (row: any) => {
      return `${row?.user?.profile?.firstName} ${row?.user?.profile?.lastName}`;
    },
    sortable: true,
  },
  {
    name: 'Date',
    cell: (row: any) => (
      <DateFormat date={new Date(row?.times?.startsAt)} marginRight={1} />
    ),
    selector: (row: any) => row?.times?.startsAt,
    sortable: true,
  },
  {
    name: 'From',
    cell: (row: any) => (
      <DateFormat
        date={new Date(row?.times?.startsAt)}
        format="p"
        marginRight={1}
      />
    ),
    selector: (row: any) => row?.times?.startsAt,
    sortable: true,
  },
  {
    name: 'To',
    cell: (row: any) => (
      <DateFormat
        date={new Date(row?.times?.endsAt)}
        format="p"
        marginRight={1}
      />
    ),
    selector: (row: any) => row?.times?.endsAt,
  },
  {
    name: 'Duration',
    cell: (row: any) => <div> {row?.meeting?.duration} min</div>,
    sortable: true,
  },
  {
    name: 'Payment Status',
    cell: (row: any) => <div> {row?.meeting?.status} </div>,
    sortable: true,
  },
];

const ApplicationsPage = () => {
  const { data, loading, fetchMore } = useGetAllAppointmentsQuery({
    variables: {
      first: 100,
    },
  });
  if (loading) return <Loader />;

  const nodes = data?.getAllAppointments?.edges?.map((edge) => edge?.node);
  const pageInfo = data?.getAllAppointments?.pageInfo;

  return (
    <SidebarLayout>
      <Box maxW="5xl" mx="auto" py={16} px={6}>
        <Heading mb={4}>Appointments</Heading>

        <DataTable
          striped
          columns={columns}
          data={nodes || []}
          pagination
          onChangePage={() => {
            if (pageInfo?.hasNextPage) {
              fetchMore({
                variables: { after: pageInfo.endCursor, first: 100 },
              });
            }
          }}
        />
      </Box>
      <Box></Box>
    </SidebarLayout>
  );
};

export default ApplicationsPage;
