import { Meeting } from '@src/video';
import { useEffect } from 'react';
import { Callback } from '@src/@types/twilio';

export default function useHandleStreamPublicationFailed(
  meeting: Meeting | null,
  onError: Callback
) {
  useEffect(() => {
    if (meeting) {
      meeting.localParticipant?.on('trackPublicationFailed', onError);
      return () => {
        meeting.localParticipant?.off('trackPublicationFailed', onError);
      };
    }
  }, [meeting, onError]);
}
