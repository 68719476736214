import React from 'react';
import { Box } from '@chakra-ui/react';
import MainParticipant from '@src/components/molecules/MeetingRoom/Participant/MainParticipant';
import ParticipantList from '@src/components/molecules/MeetingRoom/Participant/ParticipantList';
import VideoHeader from '@src/components/molecules/MeetingRoom/VideoHeader';
import MeetingRoomProgressBar from '@molecules/MeetingRoom/ProgressBar';

const totalMobileSidebarHeight = `${90 + 8 * 2 + 2}px`;

const Room = ({ startTime, endTime }: { startTime: Date; endTime: Date }) => {
  return (
    <Box
      position="relative"
      height="full"
      display="grid"
      gridTemplateColumns={{ base: '100%', sm: '1fr 320px' }}
      gridTemplateRows={{
        base: `calc(100% - ${totalMobileSidebarHeight}) ${totalMobileSidebarHeight}`,
        sm: '100%',
      }}
    >
      <VideoHeader />
      <MainParticipant />
      <ParticipantList />
      <Box zIndex={20}>
        <Box
          position="absolute"
          insetX={0}
          top={'0px'}
          transform="translateY(2rem)"
          d="flex"
          justifyContent="flex-end"
        >
          <MeetingRoomProgressBar startTime={startTime} endTime={endTime} />
        </Box>
      </Box>
    </Box>
  );
};

export default Room;
