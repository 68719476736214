import React, { useCallback, useMemo, useEffect } from 'react';
import {
  Button,
  chakra,
  Heading,
  SimpleGrid,
  StackDivider,
  VStack,
  Text,
  Icon,
  Spinner,
  useDisclosure,
} from '@chakra-ui/react';
import groupBy from 'lodash/fp/groupBy';
import arrayMutators from 'final-form-arrays';
import { Form } from 'react-final-form';
import {
  useGetMyLawyerProfileQuery,
  useUpdateLawyerProfileMutation,
  useDeleteAvailabilityBlockMutation,
  GetMyLawyerProfileDocument,
} from '@src/apollo/hooks';
import {
  parse,
  setHours,
  startOfHour,
  addHours,
  isBefore,
  format,
} from 'date-fns';
import { ReactComponent as DeleteIcon } from '@src/icons/delete.svg';
import WorkingHourDay from '@src/components/molecules/WorkingHoursForm/WorkingHourDay';
import { useToast } from '@src/hooks';
import DateFormat from '@src/components/atoms/DateFormat';
import { ReactComponent as ClockIcon } from '@src/icons/clock.svg';
import { ReactComponent as CalendarIcon } from '@src/icons/calendar.svg';
import { numberOfSlotHours, setTheTime, asUTC } from '@src/utils';
import { useTranslation } from 'react-i18next';
import CalendarTimePopover from '@src/components/molecules/CalendarTimePopover';

const defaultFromDate = startOfHour(setHours(new Date(), 9));
const defaultToDate = startOfHour(setHours(new Date(), 17));

function generateRangeDateAfter(date?: Date) {
  return {
    from: date ? addHours(date, 1) : defaultFromDate,
    to: date ? addHours(date, 2) : defaultToDate,
  };
}

function validate(values: Slot[]) {
  if (!values.length) return;
  return values.map((value) => {
    // eslint-disable-next-line
    const errors: any = {};
    if (value.to && value.from && isBefore(value.to, value.from)) {
      errors.to = 'Choose an end time later than the start time.';
    }
    return errors;
  });
}

export interface Slot {
  from: Date;
  to: Date;
}
export interface FormValues {
  sunday: Slot[];
  monday: Slot[];
  tuesday: Slot[];
  wednesday: Slot[];
  thursday: Slot[];
  friday: Slot[];
  saturday: Slot[];
}

export type DayType = keyof FormValues;

const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export default function ListView() {
  const { t } = useTranslation();
  const toast = useToast();
  const { data, loading } = useGetMyLawyerProfileQuery();
  const [updateLawyer] = useUpdateLawyerProfileMutation();
  const [
    deleteAvailabilityBlock,
    { data: deleteData },
  ] = useDeleteAvailabilityBlockMutation();
  const myLawyer = data?.getMyLawyer;
  const blockModal = useDisclosure();

  const initialValues = useMemo(() => {
    const currentWorkingHours = myLawyer?.workingHours.map((i) => ({
      name: i?.name,
      from: i?.from ? parse(i.from, 'HH:mm:ss', new Date()) : undefined,
      to: i?.to ? parse(i.to, 'HH:mm:ss', new Date()) : undefined,
    }));
    const grouped = groupBy((item) => item.name, currentWorkingHours);
    return {
      sunday: [],
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
      ...grouped,
    };
  }, [myLawyer]);
  const weekDaysArray = Object.keys(initialValues);

  useEffect(() => {
    if (deleteData?.deleteUnavailableBlock) {
      toast.success({
        title: t('common.success'),
        description: t('availability.block_deleted'),
      });
    }
  }, [deleteData]);

  const deleteBlock = (block: UnavailabilityBlockInput) => {
    deleteAvailabilityBlock({
      variables: { input: block },
      refetchQueries: [{ query: GetMyLawyerProfileDocument }],
    });
  };

  const handleSubmit = useCallback(
    async (values) => {
      const mapped = Object.keys(values).reduce((acc, curr) => {
        const daySlots = values[curr].map((item: Slot) => ({
          name: curr,
          from: format(item.from, 'HH:mm:ss'),
          to: format(item.to, 'HH:mm:ss'),
        }));
        return acc.concat(daySlots);
      }, []);

      try {
        const { data } = await updateLawyer({
          variables: { input: { workingHours: mapped } },
        });
        if (!data?.updateLawyer?.successful) {
          data?.updateLawyer?.messages?.forEach((error) => {
            toast.warning({ title: 'Error', description: error?.message });
          });
          return;
        }
        toast.success({
          title: t('common.success'),
          description: t('common.changes_saved'),
        });
        window.scrollTo(0, 0);
      } catch (err) {
        toast.warning({ title: t('common.error'), description: err.message });
      }
    },
    [updateLawyer, toast, t]
  );

  return (
    <chakra.div maxW="4xl" mx="auto" py={{ base: 4, sm: 6 }} px={3} mb={6}>
      <SimpleGrid columns={{ base: 1, sm: 3 }}>
        <Heading
          gridColumn="span 2 / span 2"
          fontSize={{ sm: 'lg' }}
          textAlign="left"
        >
          {t('availability.select_your_available_hours')}
        </Heading>
        <chakra.div px={{ sm: 6 }}>
          <chakra.span alignItems="center" d="flex" mt={{ base: 1, sm: 0 }}>
            <Icon as={ClockIcon} h={4} w={4} color="gray.600" />
            <chakra.span fontWeight={600} fontSize="sm" ml={1}>
              {t('common.timezone')}
            </chakra.span>
          </chakra.span>
          <Text color="gray.600" fontWeight={400} fontSize="sm" mt={1}>
            {timeZone}
          </Text>
        </chakra.div>
      </SimpleGrid>
      {loading ? (
        <chakra.div
          pt={16}
          d="flex"
          alignItems="center"
          justifyContent="center"
          h="full"
        >
          <Spinner />
        </chakra.div>
      ) : (
        <chakra.div pos="relative" mt={4}>
          <Form<FormValues>
            initialValues={initialValues}
            mutators={{
              ...arrayMutators,
              copyValue: ([from, to], state, { changeValue }) => {
                const newValue = (state.formState.values as FormValues)[
                  from as DayType
                ];
                changeValue(state, to, () => {
                  return newValue;
                });
              },
            }}
            onSubmit={handleSubmit}
          >
            {({
              handleSubmit,
              submitting,
              pristine,
              values,
              form: {
                mutators: { push, copyValue },
                change,
              },
            }) => {
              const timeSlots = Object.values(values).flat();
              const totalHours = numberOfSlotHours(timeSlots);
              return (
                <>
                  <chakra.div d="flex" alignItems="center" pb={6}>
                    <Icon as={CalendarIcon} h={6} w={6} color="gray.600" />
                    <DateFormat
                      ml={2}
                      date={defaultToDate}
                      format="MMMM, yyyy"
                      fontSize={{ sm: 'lg' }}
                      fontWeight={700}
                    />
                    <chakra.span
                      ml={2}
                      color="gray.600"
                      fontSize={{ sm: 'lg' }}
                      fontWeight={700}
                    >
                      {totalHours}h
                    </chakra.span>
                  </chakra.div>
                  <SimpleGrid
                    columns={{ base: 1, sm: 3 }}
                    border="1px"
                    borderColor="skyBlue.100"
                    rounded="md"
                  >
                    <chakra.div
                      gridColumn="span 2 / span 2"
                      borderRight={{ sm: '1px' }}
                      borderBottom={{ base: '1px', sm: 0 }}
                      borderRightColor={{ sm: 'skyBlue.100' }}
                      borderBottomColor="skyBlue.100"
                    >
                      <form onSubmit={handleSubmit}>
                        <VStack
                          align="initial"
                          spacing={0}
                          divider={
                            <StackDivider borderColor="rgba(160, 199, 249, 0.33)" />
                          }
                        >
                          {(weekDaysArray as DayType[]).map((day) => {
                            const daySlots = values[day];
                            const lastSlot = daySlots[daySlots.length - 1];
                            return (
                              <WorkingHourDay
                                key={day}
                                name={day}
                                onClear={() => change(day, [])}
                                onCopySlot={(to) => copyValue(day, to)}
                                onAddSlot={() =>
                                  push(
                                    day,
                                    generateRangeDateAfter(lastSlot?.to)
                                  )
                                }
                                config={{ validate }}
                              />
                            );
                          })}
                          <chakra.div
                            d="flex"
                            alignItems="center"
                            justifyContent={{
                              base: 'center',
                              sm: 'flex-start',
                            }}
                            px={{ base: 4, sm: 6, lg: 10 }}
                            py={3}
                          >
                            <Button
                              isDisabled={submitting || pristine}
                              isLoading={submitting}
                              px={10}
                              type="submit"
                              colorScheme="primary"
                              fontSize="sm"
                            >
                              {t('common.save')}
                            </Button>
                          </chakra.div>
                        </VStack>
                      </form>
                    </chakra.div>
                    <chakra.div px={{ base: 4, sm: 6 }} py={6} p={{ lg: 8 }}>
                      <chakra.div
                        d="flex"
                        alignItems="center"
                        justifyContent="center"
                        flexDir="column"
                      >
                        <CalendarTimePopover
                          isOpen={blockModal.isOpen}
                          onClose={blockModal.onClose}
                        />

                        <Button
                          variant="outline"
                          colorScheme="primary"
                          borderColor="primary"
                          color="primary"
                          fontSize="sm"
                          onClick={() => blockModal.onOpen()}
                        >
                          {t('availability.add_date_override')}
                        </Button>
                        <Text
                          textAlign="center"
                          pt={4}
                          fontSize="xs"
                          color="gray.600"
                          fontWeight={500}
                        >
                          {t('availability.add_date_override_message')}
                        </Text>
                      </chakra.div>
                      <chakra.div>
                        {data?.getMyLawyer?.unavailableBlocks?.map(
                          (block, index) => {
                            const timeOne = setTheTime(
                              new Date(),
                              block?.blockStart
                            );
                            const timeTwo = setTheTime(
                              new Date(),
                              block?.blockEnd
                            );
                            return (
                              <chakra.div key={index} fontSize="sm" my={4}>
                                <Text>
                                  <Icon as={CalendarIcon} h={4} w={4} mr={1} />
                                  <DateFormat
                                    date={asUTC(new Date(block?.date))}
                                    format="MMMM d,yyyy"
                                    marginRight={1}
                                  />
                                </Text>
                                <chakra.div
                                  display={'flex'}
                                  alignItems="center"
                                  justifyContent={'space-between'}
                                >
                                  <Text
                                    as="span"
                                    fontWeight={'bold'}
                                    color={'blue.500'}
                                    d="flex"
                                    alignItems={'center'}
                                  >
                                    {' '}
                                    <Icon as={ClockIcon} h={4} w={4} mr={1} />
                                    <DateFormat
                                      date={timeOne}
                                      format="hh:mm a"
                                      marginLeft={1}
                                    />
                                    -
                                    <DateFormat
                                      date={timeTwo}
                                      format="hh:mm a"
                                      marginRight={2}
                                    />
                                  </Text>
                                  <Button
                                    color="skyBlue.100"
                                    _hover={{ color: 'blue.200' }}
                                    _focus={{ color: 'blue.200' }}
                                    variant="unstyled"
                                    onClick={() =>
                                      deleteBlock({
                                        blockEnd: block?.blockEnd,
                                        blockStart: block?.blockStart,
                                        date: block?.date,
                                      })
                                    }
                                  >
                                    <Icon as={DeleteIcon} h={4} w={4} mr={1} />
                                  </Button>
                                </chakra.div>
                              </chakra.div>
                            );
                          }
                        )}
                      </chakra.div>
                    </chakra.div>
                  </SimpleGrid>
                </>
              );
            }}
          </Form>
        </chakra.div>
      )}
    </chakra.div>
  );
}
