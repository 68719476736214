import React, { useCallback } from 'react';
import {
  RenderProps,
  useToast as useChakraToast,
  UseToastOptions,
} from '@chakra-ui/react';
import { Alert, ToastOptions } from '@src/components/atoms/Alert';

const defaultOptions = {
  duration: 5000,
  position: 'top-right',
  variant: 'roo',
  isClosable: true,
} as const;

export function useToast(options?: UseToastOptions) {
  const toastOptions = {
    ...defaultOptions,
    ...options,
  };
  const toast = useChakraToast(toastOptions);

  const success = useCallback(
    (options: ToastOptions) => {
      const opts = { ...toastOptions, ...options };
      toast({
        status: 'success',
        ...opts,
        render: function SuccessAlert(
          props: React.PropsWithChildren<RenderProps>
        ) {
          return <Alert status="success" {...opts} {...props} />;
        },
      });
    },
    [toast, toastOptions]
  );

  const info = useCallback(
    (options: ToastOptions) => {
      const opts = { ...toastOptions, ...options };
      toast({
        status: 'info',
        ...opts,
        render: function InfoAlert(
          props: React.PropsWithChildren<RenderProps>
        ) {
          return <Alert status="info" {...opts} {...props} />;
        },
      });
    },
    [toast, toastOptions]
  );

  const warning = useCallback(
    (options: ToastOptions) => {
      const opts = { ...toastOptions, ...options };
      toast({
        status: 'warning',
        ...opts,
        render: function WarningAlert(
          props: React.PropsWithChildren<RenderProps>
        ) {
          return <Alert status="warning" {...opts} {...props} />;
        },
      });
    },
    [toast, toastOptions]
  );
  const error = useCallback(
    (options: ToastOptions) => {
      const opts = { ...toastOptions, ...options };
      toast({
        status: 'error',
        ...opts,
        render: function WarningAlert(
          props: React.PropsWithChildren<RenderProps>
        ) {
          return <Alert status="error" {...opts} {...props} />;
        },
      });
    },
    [toast, toastOptions]
  );
  return { success, warning, info, error };
}

export default useToast;
