import React, { useMemo } from 'react';
import { Box, Text, Avatar } from '@chakra-ui/react';

import {
  LocalAudioStream,
  LocalVideoStream,
  Participant,
  RemoteVideoStream,
  RemoteAudioStream,
} from '@src/video';

import useIsTrackSwitchedOff from '@src/hooks/meeting/useIsTrackSwitchedOff';
import usePublications from '@src/hooks/meeting/usePublications';
import useTrack from '@src/hooks/meeting/useTrack';
import useParticipantIsReconnecting from '@src/hooks/meeting/useParticipantIsReconnecting';
import MeetingRoomAudioIndicatorLocal from '@src/components/molecules/MeetingRoom/Audio/Indicator/Local';
import useScreenShareParticipant from '@src/hooks/meeting/useScreenShareParticipant';
import { useVideoContext } from '@src/context/VideoContext';

interface ParticipantInfoProps {
  participant: Participant;
  children: React.ReactNode;
}

export default function MainParticipantInfo({
  participant,
  children,
}: ParticipantInfoProps) {
  const identity = useMemo(() => participant.identity.split('_')[2], [
    participant,
  ]);

  const { meeting } = useVideoContext();
  const localParticipant = meeting!.localParticipant;

  const screenShareParticipant = useScreenShareParticipant();
  const isRemoteParticipantScreenSharing =
    screenShareParticipant && screenShareParticipant !== localParticipant;

  const publications = usePublications(participant);

  const audioPublication = publications.find((p) => p.kind === 'audio');
  const videoPublication = publications.find((p) =>
    p.trackName.includes('camera')
  );
  const isScreenSharing = publications.find((p) =>
    p.trackName.includes('screen')
  );

  const isVideoEnabled = Boolean(videoPublication);

  const videoTrack = useTrack(videoPublication);
  const isVideoSwitchedOff = useIsTrackSwitchedOff(
    videoTrack as LocalVideoStream | RemoteVideoStream
  );

  const audioTrack = useTrack(audioPublication) as
    | LocalAudioStream
    | RemoteAudioStream
    | undefined;

  const isParticipantReconnecting = useParticipantIsReconnecting(participant);

  return (
    <Box
      position="relative"
      display="flex"
      alignItems="center"
      {...(!isRemoteParticipantScreenSharing && {
        gridArea: { base: '1 / 1 / 3 / 3', sm: '1 / 1 / 2 / 3' },
      })}
    >
      <Box>
        {(!isVideoEnabled || isVideoSwitchedOff) && !isScreenSharing && (
          <MeetingRoomAudioIndicatorLocal
            size={128}
            sourceAudioTrack={audioTrack}
          >
            <Avatar size="2xl" name={identity} />
          </MeetingRoomAudioIndicatorLocal>
        )}
      </Box>
      {isParticipantReconnecting && (
        <Text variant="secondary" color="white">
          Reconnecting...
        </Text>
      )}
      {children}
    </Box>
  );
}
