import React, {
  createContext,
  ReactNode,
  useState,
  useContext,
  useCallback,
} from 'react';
import { MeetingError } from '@src/video';
import { useGenerateTwilioTokenMutation } from '@src/apollo/hooks';

export interface RoomTokenContextType {
  error: MeetingError | null;
  setError(error: MeetingError | null): void;
  getToken(name: string, room: string): Promise<{ token: string | null }>;
  loading: boolean;
}

export const RoomTokenContext = createContext<RoomTokenContextType | undefined>(
  undefined
);

export function RoomTokenProvider(props: { children: ReactNode }) {
  const [error, setError] = useState<MeetingError | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [generateTwilioToken] = useGenerateTwilioTokenMutation();

  const contextValue = {
    error,
    setError,
    loading: isLoading,
    getToken: async (identity: string, roomName: string) => {
      try {
        const response = await generateTwilioToken({
          variables: {
            input: {
              identity: identity,
              roomId: roomName,
            },
          },
        });
        return response.data?.generateTwilioToken?.result;
      } catch (err) {
        throw new Error(err);
      }
    },
  } as RoomTokenContextType;

  const getToken = useCallback(
    async (name: string, room: string) => {
      setIsLoading(true);
      try {
        const response = await contextValue.getToken(name, room);
        setIsLoading(false);
        return response;
      } catch (err) {
        setError(err);
        setIsLoading(false);
        return { token: null };
      }
    },
    [contextValue]
  );
  return (
    <RoomTokenContext.Provider
      value={{ ...contextValue, getToken }}
      {...props}
    />
  );
}

export function useRoomTokenContext() {
  const context = useContext(RoomTokenContext);
  if (!context) {
    throw new Error('useAppState must be used within the AppStateProvider');
  }
  return context;
}
