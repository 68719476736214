import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import Route from '@src/PrivateRoute';
import Applications from './ApplicationsPage';
import Lawyers from './LawyersPage';
import LawyerApplication from './LawyerApplication';
import Appointments from './Appointments';
import AppointmentInfo from './Appointments/AppointmentInfo';

const Admin = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}/lawyers`} prefixLocale={false}>
        <Lawyers />
      </Route>
      <Route exact path={path} prefixLocale={false}>
        <Applications />
      </Route>
      <Route exact path={`${path}/applications`} prefixLocale={false}>
        <Applications />
      </Route>
      <Route
        exact
        path={`${path}/application/:application_id`}
        prefixLocale={false}
      >
        <LawyerApplication />
      </Route>
      <Route exact path={`${path}/appointments`} prefixLocale={false}>
        <Appointments />
      </Route>
      <Route
        exact
        path={`${path}/appointments/:meeting_id`}
        prefixLocale={false}
      >
        <AppointmentInfo />
      </Route>
    </Switch>
  );
};

export default Admin;
