import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Heading } from '@chakra-ui/react';
import SidebarLayout from '@src/components/organisms/RooLayout/SidebarLayout';
import PayoutHistory from './PayoutHistory';
import ConnectAccount from './ConnectAccount';
import { useViewerQuery } from '@src/apollo/hooks';
import Loader from '@atoms/Loader';

const MyPaymentsPage = () => {
  const { t } = useTranslation();
  const { data, loading } = useViewerQuery();

  if (loading) return <Loader />;

  return (
    <SidebarLayout>
      <Box maxW="5xl" mx="auto" py="5">
        <Heading ml={3} color="blue.100" textAlign="left">
          {t('availability.lawyer_payouts')}
        </Heading>
      </Box>
      <Box pos="relative">
        {data?.viewer?.hasConnectedAccount ? (
          <PayoutHistory />
        ) : (
          <ConnectAccount />
        )}
      </Box>
    </SidebarLayout>
  );
};

export default MyPaymentsPage;
