import React from 'react';
import { Box, Icon } from '@chakra-ui/react';
import { ReactComponent as Logo } from '@src/icons/white-logo.svg';

const VideoHeader = () => {
  return (
    <Box
      as="header"
      position="absolute"
      insetX={0}
      top={0}
      zIndex={20}
      transform="translateY(2rem)"
    >
      <Box maxW="6xl" w="full" mx="auto" px={{ base: 4, lg: 8 }}>
        <Box
          pos="relative"
          display={{ md: 'flex' }}
          justifyContent={{ md: 'space-between' }}
        >
          <Icon as={Logo} w={{ base: 24, sm: 32, lg: 40 }} h="auto" />
          <Box
            position={{ base: 'relative', sm: 'absolute' }}
            inset={{ sm: 0 }}
            zIndex={0}
            px={2}
            flex="1 1 0%"
            d="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Box
              mt={{ base: 4, md: 0 }}
              maxW={{ base: '2xs', md: 'xs' }}
              w="full"
              d="flex"
              alignItems="center"
            >
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default VideoHeader;
