/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Box, Text, Heading } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import DateFormat from '@src/components/atoms/DateFormat';
import SidebarLayout from '@src/components/organisms/RooLayout/SidebarLayout';
import { useGetRoomsByUniqueNameQuery } from '@src/apollo/hooks';
import Loader from '@src/components/atoms/Loader';

const AppointmentInfo = () => {
  const { meeting_id } = useParams<{ meeting_id: string }>();
  const { data, loading } = useGetRoomsByUniqueNameQuery({
    variables: { uniqueName: meeting_id },
  });
  const sessions = data?.getRoomsByUniqueName?.rooms;
  const allParticipants =
    data?.getRoomsByUniqueName?.participants?.participants;

  if (loading) return <Loader />;
  return (
    <SidebarLayout>
      <Box maxW="4xl" mx="auto" mt={16} py={4} px={6}>
        <Heading>Connection Information</Heading>
      </Box>
      <Box maxW="4xl" mx="auto" px={6}>
        {sessions?.length === 0 && <Text>No connection information</Text>}
        {sessions?.map((session: any) => {
          const sessionParticipants = allParticipants.filter(
            (p: any) => p?.room_sid === session.sid
          );
          return (
            <Box key={session.sid} background="white" rounded="md" mb={2} p={4}>
              <h2>
                <Box d="flow">
                  Session:
                  <Text as="span" color="blue.600">
                    {session.sid}{' '}
                  </Text>
                  Duration:{' '}
                  <Text as="span" color="blue.600">
                    {session.duration}{' '}
                  </Text>
                  seconds, is{' '}
                  <Text as="span" color="blue.600">
                    {session.status}
                  </Text>
                </Box>
              </h2>
              <Box mt={4}>
                {sessionParticipants?.map((p: any) => {
                  const identity = p.identity?.split('_') || ['', '', ''];
                  return (
                    <Box
                      key={p.sid}
                      d="flex"
                      flexDir="column"
                      rounded="md"
                      boxShadow="lg"
                      mb={2}
                      p={4}
                    >
                      <Box d="flow">
                        <Text as="strong" color="blue.500">
                          {identity[1]}:{' '}
                        </Text>
                        <Text as="span" fontStyle="secondary">
                          {identity[2]}
                        </Text>
                      </Box>

                      <Text>Duration: {p.duration}</Text>
                      <Text>
                        Start at:{' '}
                        {p?.start_time && (
                          <DateFormat
                            format={"EEEE. LLLL do 'at' p"}
                            date={new Date(p?.start_time)}
                          />
                        )}
                      </Text>
                      <Text>
                        Ended at:{' '}
                        {p?.end_time && (
                          <DateFormat
                            format={"EEEE. LLLL do 'at' p"}
                            date={new Date(p?.end_time)}
                          />
                        )}
                      </Text>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          );
        })}
      </Box>
    </SidebarLayout>
  );
};

export default AppointmentInfo;
