import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Text, Button, Stack, VStack, Checkbox } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { parse } from 'date-fns';
import {
  useGetLawyerApplicationQuery,
  useMoveToInReviewLawyerApplicationMutation,
  useMarkApplicationFeedbackCompleteMutation,
  useApproveLawyerApplicationMutation,
  useDeleteLawyerApplicationMutation,
  useRejectLawyerApplicationMutation,
} from '@src/apollo/hooks';
import ApplicationExtraInformation from '@molecules/ApplicationForm/ApplicationExtraInformation';
import BasicInformation from '@molecules/ApplicationForm/BasicInformation';
import Loader from '@atoms/Loader';
import DateFormat from '@src/components/atoms/DateFormat';
import { centsToDollar } from '@src/utils';
import AlertDialogWrapper from '@atoms/AlertDialog';
import { uniq } from 'lodash';

export interface LawyerApplicationFormReturnValues {
  bio: Maybe<string>;
  phone: string;
  hourlyRate: number;
  headshot: string;
  legalPractices: Option[];
  spokenLanguages: Option[];
  workingHours: { from: Date; to: Date; days: string[] };
}

function ApplicationInformation() {
  const { t } = useTranslation();
  const { application_id } = useParams<
    AdminLawyerApplicationParams & BaseRouteParams
  >();

  const { data, loading } = useGetLawyerApplicationQuery({
    variables: {
      applicationId: parseInt(application_id, 0),
    },
  });

  const [
    moveToInReviewLawyerApplicationMutation,
  ] = useMoveToInReviewLawyerApplicationMutation();

  const [
    markApplicationFeedbackCompleteMutation,
  ] = useMarkApplicationFeedbackCompleteMutation();

  const [
    approveLawyerApplicationMutation,
  ] = useApproveLawyerApplicationMutation();

  const [
    deleteLawyerApplicationMutation,
  ] = useDeleteLawyerApplicationMutation();

  const [
    rejectLawyerApplicationMutation,
  ] = useRejectLawyerApplicationMutation();

  const applicationData = data?.getLawyerApplication;

  const submitForReview = () => {
    moveToInReviewLawyerApplicationMutation({
      variables: {
        applicationId: parseInt(application_id, 0),
      },
    });
  };

  const markFeedbackComplete = (id: string | null | undefined) => {
    if (id) {
      markApplicationFeedbackCompleteMutation({
        variables: {
          feedbackId: parseInt(id, 0),
        },
      });
    }
  };

  const approveLawyerApplication = () => {
    approveLawyerApplicationMutation({
      variables: {
        applicationId: parseInt(application_id, 0),
      },
    });
  };
  const deleteLawyerApplication = () => {
    deleteLawyerApplicationMutation({
      variables: {
        applicationId: parseInt(application_id, 0),
      },
    });
    window.location.href = '/admin/applications';
  };
  const rejectLawyerApplication = () => {
    rejectLawyerApplicationMutation({
      variables: {
        applicationId: parseInt(application_id, 0),
      },
    });
    window.location.reload();
  };

  const defaultValues = useMemo(() => {
    return {
      bio: applicationData?.bio,
      phone: applicationData?.phone || '',
      headshot: applicationData?.headshot || '',
      hourlyRate: centsToDollar(applicationData?.hourlyRate || 0),
      spokenLanguages: applicationData?.spokenLanguages?.codes,
      legalPractices: applicationData?.legalPractices?.slugs,
      workingHours: {
        from: parse(
          applicationData?.workingHours?.hours[0]?.from || '08:00:00',
          'HH:mm:ss',
          new Date()
        ),
        to: parse(
          applicationData?.workingHours?.hours[0]?.to || '15:00:00',
          'HH:mm:ss',
          new Date()
        ),
        days:
          applicationData?.workingHours?.hours.length > 0
            ? uniq(
                applicationData?.workingHours?.hours.map(
                  (workingOn: LawyerTimeSlotsInput) => workingOn.name
                )
              )
            : ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'],
      },
    };
  }, [applicationData]);

  if (loading) return <Loader />;
  return (
    <>
      <Box maxW="6xl" mx="auto" px={6}>
        <Box
          position="relative"
          top="-50"
          d="flex"
          w="full"
          flexDirection="column"
          justifyContent="top"
          bgColor="white"
          padding={{ base: 6, md: 50 }}
          rounded={15}
          shadow={'0px 0px 40px rgba(0, 0, 0, 0.15)'}
        >
          <VStack spacing={4} alignItems="flex-start" w="100%" my={8} mx="auto">
            <Text fontSize="2xl">
              <Text as="span" textStyle="strongBlue">
                {t('lawyer_application.admin.status_title')}{' '}
              </Text>
              <Text as="span" textStyle="strongYellow">
                {applicationData?.firstName} {applicationData?.lastName}
              </Text>
              <Text as="span" textStyle="strongBlue">
                {' '}
                {t('lawyer_application.admin.is')}
              </Text>
              <Text
                as="span"
                textStyle={
                  applicationData?.status === 'approved'
                    ? 'strongGreen'
                    : 'strongYellow'
                }
              >
                {t(`lawyer_application.status.${applicationData?.status}`)}{' '}
              </Text>
            </Text>
            <Text textStyle="strongBlue">
              {t('lawyer_application.created_at')}
              {'  '}
              <DateFormat date={new Date(applicationData?.insertedAt)} />
            </Text>
            <Stack
              w="100%"
              bg="linear-gradient(180deg, #D2E3FB 0%, rgba(255, 255, 255, 0) 100%)"
              rounded="lg"
              p={6}
            >
              <Text as="span" textStyle="strongBlue">
                {t('lawyer_application.basic_information')}
              </Text>

              {applicationData && (
                <BasicInformation applicationData={applicationData} />
              )}
            </Stack>

            <Stack
              w="100%"
              bg="linear-gradient(180deg, #D2E3FB 0%, rgba(255, 255, 255, 0) 100%)"
              rounded="lg"
              p={6}
              mt={4}
            >
              <Text as="span" textStyle="strongBlue">
                {t('lawyer_application.extra_information')}
              </Text>
              <ApplicationExtraInformation
                canUpdate={false}
                initialValues={defaultValues}
                onSubmit={() => console.log('no update')}
              />
            </Stack>

            <Stack w="100%" bg="orange.100" rounded="lg" p={6}>
              <Text as="span" textStyle="strongYellow">
                {t('lawyer_application.application_feedback')}
              </Text>
              <Text fontSize={'sm'}>
                {t('lawyer_application.application_feedback_description')}
              </Text>

              {applicationData?.status !== 'draft' &&
                applicationData?.feedback?.map((feedback, index) => {
                  return (
                    <Box key={index}>
                      <Checkbox
                        colorScheme={'yellow'}
                        isChecked={!!feedback?.completedAt}
                      >
                        <Text
                          whiteSpace="pre-wrap"
                          fontSize="sm"
                          fontWeight="600"
                        >
                          {t(
                            `lawyer_application.feedback.${feedback?.feedback}`
                          )}
                        </Text>
                      </Checkbox>
                      {!feedback?.completed && (
                        <Button
                          colorScheme="orange"
                          onClick={() => markFeedbackComplete(feedback?.id)}
                          mx={4}
                        >
                          Mark Feedback Completed
                        </Button>
                      )}
                      {feedback?.completed && (
                        <Text
                          color="green.500"
                          fontSize="sm"
                          fontWeight="600"
                          m={2}
                        >
                          Completed at: {feedback?.completedAt}
                        </Text>
                      )}
                    </Box>
                  );
                })}
            </Stack>
          </VStack>
        </Box>
        <Box display={'flex'} justifyContent="space-evenly">
          {applicationData?.status === 'submitted_for_review' && (
            <Button colorScheme="green" onClick={submitForReview}>
              Move to in Review
            </Button>
          )}
          <AlertDialogWrapper
            colorScheme="red"
            buttonText={t('lawyer_application.reject')}
            onSubmit={rejectLawyerApplication}
            submitText={t('common.continue')}
            cancelText={t('common.cancel')}
            dialogDescription={t(
              'lawyer_application.submit_reject_application'
            )}
            dialogHeader={t('lawyer_application.submit_warning_header')}
          />
          <AlertDialogWrapper
            colorScheme="red"
            buttonText={t('lawyer_application.delete')}
            onSubmit={deleteLawyerApplication}
            submitText={t('common.continue')}
            cancelText={t('common.cancel')}
            dialogDescription={t(
              'lawyer_application.submit_delete_application'
            )}
            dialogHeader={t('lawyer_application.submit_warning_header')}
          />

          {applicationData?.status === 'under_review' && (
            <AlertDialogWrapper
              buttonText={t('lawyer_application.approve')}
              onSubmit={approveLawyerApplication}
              submitText={t('common.continue')}
              cancelText={t('common.cancel')}
              dialogDescription={t(
                'lawyer_application.submit_approve_description'
              )}
              dialogHeader={t('lawyer_application.submit_warning_header')}
            />
          )}
        </Box>
      </Box>
    </>
  );
}

export default ApplicationInformation;
