import React from 'react';
import {
  Alert,
  AlertDescription,
  AlertTitle,
  AlertIcon,
  Box,
  Text,
  CloseButton,
} from '@chakra-ui/react';
import Link from '@components/atoms/Link';
import { useTranslation } from 'react-i18next';

const LawyerAccountAlert = () => {
  const { t } = useTranslation();
  return (
    <Alert status="error">
      <AlertIcon />
      <Box flex="1">
        <AlertTitle>{t('payouts.warning.title')}</AlertTitle>
        <AlertDescription display="block">
          {t('payouts.warning.description')}
          <Link variant="outline" colorScheme="blue.500" to="/payouts">
            <Text
              as="span"
              fontSize="md"
              fontWeight="bold"
              color="blue.500"
              decoration="underline"
            >
              {t('payouts.payout_dashboard')}
            </Text>
          </Link>
        </AlertDescription>
      </Box>
      <CloseButton position="absolute" right="8px" top="8px" />
    </Alert>
  );
};

export default LawyerAccountAlert;
