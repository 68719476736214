import React, { useCallback } from 'react';
import { Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Button,
  IconButton,
  Icon,
  Text,
  Heading,
  chakra,
} from '@chakra-ui/react';
import { useAuthModalContext } from '@src/context';
import { EmailField } from '@src/components/atoms/Fields';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ChevronLeftIcon } from '@src/icons/chevron-left.svg';
import { ReactComponent as LegarooIcon } from '@src/icons/white-logo.svg';
import { ReactComponent as TextureAbst } from '@src/icons/abst-invert.svg';
import { useToast } from '@src/hooks';
import { useAuth0 } from '@src/hooks';
// import { useResetPasswordMutation } from '@src/apollo/hooks';
import { Alert } from '@src/components/atoms/Alert';

const ForgotPasswordModal = () => {
  const toast = useToast();
  const { t } = useTranslation();
  // const [resetPassword] = useResetPasswordMutation();
  const { resetPassword } = useAuth0();
  const { signin, forgotPassword } = useAuthModalContext();
  const onOverlayClick = () => {
    forgotPassword.onClose();
    signin.onOpen();
  };
  const onSubmit = useCallback(
    async ({ email }) => {
      try {
        await resetPassword({ email });
        forgotPassword.onClose();
        toast.success({
          description: t('auth.forgot_password_success', { email }),
          isClosable: true,
          duration: 5000,
          position: 'top',
        });
      } catch (error) {
        // if (error?.message === 'User not found') {
        forgotPassword.onClose();
        toast.success({
          description: t('auth.forgot_password_success', { email }),
          isClosable: true,
          duration: 5000,
          position: 'top',
        });
        // } else {
        return { [FORM_ERROR]: error?.message };
        // }
      }
    },
    [toast, t, forgotPassword, resetPassword]
  );
  return (
    <Modal
      isCentered
      isOpen={forgotPassword.isOpen}
      onClose={forgotPassword.onClose}
      onOverlayClick={onOverlayClick}
      closeOnOverlayClick={false}
      closeOnEsc={false}
      size="3xl"
    >
      <ModalOverlay>
        <ModalContent rounded="3xl">
          <IconButton
            size="sm"
            aria-label="Back"
            icon={<Icon as={ChevronLeftIcon} h={5} w={5} />}
            position="absolute"
            zIndex={50}
            color="black"
            top={4}
            left={{ base: 3, md: 6 }}
            onClick={onOverlayClick}
          />
          <ModalBody p={0}>
            <Box position="relative" bg="white" overflow="hidden" rounded="3xl">
              <Box
                position={{ md: 'relative' }}
                zIndex={{ md: 10 }}
                bg="white"
                px={{ base: 6, md: 12 }}
                py={16}
                mb={8}
                w={{ base: 'full', md: '60%' }}
              >
                <Icon
                  viewBox="0 0 100 100"
                  fill="currentColor"
                  preserveAspectRatio="none"
                  display={{ base: 'hidden', md: 'block' }}
                  position="absolute"
                  right={0}
                  insetY={0}
                  h="full"
                  w={12}
                  color="white"
                  transform="translateX(50%)"
                >
                  <polygon points="50,0 100,0 50,100 0,100" />
                </Icon>

                <Heading
                  as="h4"
                  mt={4}
                  fontWeight="bold"
                  fontSize="2xl"
                  lineHeight={6}
                >
                  {t('auth.forgot_password_modal.header')}
                </Heading>
                <Text mt={3}>{t('auth.forgot_password_modal.body')}</Text>
                <Form
                  onSubmit={onSubmit}
                  render={({ handleSubmit, submitting, submitError }) => (
                    <Box as="form" onSubmit={handleSubmit} py={6}>
                      {submitError && (
                        <chakra.div pb={6}>
                          <Alert
                            variant="roo"
                            status="error"
                            title="Error"
                            description={submitError}
                          />
                        </chakra.div>
                      )}
                      <EmailField
                        placeholder={t('fields.email.placeholder')}
                        label={t('fields.email.label')}
                      />
                      <Button
                        colorScheme="orange"
                        mt={6}
                        isLoading={submitting}
                        type="submit"
                      >
                        {t('auth.send_reset_link')}
                      </Button>
                    </Box>
                  )}
                />
              </Box>

              <Box
                display={{ base: 'hidden', md: 'block' }}
                bg="primary"
                position="absolute"
                insetY={{ md: 0 }}
                right={{ md: 0 }}
                color="white"
                w={{ md: '40%' }}
              >
                <Icon
                  as={TextureAbst}
                  position="absolute"
                  right={0}
                  bottom={0}
                  transform="translateX(50%) translateY(-2.5rem)"
                  w={56}
                  h="auto"
                />
                <Box
                  pt={16}
                  px={6}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Icon as={LegarooIcon} w={48} h="auto" />
                </Box>
              </Box>
            </Box>
          </ModalBody>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};

export default ForgotPasswordModal;
