import React, { useEffect } from 'react';
import { Box, Flex, Heading, Spinner } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useRoomTokenContext } from '@src/context/RoomTokenContext';
import VideoStateProvider from '@src/context/VideoContext';
import ErrorDialog from '@molecules/MeetingRoom/ErrorDialog';
import Loader from '@atoms/Loader';
import useRoomState from '@src/hooks/meeting/useRoomState';
import PreJoinScreens from '@src/components/organisms/PreJoinScreens';
import Room from './Room';
import MenuBar from '@src/components/molecules/MeetingRoom/MenuBar';
import { useToast } from '@src/hooks';
import { useGetAppointmentByRoomQuery } from '@src/apollo/hooks';
import useHeight from '@src/hooks/meeting/useHeight';
import useIsHostConnected from '@src/hooks/meeting/useIsHostConnected';
import { ErrorCallback } from '@src/@types/twilio';

const VideoApp = ({ viewer }: { viewer?: User }) => {
  const { error, setError } = useRoomTokenContext();
  return (
    <VideoStateProvider onError={setError as ErrorCallback}>
      <ErrorDialog dismissError={() => setError(null)} error={error} />
      <App viewer={viewer as User} />
    </VideoStateProvider>
  );
};

function App({ viewer }: { viewer: User }) {
  const { meeting_id } = useParams<RoomParamTypes>();
  const { t } = useTranslation();
  const roomState = useRoomState();
  const isHostConnected = useIsHostConnected();
  const toast = useToast();
  const height = useHeight();

  const { data, loading: meetingInfoLoading } = useGetAppointmentByRoomQuery({
    variables: {
      roomId: meeting_id,
    },
  });

  const disconnected = roomState === 'disconnected';
  const reconnecting = roomState === 'reconnecting';

  useEffect(() => {
    if (reconnecting) {
      toast.warning({
        title: 'Connection Lost:',
        description: 'Reconnecting to room...',
        isClosable: true,
      });
    }
  }, [toast, reconnecting]);

  const showWaiting = viewer.roles?.includes('lawyer')
    ? false
    : !isHostConnected;
  if (meetingInfoLoading) return <Loader />;

  return (
    <Box
      display="grid"
      gridTemplateRows="1fr auto"
      minH="100vh"
      h={height}
      role="group"
    >
      {disconnected ? (
        <PreJoinScreens viewer={viewer} />
      ) : showWaiting ? (
        <Flex w="100vw" h="100vh" alignItems="center" justify="center">
          <Flex flexDir="column" alignItems="center" justifyContent="center">
            <Heading
              as="h2"
              fontSize={{ base: 'xl', md: '3xl' }}
              px={{ base: 4, sm: 6, lg: 8 }}
              mb={6}
              color="gray.700"
              fontWeight="semibold"
            >
              {t('meetings.waiting_host')}
            </Heading>
            <Spinner size="lg" color="gray.700" />
          </Flex>
        </Flex>
      ) : (
        <Box
          overflow="hidden"
          paddingBottom={{ sm: '72px' }}
          backgroundColor="black"
          background="url('/images/lobby-background.svg') no-repeat"
          backgroundSize="cover"
          _before={{
            content: '""',
            width: '100%',
            height: '30%',
            position: 'absolute',
            zIndex: 1,
            top: 0,
            left: 0,
            opacity: 0.4,
            background:
              'linear-gradient(180deg, #081B36 -15.18%, rgba(196, 196, 196, 0) 100%)',
          }}
        >
          {data?.getAppointmentByRoom && (
            <Room
              startTime={data?.getAppointmentByRoom.times.startsAt}
              endTime={data?.getAppointmentByRoom.times.endsAt}
            />
          )}
          <MenuBar />
        </Box>
      )}
    </Box>
  );
}

export default VideoApp;
