import React from 'react';
import { Box, Text, Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const ConnectAccount = () => {
  const { t } = useTranslation();

  return (
    <Box maxW="5xl" mx="auto" py={8} px={3}>
      <Box d={{ md: 'flex' }} justifyContent={{ md: 'space-between' }}>
        <Text fontSize="lg" textTransform="capitalize" fontWeight="semibold">
          {t('payouts.connect_title')}
        </Text>
      </Box>
      <Text my={4} textStyle="secondary">
        {t('payouts.connect_description')}
      </Text>
      <Button
        as={'a'}
        href={process.env.REACT_APP_AUTHORIZE_STRIPE_URL}
        my={4}
        colorScheme="primary"
      >
        {t('payouts.connect_button')}
      </Button>
    </Box>
  );
};

export default ConnectAccount;
