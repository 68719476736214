import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Avatar, Box, Text, Icon, Button, SimpleGrid } from '@chakra-ui/react';
import { ReactComponent as LaurelIcon } from '@src/icons/laurel-wreath.svg';
import { formatMoney } from '@src/utils';
import Link from '@src/components/atoms/Link';

export type Lawyer = { __typename?: 'Lawyer' } & {
  user: { __typename?: 'User' } & {
    profile: { __typename?: 'UserProfile' } & ProfileFragment;
  };
} & LawyerFragment;

export interface LawyerCardProps {
  lawyer: Lawyer;
}

export const LawyerCard: React.FC<LawyerCardProps> = ({
  lawyer,
}: LawyerCardProps) => {
  const { t } = useTranslation();
  const { locale } = useParams<BaseRouteParams>();
  const history = useHistory();
  const { specialties, languages } = useMemo(() => {
    return {
      specialties: lawyer?.legalPractices
        ?.slice(0, 4)
        .map((area) => {
          return area ? t(`practice_areas.${area?.slug}`, area.name) : '';
        })
        .join(' | '),
      languages: lawyer?.spokenLanguages
        ?.slice(0, 4)
        .map((language) => {
          return language ? t(`languages.${language.code}`, language.name) : '';
        })
        .join(', '),
    };
  }, [lawyer, t]);
  return (
    <Box
      d="flex"
      flexDirection={{ base: 'column', lg: 'row' }}
      boxShadow="0px 0px 18px 2px rgba(0, 0, 0, 0.12);"
      borderRadius={8}
      p={{ base: 6, md: 8, lg: 10 }}
    >
      <SimpleGrid flex="1 1 0%" columns={{ base: 1, lg: 2 }}>
        <Box d="flex" alignItems="center" flexShrink={0}>
          <Link to={`/lawyers/profile/${lawyer.slug}`}>
            <Avatar
              size="xl"
              mr={4}
              name={`${lawyer.firstName} ${lawyer.lastName}`}
              src={lawyer.headshot as string}
            />
          </Link>
          <Box>
            <Link to={`/lawyers/profile/${lawyer.slug}`}>
              <Text
                d="flex"
                fontSize={{ base: 'lg', md: 'xl' }}
                fontWeight="300"
                _hover={{ color: 'gray.700' }}
                color="black"
              >
                {`${lawyer.firstName} ${lawyer.lastName}`}
              </Text>
            </Link>
            <Text
              fontSize={{ base: 'sm', md: 'md' }}
              color="gray.600"
              fontWeight="400"
              textTransform="capitalize"
            >
              {t(`fields.title_options.${lawyer.title}`)}
            </Text>
            <Link to={`/lawyers/profile/${lawyer.slug}`}>
              <Text> {t(`common.view_profile`)}</Text>
            </Link>
          </Box>
        </Box>
        <Box alignItems="center" d="flex" mt={{ base: 5, lg: 0 }}>
          <Box d="flex" flexDir="column">
            <Box d="flex">
              <Icon color="gray.400" as={LaurelIcon} mr={2} w={25} h={25} />
              <Text fontSize="sm" fontWeight="500" color="gray.600">
                {specialties}
              </Text>
            </Box>
            <Box d="flex" mt={15}>
              <Text fontSize="sm" color="black" fontWeight="300">
                {`${t('lawyer_card.languages')} ${languages}`}
              </Text>
            </Box>
          </Box>
        </Box>
      </SimpleGrid>
      <Box
        flexShrink={{ lg: 0 }}
        alignItems="center"
        p={{ base: 1, md: 3 }}
        d="flex"
        ml={{ lg: 6 }}
        mt={{ base: 3 }}
        justifyContent={{ base: 'center', lg: 'flex-end' }}
      >
        <Box
          d="flex"
          flexDir="column"
          alignItems={{ base: 'center', lg: 'flex-end' }}
        >
          <Box d="flex">
            <Text fontSize="sm" fontWeight="800" color="black">
              {formatMoney(lawyer.hourlyRate)} {t('common.per_hour')}
            </Text>
          </Box>
          <Box d="flex" mt={{ md: 4 }}>
            <Button
              onClick={() =>
                history.push(`/${locale}/booking/new/${lawyer.id}`)
              }
              mt={4}
              colorScheme="blue"
              fontWeight="medium"
              fontSize="sm"
            >
              {t('lawyer_card.schedule_meeting')}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LawyerCard;
