import { Callback } from '@src/@types/twilio';
import Video from 'twilio-video';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  connectMeeting,
  Meeting,
  ConnectMeetingOptions,
  LocalStream,
} from '@src/video';
import { isMobile } from '@src/utils';

// eslint-disable-next-line
// @ts-ignore
window.TwilioVideo = Video;

export default function useMeeting(
  localStreams: LocalStream[],
  onError: Callback,
  options?: ConnectMeetingOptions
) {
  const [meeting, setMeeting] = useState<Meeting | null>(null);
  const [isConnecting, setIsConnecting] = useState(false);
  const optionsRef = useRef(options);

  useEffect(() => {
    optionsRef.current = options;
  }, [options]);

  const connect = useCallback(
    (token) => {
      setIsConnecting(true);
      return connectMeeting(token, {
        ...optionsRef.current,
        tracks: localStreams,
      }).then(
        (newMeeting) => {
          setMeeting(newMeeting);
          const disconnect = () => newMeeting.disconnect();
          newMeeting.setMaxListeners(10);

          newMeeting.once('disconnected', () => {
            setTimeout(() => setMeeting(null));
            window.removeEventListener('beforeunload', disconnect);

            if (isMobile) {
              window.removeEventListener('pagehide', disconnect);
            }
          });
          // eslint-disable-next-line
          // @ts-ignore
          window.twilioRoom = newMeeting;

          newMeeting.localParticipant.videoTracks.forEach((publication) => {
            publication.setPriority('low');
          });

          setIsConnecting(false);
          window.addEventListener('beforeunload', disconnect);

          if (isMobile) {
            // Add a listener to disconnect from the room when a mobile user closes their browser
            window.addEventListener('pagehide', disconnect);
          }
        },
        (error) => {
          onError(error);
          setIsConnecting(false);
        }
      );
    },
    [onError, localStreams]
  );

  return { meeting, isConnecting, connect };
}
