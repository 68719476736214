/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-key */
import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { DefaultFilterForColumn } from './filter';

// import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import { useTable, useSortBy, useFilters } from 'react-table';

function SortableTable({ columns, data }: any) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    { columns, data, defaultColumn: { Filter: DefaultFilterForColumn } },
    useFilters,
    useSortBy
  );

  return (
    <Box w="100%" bg="white" rounded="md" p={2}>
      <Box
        as="table"
        {...getTableProps()}
        wordBreak="break-word"
        d="table"
        width={'100%'}
      >
        <Box as="thead" width="100%" background="gray.100">
          {headerGroups.map((headerGroup) => (
            <Box
              as="tr"
              display={{ base: 'none', md: 'table-row' }}
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column) => (
                <Box
                  as="th"
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  // isNumeric={column.isNumeric}
                  width={{ base: '100%', md: column.width || 'auto' }}
                  overflow="hidden"
                  paddingX={0}
                >
                  <Box d="flex" alignItems="center" p={2}>
                    {column.render('Header')}
                    <Box as="span" textAlign="left">
                      {column?.isSorted ? (
                        column?.isSortedDesc ? (
                          // <TriangleDownIcon aria-label="sorted descending" />
                          <Text fontSize="xs" ml={2}>
                            ^
                          </Text>
                        ) : (
                          <Text fontSize="xs" ml={2}>
                            v
                          </Text>
                        )
                      ) : null}
                    </Box>
                  </Box>
                  <Box>{column.canFilter ? column.render('Filter') : null}</Box>
                </Box>
              ))}
            </Box>
          ))}
        </Box>
        <Box as="tbody" {...getTableBodyProps()} width="100%" textAlign="left">
          {rows.map((row) => {
            prepareRow(row);
            return (
              <Box
                as="tr"
                {...row.getRowProps()}
                display={{ base: 'flex', md: 'table-row' }}
                flexDir="column"
                width="100%"
                fontSize="xs"
                textAlign="left"
                borderBottom={'thin lightgray solid'}
              >
                {row.cells.map((cell) => (
                  <Box
                    as="td"
                    {...cell.getCellProps()}
                    textAlign="left"
                    // width={{ base: '100%', md: cell.column.width || 'auto' }}
                    padding={1}
                    overflow="hidden"
                    wordBreak={'keep-all'}
                  >
                    <Box d="flex" overflow="hidden">
                      <Text
                        as="strong"
                        d={{ base: 'block', md: 'none' }}
                        marginRight={2}
                      >
                        {cell.column.render('Header')}
                      </Text>
                      {cell.render('Cell')}
                    </Box>
                  </Box>
                ))}
              </Box>
            );
          })}
          {rows.length === 0 && (
            <Box as="tr">
              <Box as="td" textAlign="center" padding={4}>
                No data
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default SortableTable;
