/* eslint-disable @typescript-eslint/no-explicit-any */
declare global {
  interface Window {
    dataLayer: any;
    gtag: any;
  }
}

const useAnalytics = () => {
  const trackNewAppointment = () => {
    window?.dataLayer?.push({
      event: 'conversion',
      category: 'Meetings',
      action: 'bookingCreated',
      label: 'Meeting with Lawyer',
    });
  };

  // window?.gtag('event', 'conversion', {
  //   send_to: 'AW-10857686268/0_IlCLztnaMDEPzJrLko',
  // });

  const reportConversion = () => {
    const callback = () => {
      console.log('track conversion');
    };
    window?.gtag('event', 'conversion', {
      send_to: 'AW-10867778923/G_ynCJLugqkDEOvKlL4o',
      event_callback: callback,
    });
    return false;
  };

  return { trackNewAppointment, reportConversion };
};

export default useAnalytics;
