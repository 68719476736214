import React, { useCallback, useMemo } from 'react';
import {
  Box,
  Text,
  Button,
  Stack,
  VStack,
  Checkbox,
  Tag,
} from '@chakra-ui/react';
import Link from '@atoms/Link';
import { useTranslation } from 'react-i18next';
import { FORM_ERROR } from 'final-form';
import { format, parse } from 'date-fns';
import {
  GetMyLawyerApplicationDocument,
  useGetMyLawyerApplicationQuery,
  useUpdateMyLawyerApplicationMutation,
  useSubmitMyLawyerApplicationForReviewMutation,
} from '@src/apollo/hooks';
import ApplicationExtraInformation from '@molecules/ApplicationForm/ApplicationExtraInformation';
import BasicInformation from '@molecules/ApplicationForm/BasicInformation';
import Loader from '@atoms/Loader';
import AlertDialogWrapper from '@atoms/AlertDialog';
import { dollarToCents, centsToDollar } from '@src/utils';
import { useToast } from '@src/hooks';
import { uniq } from 'lodash';

export interface LawyerApplicationFormReturnValues {
  bio: Maybe<string>;
  phone: string;
  headshot?: Maybe<string>;
  hourlyRate: number;
  legalPractices: Option[];
  spokenLanguages: Option[];
  workingHours: { from: Date; to: Date; days: string[] };
}

function LawyerApplicationStatus() {
  const { t } = useTranslation();
  const toast = useToast();
  const { data, loading } = useGetMyLawyerApplicationQuery();
  const [updateMyLawyerApplication] = useUpdateMyLawyerApplicationMutation({
    refetchQueries: [{ query: GetMyLawyerApplicationDocument }],
    awaitRefetchQueries: true,
  });
  const [
    submitMyLawyerApplicationForReview,
  ] = useSubmitMyLawyerApplicationForReviewMutation({
    refetchQueries: [{ query: GetMyLawyerApplicationDocument }],
    awaitRefetchQueries: true,
  });

  const applicationData = data?.getMyLawyerApplication;
  const phone = applicationData?.phone;

  const submitForReview = () => {
    if (data?.getMyLawyerApplication?.id) {
      submitMyLawyerApplicationForReview();
    }
    window.scrollTo(0, 0);
  };

  const updateApplicationExtras = useCallback(
    async (values: LawyerApplicationFormReturnValues) => {
      try {
        const practiceSlugs = values.legalPractices.map(
          (practice) => practice.value
        );
        const languageCodes = values.spokenLanguages.map(
          (language) => language.value
        );
        const parsedWorkingHours = uniq(values.workingHours.days).map((day) => {
          return {
            name: day,
            from: format(values.workingHours.from, 'HH:mm:ss'),
            to: format(values.workingHours.to, 'HH:mm:ss'),
          };
        });
        const updateValues = {
          bio: values.bio,
          phone: phone || '',
          headshot: values.headshot || '',
          hourlyRate: dollarToCents(values.hourlyRate),
          spokenLanguages: { codes: languageCodes },
          legalPractices: { slugs: practiceSlugs },
          workingHours: {
            hours: parsedWorkingHours,
          },
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        };
        const { data } = await updateMyLawyerApplication({
          variables: {
            input: updateValues,
          },
        });

        if (!data?.updateMyLawyerApplication?.successful) {
          data?.updateMyLawyerApplication?.messages?.forEach((error) => {
            toast.warning({
              title: t('common.error'),
              description: error?.message,
              isClosable: true,
            });
          });
          return;
        }
        toast.success({
          title: t('common.success'),
          description: t('profile.toast.updated_profile.title'),
          isClosable: true,
        });
        window.scrollTo(0, 0);
      } catch (err) {
        toast.warning({
          title: err.description,
          isClosable: true,
        });
        return { [FORM_ERROR]: err.description };
      }
    },
    [updateMyLawyerApplication, toast, t, phone]
  );

  const defaultValues = useMemo(() => {
    return {
      bio: applicationData?.bio,
      headshot: applicationData?.headshot,
      phone: applicationData?.phone || '',
      hourlyRate: centsToDollar(applicationData?.hourlyRate || 0),
      spokenLanguages: applicationData?.spokenLanguages?.codes,
      legalPractices: applicationData?.legalPractices?.slugs,
      workingHours: {
        from: parse(
          applicationData?.workingHours?.hours[0]?.from || '08:00:00',
          'HH:mm:ss',
          new Date()
        ),
        to: parse(
          applicationData?.workingHours?.hours[0]?.to || '15:00:00',
          'HH:mm:ss',
          new Date()
        ),
        days:
          applicationData?.workingHours?.hours.length > 0
            ? uniq(
                applicationData?.workingHours?.hours.map(
                  (workingOn: LawyerTimeSlotsInput) => workingOn.name
                )
              )
            : ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'],
      },
    };
  }, [applicationData]);

  const isStep2 = useMemo(() => {
    return applicationData?.status === 'draft';
  }, [applicationData]);

  if (loading) return <Loader />;
  const {
    bio,
    phone: extraPhone,
    hourlyRate,
    spokenLanguages,
    legalPractices,
    workingHours,
  } = defaultValues;

  const canSubmit =
    (bio || extraPhone) !== '' &&
    hourlyRate !== 0 &&
    (spokenLanguages.length && legalPractices.length) !== 0 &&
    workingHours.days;

  return (
    <>
      <Box
        w="full"
        zIndex={2}
        maxW="6xl"
        mx="auto"
        px={6}
        d="flex"
        flexDir={{ base: 'column', md: 'row' }}
        paddingBottom={{ base: 12, md: 1 }}
        justifyContent="space-between"
      >
        <Box padding={{ base: 4, md: 50 }}>
          <Text
            color="white"
            fontSize="lg"
            fontWeight="bold"
            textAlign="left"
            w="full"
            mt={{ base: 2, md: 16 }}
          >
            {isStep2
              ? t('lawyer_application.step2')
              : t('lawyer_application.step3')}
          </Text>
          <Text
            color="white"
            fontSize="2xl"
            fontWeight="200"
            textAlign="left"
            w="full"
            mb={{ base: 2, md: 10 }}
          >
            {isStep2
              ? t('lawyer_application.step2_description')
              : t('lawyer_application.step3_description')}
          </Text>
        </Box>
        <Box padding={{ base: 4, md: 50 }} alignItems="center">
          <Text color="white" textAlign="left" mt={{ base: 2, md: 16 }}>
            {t('lawyer_application.application_status')}
          </Text>
          <Tag
            size="lg"
            colorScheme={applicationData?.status === 'draft' ? 'blue' : 'green'}
            borderRadius="full"
          >
            {t(`lawyer_application.status.${applicationData?.status}`)}
          </Tag>
        </Box>
      </Box>

      <Box
        backgroundColor="blue.50"
        backgroundPosition="center"
        w="full"
        h="auto"
        minHeight="100vh"
        zIndex={2}
      >
        <Box maxW="6xl" mx="auto" px={{ base: 1, md: 6 }}>
          <Box
            position="relative"
            top="-50"
            d="flex"
            w="full"
            flexDirection="column"
            justifyContent="top"
            bgColor="white"
            padding={{ base: 2, md: 50 }}
            rounded={15}
            shadow={'0px 0px 40px rgba(0, 0, 0, 0.15)'}
          >
            {isStep2 && (
              <VStack spacing={4} alignItems="flex-start" w="100%" mx="auto">
                <Stack w="100%" bg="blue.100" rounded="lg" p={6}>
                  {applicationData && (
                    <BasicInformation applicationData={applicationData} />
                  )}
                </Stack>

                <Stack w="100%" rounded="lg" p={6} mt={2}>
                  <Text as="span" textStyle="strongBlue">
                    {t('lawyer_application.extra_information')}
                  </Text>
                  <ApplicationExtraInformation
                    canUpdate={applicationData?.status === 'draft'}
                    initialValues={defaultValues}
                    onSubmit={updateApplicationExtras}
                  />
                </Stack>

                <Text frontSize="xs" mt={0} px={4}>
                  {t('lawyer_application.submit_prompt')}
                </Text>

                {canSubmit && (
                  <Box d="flex" justifyContent="flex-end" w="100%" pr={4}>
                    <AlertDialogWrapper
                      buttonText={t('lawyer_application.submit_for_review')}
                      onSubmit={submitForReview}
                      submitText={t('common.continue')}
                      cancelText={t('common.cancel')}
                      dialogDescription={t(
                        'lawyer_application.submit_warning_description'
                      )}
                      dialogHeader={t(
                        'lawyer_application.submit_warning_header'
                      )}
                    />
                  </Box>
                )}
              </VStack>
            )}
            {!isStep2 && (
              <VStack spacing={4} alignItems="flex-start" w="100%" mx="auto">
                <Stack w="100%" rounded="lg" p={6}>
                  <Text as="span" textStyle="strongYellow">
                    {t('lawyer_application.application_feedback')}
                  </Text>

                  {applicationData?.feedback?.map((feedback, index) => {
                    return (
                      <Box key={index}>
                        <Checkbox
                          colorScheme={'yellow'}
                          isChecked={!!feedback?.completedAt}
                        >
                          <Text
                            whiteSpace="pre-wrap"
                            fontSize="sm"
                            fontWeight="600"
                          >
                            {t(
                              `lawyer_application.feedback.${feedback?.feedback}`
                            )}
                          </Text>
                        </Checkbox>
                      </Box>
                    );
                  })}
                </Stack>

                <Button
                  as={Link}
                  variant="solid"
                  colorScheme="blue"
                  alignSelf="flex-end"
                  to="/"
                >
                  {t('common.main_page')}
                </Button>
              </VStack>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default LawyerApplicationStatus;
