import React from 'react';
import { chakra, Text } from '@chakra-ui/react';
import { PayoutLineProps } from './PayoutLineItem';
import { formatMoney } from '@src/utils';
import DateFormat from '@src/components/atoms/DateFormat';

const PayoutLineMobile = ({ payout }: PayoutLineProps) => {
  return (
    <chakra.div rounded="xl" shadow="lg" bg="white" w="full" p={5}>
      <chakra.div>
        <chakra.div
          d="flex"
          justifyContent="space-between"
          mt={4}
          alignItems="center"
        >
          <Text fontWeight="light" fontSize="sm">
            {payout?.description}
          </Text>
        </chakra.div>
        <chakra.div d="flex" justifyContent="space-between" alignItems="center">
          <Text fontWeight="semibold" ml={1}>
            <DateFormat date={new Date(payout?.date)} />
          </Text>

          <Text fontWeight="semibold">{formatMoney(payout?.amount)}</Text>
        </chakra.div>
      </chakra.div>
    </chakra.div>
  );
};

export default PayoutLineMobile;
