/* eslint-disable react/display-name */
import React, { useState, useMemo, useCallback } from 'react';
import { Box, Avatar, Heading, Button, Select } from '@chakra-ui/react';
import DataTable from 'react-data-table-component';
import SidebarLayout from '@src/components/organisms/RooLayout/SidebarLayout';
import {
  useDeactivateLawyerMutation,
  useActivateLawyerMutation,
  useGetAllLawyersQuery,
  GetAllLawyersDocument,
  useGetAllMeetsCountriesQuery,
} from '@src/apollo/hooks';
import Link from '@components/atoms/Link';
import Loader from '@src/components/atoms/Loader';
import { SelectColumnFilter } from '@src/components/molecules/SortableTable/filter';

const pageSize = 100;

const LawyersPage = () => {
  const [selectedCountry, setCountry] = useState('CR');
  const { data, loading, fetchMore } = useGetAllLawyersQuery({
    variables: {
      first: pageSize,
      filters: { countryCode: selectedCountry },
    },
  });
  const [deactivateLawyer] = useDeactivateLawyerMutation();
  const [activateLaywer] = useActivateLawyerMutation();
  const { data: countryData } = useGetAllMeetsCountriesQuery();

  const activateALawyer = useCallback(
    (id: string) => {
      activateLaywer({
        variables: {
          lawyerId: id,
        },
        refetchQueries: [{ query: GetAllLawyersDocument }],
      });
    },
    [activateLaywer]
  );
  const deactivateALawyer = useCallback(
    (id: string) => {
      deactivateLawyer({
        variables: {
          lawyerId: id,
        },
        refetchQueries: [{ query: GetAllLawyersDocument }],
      });
    },
    [deactivateLawyer]
  );
  const columns = useMemo(() => {
    return [
      {
        name: 'Name',
        cell: (row: any) => (
          <>
            <Avatar
              size="sm"
              mr={4}
              name={`${row?.fullName}`}
              src={row?.headshot as string}
            />
            <Link fontSize="sm" to={`/lawyers/${row?.id}`}>
              {row?.fullName}
            </Link>
          </>
        ),
        selector: (row: any) => row?.meeting?.roomId,
        Filter: SelectColumnFilter,
        filter: 'includes',
      },
      {
        name: 'Country',
        selector: (row: any) => row?.location?.name,
        sortable: true,
      },
      {
        name: '',
        cell: (row: any) => (
          <Box w="10%">
            {row?.active ? (
              <Button
                onClick={() => deactivateALawyer(`${row?.id}`)}
                size="sm"
                colorScheme="red"
              >
                Deactivate
              </Button>
            ) : (
              <Button
                onClick={() => activateALawyer(`${row?.id}`)}
                size="sm"
                colorScheme="green"
              >
                {' '}
                Activate
              </Button>
            )}
          </Box>
        ),
        sortable: true,
      },
    ];
  }, [activateALawyer, deactivateALawyer]);
  if (loading) return <Loader />;

  const pageInfo = data?.getAllLawyers?.pageInfo;
  const displayLawyers = data?.getAllLawyers?.edges?.map((edge) => edge?.node);
  return (
    <SidebarLayout>
      <Box maxW="5xl" mx="auto" py={16} px={6}>
        <Box d="flex" justifyContent="space-between" m={4}>
          <Heading>Lawyers</Heading>

          <Select
            w="50%"
            name="countryId"
            onChange={(e) => setCountry(e.currentTarget.value)}
          >
            {countryData?.getAllMeetsCountries?.map((country) => {
              if (!country) return null;
              return (
                <Box as="option" key={country.id} value={country.code}>
                  {country.name}
                </Box>
              );
            })}
          </Select>
        </Box>

        <Box m={4} background="white" rounded="lg">
          <DataTable
            striped
            columns={columns}
            data={displayLawyers || []}
            pagination
            onChangePage={() => {
              if (pageInfo?.hasNextPage) {
                fetchMore({
                  variables: { after: pageInfo.endCursor, first: pageSize },
                });
              }
            }}
          />
        </Box>
      </Box>
    </SidebarLayout>
  );
};

export default LawyersPage;
