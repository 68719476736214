import React from 'react';
import {
  VisaIcon,
  MastercardIcon,
  CreditCardIcon,
} from '@molecules/CreditCard/icons';

export interface BrandIconProps {
  brand?: string;
  color?: string;
}
export default function CardBrand(props: BrandIconProps) {
  const { brand } = props;
  if (brand === 'visa') {
    return <VisaIcon {...props} viewBox="0 0 36 36" />;
  }
  if (brand === 'mastercard') {
    return <MastercardIcon {...props} viewBox="0 0 48 48" />;
  }
  return <CreditCardIcon color="white" {...props} viewBox="0 0 36 36" />;
}
