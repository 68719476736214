import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import DateFormat from '@src/components/atoms/DateFormat';
import PayoutAmount from '@src/components/atoms/PayoutAmount';
import { useTranslation } from 'react-i18next';

export interface AppointmentLineProps {
  title?: string;
  id?: number;
  host?: { __typename?: 'LawyerType' } & LawyerFragment;
  meeting?: { __typename?: 'AppointmentMeetingType' } & MeetingFragment;
  user?: { __typename?: 'User' } & Pick<User, 'email'> & {
      profile: { __typename?: 'UserProfile' } & ProfileFragment;
    };
  times?: { __typename?: 'AppointmentTimes' } & Pick<
    AppointmentTimes,
    'startsAt' | 'endsAt'
  >;
  purchaseInfo?: Array<
    Maybe<
      { __typename?: 'PurchaseType' } & Pick<
        PurchaseType,
        'description' | 'invoiceUrl' | 'paid'
      > & {
          details: { __typename?: 'PurchaseDetailsType' } & Pick<
            PurchaseDetailsType,
            'total'
          >;
        }
    >
  >;
}

const AppointmentLineItem = ({
  times,
  id,
  title,
  purchaseInfo,
  meeting,
  host,
}: AppointmentLineProps) => {
  const { t } = useTranslation();
  const payoutDate = meeting?.payoutDate
    ? meeting?.payoutDate.split('-')
    : null;
  return (
    <Box as="tr">
      <Box
        as="td"
        textAlign="left"
        px={3}
        py={4}
        whiteSpace="nowrap"
        fontSize="sm"
      >
        {id}
      </Box>

      <Box as="td" px={2} py={4}>
        <Box d="flex" alignItems="left">
          <Box>
            <Text fontSize="sm" color="gray.900">
              {title} (<DateFormat date={new Date(times?.startsAt)} />)
            </Text>
          </Box>
        </Box>
      </Box>

      <Box as="td" px={2} py={4} whiteSpace="nowrap">
        {payoutDate && (
          <span>{`${payoutDate[1]}/${payoutDate[2]}/${payoutDate[0]}`}</span>
        )}
        {!payoutDate && 'NA'}
      </Box>
      <Box as="td" px={2} py={4} whiteSpace="nowrap">
        {t(`meetings.status.${meeting?.status}`)}
      </Box>

      <Box as="td" px={2} py={4} whiteSpace="nowrap">
        <Box d="flex" alignItems="center" flexDirection="column">
          <Text fontSize="sm" fontWeight="medium">
            {purchaseInfo?.map((info) => {
              if (info) {
                return (
                  <PayoutAmount
                    amount={host?.hourlyRate || 0}
                    duration={meeting?.duration || 60}
                  />
                );
              } else {
                return t('meetings.no_payment_information');
              }
            })}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default AppointmentLineItem;
