import React, { useCallback, useMemo } from 'react';
import SidebarLayout from '@src/components/organisms/RooLayout/SidebarLayout';
import { Box, chakra, Heading } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Switch, useHistory, useRouteMatch } from 'react-router-dom';
import Route from '@src/PrivateRoute';
import { FORM_ERROR } from 'final-form';
import LawyerProfileForm, {
  LawyerProfileFormValues,
} from '@src/components/molecules/ProfileForm/LawyerProfileForm';
import {
  useGetMyLawyerProfileQuery,
  useUpdateLawyerProfileMutation,
  GetMyLawyerProfileDocument,
} from '@src/apollo/hooks';
import { dollarToCents, centsToDollar } from '@src/utils';
import { useToast } from '@src/hooks';
import { languages } from '@src/config/i18n';
import ViewLawyer from './ViewLawyer';
import Loader from '@src/components/atoms/Loader';
import UserProfile from './UserProfile';

const localePath = `/:locale(${languages.join('|')})`;

const LawyerProfile = ({ viewer }: { viewer: User }) => {
  const { t } = useTranslation();
  const toast = useToast();
  const history = useHistory();
  const { path, url } = useRouteMatch();
  const { data, loading } = useGetMyLawyerProfileQuery();
  const [updateProfile] = useUpdateLawyerProfileMutation({
    refetchQueries: [{ query: GetMyLawyerProfileDocument }],
    awaitRefetchQueries: true,
  });
  const editableMatch = useRouteMatch({
    path: `${localePath}/profile/edit`,
    exact: true,
  });

  const profile = data?.getMyLawyer;

  const onCancel = useCallback(() => {
    history.push(url);
  }, [url, history]);

  const onSubmit = useCallback(
    async (values: LawyerProfileFormValues) => {
      try {
        const input = {
          bio: values.bio,
          phone: values.phone,
          hourlyRate: dollarToCents(values.hourlyRate),
          title: values.title,
          legalPractices: values.legalPractices
            ?.filter(Boolean)
            .map((item) => item?.slug as string),
          spokenLanguages: values.spokenLanguages
            ?.filter(Boolean)
            .map((item) => item?.code as string),
          educationalBackground: values.educationalBackground
            .filter(Boolean)
            .map((item) => ({
              title: item?.title,
              institution: item?.institution,
              graduationYear: Number(item?.graduationYear),
            })),
          workExperience: values.workExperience.filter(Boolean).map((item) => ({
            companyName: item?.companyName,
            title: item?.title,
            startYear: Number(item?.startYear),
            ...(item?.endYear && {
              endYear: Number(item.endYear),
            }),
          })) as LawyerWorkExperienceInput[],
        };
        const { data } = await updateProfile({
          variables: {
            input,
          },
        });
        if (!data?.updateLawyer?.successful) {
          data?.updateLawyer?.messages?.forEach((error) => {
            toast.warning({
              title: t('common.error'),
              description: error?.message,
              isClosable: true,
            });
          });
          return;
        }
        toast.success({
          title: t('common.success'),
          description: t('profile.toast.updated_profile.title'),
          isClosable: true,
        });
        window.scrollTo(0, 0);
      } catch (err) {
        toast.warning({
          title: err.description,
          isClosable: true,
        });
        return { [FORM_ERROR]: err.description };
      }
    },
    [updateProfile, toast, t]
  );

  const formInitialValue = useMemo<Partial<LawyerProfileFormValues>>(
    () => ({
      firstName: profile?.firstName,
      lastName: profile?.lastName,
      bio: profile?.bio,
      phone: profile?.phone,
      hourlyRate: profile?.hourlyRate && centsToDollar(profile?.hourlyRate),
      title: profile?.title,
      legalPractices: profile?.legalPractices,
      countryId: profile?.location.id,
      spokenLanguages: profile?.spokenLanguages,
      educationalBackground:
        (profile?.educationalBackground ?? []).length > 0
          ? profile?.educationalBackground
          : [undefined],
      affiliations: [undefined],
      workExperience:
        (profile?.workExperience ?? []).length > 0
          ? profile?.workExperience
              .slice()
              .sort((a, b) => {
                if (a?.endYear && b?.endYear) {
                  return b.endYear - a.endYear;
                }
                if (a?.endYear) {
                  return 1;
                }
                if (b?.endYear) {
                  return -1;
                }
                return 0;
              })
              .map(
                (item) =>
                  ({ ...item, endYear: item?.endYear ?? 'current' } as Maybe<
                    LawyerWorkExperienceInput | undefined
                  >)
              )
          : [undefined],
      headshot: profile?.headshot,
    }),
    [profile]
  );
  if (loading) {
    return <Loader />;
  }

  if (!profile) {
    return <UserProfile viewer={viewer} />;
  }

  return (
    <SidebarLayout>
      <Box maxW="5xl" mx="auto" mt={4}>
        <Heading
          color="blue.100"
          px={3}
          fontSize={{ base: 'xl', sm: '4xl' }}
          fontWeight="bold"
        >
          {editableMatch ? t('common.edit_profile') : t('profile.my_profile')}
        </Heading>
      </Box>
      <chakra.div my={8}>
        <Switch>
          <Route exact path={path} prefixLocale={false}>
            <ViewLawyer lawyer={profile} viewer={viewer} />
          </Route>
          <Route path={`${path}/edit`} prefixLocale={false}>
            <LawyerProfileForm
              initialValues={formInitialValue}
              onSubmit={onSubmit}
              onCancel={onCancel}
            />
          </Route>
        </Switch>
      </chakra.div>
    </SidebarLayout>
  );
};

export default LawyerProfile;
