import React, { useRef } from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  Box,
  Button,
  DrawerCloseButton,
  Icon,
  Link as ChackraLink,
  useDisclosure,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Link from '@components/atoms/Link';
import LanguageSelect from '@src/components/atoms/LanguageSelect';
import SidebarNav from '@organisms/RooLayout/Sidebar/SidebarNav';
import { useSignupModal, useSigninModal } from '@src/context';
import { ReactComponent as Logo } from '@src/icons/white-logo.svg';
import { ReactComponent as ColorLogo } from '@src/icons/logo.svg';
import { MenuIcon } from './icons';
import UserMenu from './UserMenu';

export interface UserNavigationProps {
  theme: { [key: string]: string };
  viewer?: Maybe<Viewer>;
  showLogo: boolean;
}

const MobileMenu = ({ viewer, theme, showLogo }: UserNavigationProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();
  const signUpModal = useSignupModal();
  const signInModal = useSigninModal();
  const location = useLocation();
  const btnRef = useRef<HTMLButtonElement>(null);
  const isInLawyerLanding =
    location.pathname.split('/')[2] === 'lawyers' &&
    location.pathname.split('/').length === 3;

  return (
    <Box width="10%">
      <Box
        width="100%"
        display={{ base: 'flex', lg: 'none' }}
        justifyContent="space-between"
      >
        {showLogo && (
          <Button variant="unstyled" as="a" href="/">
            <Icon
              as={ColorLogo}
              w={{ base: 32, md: 40 }}
              h="auto"
              data-testid="app-logo"
            />
          </Button>
        )}

        <Button variant="unstyled" position="absolute" top="10px" right="50px">
          <LanguageSelect />
        </Button>
        <Button
          variant="unstyled"
          color={theme.buttonColor}
          ref={btnRef}
          onClick={onOpen}
          position="absolute"
          top="10px"
          right="10px"
        >
          <MenuIcon />
        </Button>
      </Box>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay>
          <DrawerContent>
            <DrawerHeader
              width="100%"
              background="blue.600"
              d="flex"
              justifyContent="space-between"
              alignItems="flex-start"
              paddingTop="30px"
              paddingBottom="80px"
              className="clip-image-bottom-left-extra"
            >
              <Icon as={Logo} w={{ base: 24, sm: 32, lg: 40 }} h="auto" />
              <DrawerCloseButton size="sm" color="white" />
            </DrawerHeader>

            <DrawerBody>
              <Box overflowY="scroll" background="red" h="75vh">
                {viewer && (
                  <>
                    <Box mt={10}>
                      <SidebarNav />
                    </Box>
                    <Box mt={10}>
                      <UserMenu viewer={viewer} />
                    </Box>
                  </>
                )}
                {!viewer && (
                  <Box
                    d="flex"
                    flexDir="column"
                    justifyContent="flex-end"
                    p={8}
                    textAlign="right"
                  >
                    <Link
                      to={isInLawyerLanding ? '/application' : '/lawyers'}
                      fontWeight="600"
                      my={2}
                    >
                      {t('common.join_as_lawyer')}
                    </Link>
                    <ChackraLink
                      as="a"
                      href={'https://meets.legaroo.com/blog'}
                      fontWeight="600"
                      my={2}
                    >
                      Blog
                    </ChackraLink>
                    <Link to="/contact" fontWeight="600" my={2}>
                      {t('common.contact_us')}
                    </Link>
                    <Box
                      d="flex"
                      flexDir={'column'}
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      mt={4}
                    >
                      <Button
                        my={2}
                        variant="unstyled"
                        p={0}
                        py={0}
                        onClick={signInModal.open}
                      >
                        {t('common.labels.sign_in')}
                      </Button>
                      <Button
                        variant="outline"
                        colorScheme="blue"
                        onClick={signUpModal.open}
                      >
                        {t('common.labels.sign_up')}
                      </Button>
                    </Box>
                  </Box>
                )}
              </Box>
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </Box>
  );
};

export default MobileMenu;
