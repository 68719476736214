export interface PracticeArea {
  id?: string;
  name: string;
  slug?: string;
}

enum Order {
  ASC = 'asc',
  DESC = 'desc',
}
export interface SortOption {
  by: string;
  name: string;
  order: Order;
}

export const areasOfPractice: PracticeArea[] = [
  {
    id: '1',
    name: 'Traveling About',
    slug: 'traveling-about',
  },
  {
    id: '2',
    name: 'Real Estate',
    slug: 'real-estate',
  },
  {
    id: '3',
    name: 'Doing Business',
    slug: 'doing-business',
  },
  {
    id: '4',
    name: 'Immigration',
    slug: 'immigration',
  },
  {
    id: '5',
    name: 'Family',
    slug: 'family',
  },
  {
    id: '6',
    name: 'Unlawful',
    slug: 'unlawful',
  },
  {
    id: '7',
    name: 'Culture & Travel Tips',
    slug: 'culture-travel-tips',
  },
  {
    id: '8',
    name: 'Human Rights',
    slug: 'human-rights',
  },
  {
    id: '9',
    name: 'Litigation and Disputes',
    slug: 'litigation-disputes',
  },
  {
    id: '10',
    name: 'Covid 19 Pandemic',
    slug: 'covid-19',
  },
];

export const lawyerSortOptions: SortOption[] = [
  {
    name: 'price_low_high',
    by: 'hourlyRate',
    order: Order.ASC,
  },
  {
    name: 'price_high_low',
    by: 'hourlyRate',
    order: Order.DESC,
  },
];
