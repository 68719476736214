import React, { useState, useEffect } from 'react';
import { Participant, Meeting } from '@src/video';

export interface ContextProviderProps {
  meeting: Meeting | null;
  children: React.ReactNode;
}

export interface ContextProps {
  selectedParticipant: Participant | null;
  setSelectedParticipant: (participant: Participant) => void;
}

const SelectedParticipantContext = React.createContext<ContextProps>({
  selectedParticipant: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSelectedParticipant: () => {},
});

function SelectedParticipantContextProvider({
  children,
  meeting,
}: ContextProviderProps) {
  const [
    activeParticipant,
    setActiveParticipant,
  ] = useState<Participant | null>(null);

  const setSelectedParticipant = (participant: Participant) => {
    setActiveParticipant(participant);
  };

  useEffect(() => {
    if (meeting) {
      const onDisconnect = () => setActiveParticipant(null);

      const handleParticipantDisconnected = (participant: Participant) =>
        setActiveParticipant((prevParticipant) =>
          prevParticipant === participant ? null : prevParticipant
        );

      meeting.on('disconnected', onDisconnect);
      meeting.on('participantDisconnected', handleParticipantDisconnected);
      return () => {
        meeting.off('disconnected', onDisconnect);
        meeting.off('participantDisconnected', handleParticipantDisconnected);
      };
    }
  }, [meeting, activeParticipant]);

  return (
    <SelectedParticipantContext.Provider
      value={{
        selectedParticipant: activeParticipant,
        setSelectedParticipant: setSelectedParticipant,
      }}
    >
      {children}
    </SelectedParticipantContext.Provider>
  );
}
export { SelectedParticipantContext, SelectedParticipantContextProvider };
