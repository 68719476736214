import React, { FC } from 'react';
import { Box, VStack, Icon } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as HomeIcon } from '@src/icons/home.svg';
import { ReactComponent as PaymentIcon } from '@src/icons/payment.svg';
import { ReactComponent as MeetingIcon } from '@src/icons/meeting.svg';
import { ReactComponent as ApplicationIcon } from '@src/icons/application-icon.svg';
import { ReactComponent as AvailabilityIcon } from '@src/icons/lawyer_availability.svg';
import { ReactComponent as PayoutIcon } from '@src/icons/bank.svg';
import { ReactComponent as AdminIcon } from '@src/icons/gear-icon.svg';
import { ReactComponent as UserIcon } from '@src/icons/profile-user.svg';
import { useLawyerApplicationStatus } from '@src/hooks/useLawyerApplicationStatus';
import SidebarLink from './SidebarLink';
import { Can } from '@src/context';

const SidebarNav: FC = () => {
  const { t } = useTranslation();
  const { applicationStatus } = useLawyerApplicationStatus();

  return (
    <Box as="nav" flex="1 1 0%" bg="white">
      <VStack align="left" spacing={3}>
        <SidebarLink to="/" exact>
          <Icon as={HomeIcon} h={6} w={6} mr={3} />
          {t('common.home')}
        </SidebarLink>
        <SidebarLink to="/my-meetings">
          <Icon as={MeetingIcon} h={6} w={6} mr={3} />
          {t('common.my_meetings')}
        </SidebarLink>
        <SidebarLink to="/my-payments">
          <Icon as={PaymentIcon} h={6} w={6} mr={3} />
          {t('common.my_purchases')}
        </SidebarLink>

        <Can I="create" a="time_slots">
          <SidebarLink to="/profile">
            <Icon as={UserIcon} h={6} w={6} mr={3} />
            {t('common.lawyer_profile')}
          </SidebarLink>
          <SidebarLink to="/availability">
            <Icon as={AvailabilityIcon} h={6} w={6} mr={3} />
            {t('availability.lawyer_availability')}
          </SidebarLink>
          <SidebarLink to="/payouts">
            <Icon as={PayoutIcon} h={6} w={6} mr={3} />
            {t('availability.lawyer_payouts')}
          </SidebarLink>
        </Can>

        <Can I="approve" a="application">
          <SidebarLink to="/admin">
            <Icon as={AdminIcon} h={6} w={6} mr={3} />
            {t('common.admin')}
          </SidebarLink>
          <Box pl={12}>
            <SidebarLink to="/admin/applications">
              {t('common.application_admin')}
            </SidebarLink>
            <SidebarLink to="/admin/lawyers">
              {t('common.lawyer_admin')}
            </SidebarLink>
            <SidebarLink to="/admin/appointments">
              {t('common.appointment_admin')}
            </SidebarLink>
          </Box>
        </Can>

        {applicationStatus === 'inProgress' && (
          <SidebarLink to="/application">
            <Icon as={ApplicationIcon} h={6} w={6} mr={3} />
            {t('common.my_application')}
          </SidebarLink>
        )}
      </VStack>
    </Box>
  );
};

export default SidebarNav;
