/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { Box, Text, Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import SortableTable from '@molecules/SortableTable';
import DateFormat from '@src/components/atoms/DateFormat';
import Link from '@components/atoms/Link';

export interface ApplicationsTableProps {
  applications: GetAllLawyerApplicationsQuery['getAllLawyerApplications'];
}

const ApplicationTable = ({ applications }: ApplicationsTableProps) => {
  const { t } = useTranslation();
  if (!applications) return <>no data</>;
  console.log(applications);
  const columns = [
    {
      Header: 'Created',
      accessor: 'insertedAt',
      Cell: ({ value }: any) => {
        return <DateFormat format="dd/MM/yyyy" date={new Date(value)} />;
      },
      disableFilters: true,
    },
    {
      Header: 'Name',
      accessor: 'firstName',
      Cell: ({ value }: any) => {
        return <Box>{value}</Box>;
      },
    },
    {
      Header: 'Last Name',
      accessor: 'lastName',
      Cell: ({ value }: any) => {
        return <Box>{value}</Box>;
      },
    },

    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'Country',
      accessor: 'location.name',
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ value }: any) => {
        return (
          <Box>
            {' '}
            <Text
              textAlign="left"
              color={value === 'approved' ? 'green.500' : 'yellow.500'}
            >
              {t(`lawyer_application.status.${value}`)}
            </Text>
          </Box>
        );
      },
    },
    {
      Header: 'Actions',
      accessor: 'id',
      disableFilters: true,
      Cell: ({ value }: any) => {
        return (
          <Box d="flex" alignItems="left" flexDirection="column">
            <Text fontSize="sm" fontWeight="medium" textAlign="left">
              <Button
                size="sm"
                variant="solid"
                colorScheme="blue"
                as={Link}
                to={`/admin/application/${value}`}
              >
                View
              </Button>
            </Text>
          </Box>
        );
      },
    },
  ];
  return <SortableTable columns={columns} data={applications || []} />;
};

export default ApplicationTable;
