import { useGetMyConnectedAccountQuery } from '@src/apollo/hooks';
import { isLawyer } from '@src/utils';

const useLawyerConnectedAccount = (user: any) => {
  const { data, loading } = useGetMyConnectedAccountQuery({
    skip: !isLawyer(user),
  });

  const connectedAccount = () => {
    const account = data?.getMyConnectedAccount;
    return account;
  };

  return {
    connectedAccount: connectedAccount(),
    hasConnectedAccount: !!connectedAccount(),
    loading: loading,
  };
};

export default useLawyerConnectedAccount;
