import { extendTheme } from '@chakra-ui/react';
import foundations from './foundations';
import components from './components';
import styles from './styles';

const theme = extendTheme({
  ...foundations,
  components,
  styles,
  textStyles: {
    secondary: {
      color: 'gray.600',
      fontSize: 'sm',
    },
    strongBlue: {
      color: 'blue.600',
      fontWeight: 800,
    },
    strongYellow: {
      color: 'orange.500',
      fontWeight: 800,
    },
    strongGreen: {
      color: 'green.500',
      fontWeight: 800,
    },
  },
});

export default theme;
