import React from 'react';
import {
  AreYouLawyer,
  FeaturesSection,
  HeroSection,
  HowItWorkSection,
  RLMSection,
  WhyWeDoSection,
} from '@components/molecules/LandingSection';
import Layout from '@src/components/organisms/RooLayout/Layout';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
const Landing: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Layout theme="transparent">
      <Helmet>
        <link rel="canonical" href={t('seo.canonical.landing')} />
      </Helmet>
      <div className="landing-page ">
        <HeroSection />

        <RLMSection />

        <WhyWeDoSection />

        <HowItWorkSection />

        <AreYouLawyer />

        <FeaturesSection />
      </div>
    </Layout>
  );
};

export default Landing;
