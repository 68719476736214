import React, { useCallback, useContext } from 'react';

import {
  Meeting,
  CreateStreamOptions,
  LocalStream,
  LocalAudioStream,
  LocalVideoStream,
  ConnectMeetingOptions,
} from '@src/video';

import useLocalStreams from '@src/hooks/meeting/useLocalStreams';
import useMeeting from '@src/hooks/meeting/useMeeting';
import { ErrorCallback } from '@src/@types/twilio';
import { SelectedParticipantContextProvider } from '@src/context';
import useHandleStreamPublicationFailed from '@src/hooks/meeting/useHandleStreamPublicationFailed';
import useScreenShareToggle from '@src/hooks/meeting/useScreenShareToggle';
import useHandleRoomDisconnection from '@src/hooks/meeting/useHandleRoomDisconnection';
import useRestartAudioTrackOnDeviceChange from '@src/hooks/meeting/useRestartAudioTrackOnDeviceChange';
import AttachVisibilityHandler from '@src/components/molecules/MeetingRoom/AttachVisibilityHandler';

export interface GetTokenResponse {
  meeting_token: string;
}

export interface VideoContextProps {
  children: React.ReactNode;
  options?: ConnectMeetingOptions;
  onError: ErrorCallback;
}

export interface VideoContextType {
  meeting: Meeting | null;
  localStreams: LocalStream[];
  isConnecting: boolean;
  connect: (token: string) => Promise<void>;
  onError: ErrorCallback;
  getLocalVideoStream: (
    newOptions?: CreateStreamOptions
  ) => Promise<LocalStream>;
  getLocalAudioStream: (deviceId?: string) => Promise<LocalStream>;
  isAcquiringLocalStreams: boolean;
  removeLocalVideoStream: () => void;
  getAudioAndVideoStreams: () => Promise<void>;
  isSharingScreen: boolean;
  toggleScreenShare: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const VideoContext = React.createContext<VideoContextType>(null!);

export default function VideoContextProvider({
  children,
  // eslint-disable-next-line
  onError = () => {},
  options,
}: VideoContextProps) {
  const onErrorCallback: ErrorCallback = useCallback(
    (error) => {
      console.log(`ERROR: ${error.message}`, error);
      onError(error);
    },
    [onError]
  );

  const {
    localStreams,
    getLocalVideoStream,
    getLocalAudioStream,
    isAcquiringLocalStreams,
    removeLocalVideoStream,
    removeLocalAudioStream,
    getAudioAndVideoStreams,
  } = useLocalStreams();

  const { meeting, isConnecting, connect } = useMeeting(
    localStreams,
    onErrorCallback,
    options
  );

  const [isSharingScreen, toggleScreenShare] = useScreenShareToggle(
    meeting,
    onError
  );

  // Register callback functions to be called on room disconnect.
  useHandleRoomDisconnection(
    meeting,
    onError,
    removeLocalAudioStream,
    removeLocalVideoStream,
    isSharingScreen,
    toggleScreenShare
  );
  useHandleStreamPublicationFailed(meeting, onError);
  useRestartAudioTrackOnDeviceChange(
    localStreams as (LocalAudioStream | LocalVideoStream)[]
  );

  return (
    <VideoContext.Provider
      value={{
        meeting,
        localStreams,
        isConnecting,
        onError: onErrorCallback,
        getLocalVideoStream,
        getLocalAudioStream,
        connect,
        isAcquiringLocalStreams,
        removeLocalVideoStream,
        isSharingScreen,
        toggleScreenShare,
        getAudioAndVideoStreams,
      }}
    >
      <SelectedParticipantContextProvider meeting={meeting}>
        {children}
      </SelectedParticipantContextProvider>
      {/* 
        The AttachVisibilityHandler component is using the useLocalVideoToggle hook
        which must be used within the VideoContext Provider.
      */}
      <AttachVisibilityHandler />
    </VideoContext.Provider>
  );
}
export function useVideoContext() {
  const context = useContext(VideoContext);
  if (!context) {
    throw new Error(
      'useVideoContext must be used within the VideoStateProvider'
    );
  }
  return context;
}
