import { useEffect, useState } from 'react';
import { useVideoContext } from '../../context/VideoContext';
import { RemoteParticipant } from '@src/video';

export default function useDominantSpeaker() {
  const { meeting } = useVideoContext();
  const [dominantSpeaker, setDominantSpeaker] = useState(
    meeting?.dominantSpeaker ?? null
  );

  useEffect(() => {
    if (meeting) {
      // Sometimes, the 'dominantSpeakerChanged' event can emit 'null', which means that
      // there is no dominant speaker. If we change the main participant when 'null' is
      // emitted, the effect can be jarring to the user. Here we ignore any 'null' values
      // and continue to display the previous dominant speaker as the main participant.
      const handleDominantSpeakerChanged = (
        newDominantSpeaker: RemoteParticipant
      ) => {
        if (newDominantSpeaker !== null) {
          setDominantSpeaker(newDominantSpeaker);
        }
      };

      // Since 'null' values are ignored, we will need to listen for the 'participantDisconnected'
      // event, so we can set the dominantSpeaker to 'null' when they disconnect.
      const handleParticipantDisconnected = (
        participant: RemoteParticipant
      ) => {
        setDominantSpeaker((prevDominantSpeaker) => {
          return prevDominantSpeaker === participant
            ? null
            : prevDominantSpeaker;
        });
      };

      meeting.on('dominantSpeakerChanged', handleDominantSpeakerChanged);
      meeting.on('participantDisconnected', handleParticipantDisconnected);
      return () => {
        meeting.off('dominantSpeakerChanged', handleDominantSpeakerChanged);
        meeting.off('participantDisconnected', handleParticipantDisconnected);
      };
    }
  }, [meeting]);

  return dominantSpeaker;
}
