import React, { useCallback } from 'react';
import { Button, Icon, Text, Tooltip } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ShareDisplayIcon } from '@src/icons/share-display.svg';
import useScreenShareParticipant from '@src/hooks/meeting/useScreenShareParticipant';
import { useVideoContext } from '@src/context/VideoContext';

// export const SHARE_IN_PROGRESS_TEXT =
//   'Cannot share screen when another user is sharing';
// export const SHARE_NOT_SUPPORTED_TEXT =
//   'Screen sharing is not supported with this browser';

export type ToggleScreenShareButtonProps = { isDisabled: boolean };

export default function ToggleScreenShareButton(
  props: ToggleScreenShareButtonProps
) {
  const { t } = useTranslation();
  const screenShareParticipant = useScreenShareParticipant();
  const { toggleScreenShare } = useVideoContext();
  const disableScreenShareButton = Boolean(screenShareParticipant);
  const isScreenShareSupported =
    navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia;

  const isDisabled =
    props.isDisabled || disableScreenShareButton || !isScreenShareSupported;

  let tooltipMessage = '';

  if (disableScreenShareButton) {
    // tooltipMessage = SHARE_IN_PROGRESS_TEXT;
    tooltipMessage = t('meetings.share_in_progress_text');
  }

  if (!isScreenShareSupported) {
    // tooltipMessage = SHARE_NOT_SUPPORTED_TEXT;
    tooltipMessage = t('meetings.share_not_supported_text');
  }

  const onToggleScreenShare = useCallback(() => {
    if (isDisabled) {
      return;
    }
    toggleScreenShare();
  }, [toggleScreenShare, isDisabled]);

  return (
    <Tooltip label={tooltipMessage} placement="top">
      <Button
        colorScheme="white"
        size="lg"
        color="dove-gray.500"
        display="flex"
        flexDirection="column"
        alignItems="center"
        // isDisabled={isDisabled}
        width={12}
        onClick={onToggleScreenShare}
      >
        <Icon
          as={ShareDisplayIcon}
          w={{ base: 5, sm: 6 }}
          h={{ base: 5, sm: 6 }}
          d="block"
        />
        <Text fontSize="xs" pt={1}>
          {t('common.share')}
        </Text>
      </Button>
    </Tooltip>
  );
}
