import React, { useMemo } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Stack,
  VStack,
  Button,
  HStack,
  chakra,
  Text,
  useBreakpointValue,
  InputGroup,
  InputRightElement,
  FormLabel,
  InputLeftElement,
} from '@chakra-ui/react';
import arrayMutators from 'final-form-arrays';
import {
  TextField,
  Select,
  Control,
  AdaptedInput,
  TextareaField,
  SelectMulti as SelectMultipleField,
  Error,
  Phone,
} from '@src/components/atoms/Fields';
import {
  useGetAllActiveLegalPracticesQuery,
  useGetAllLanguagesQuery,
  useGetAllMeetsCountriesQuery,
} from '@src/apollo/hooks';
// import AffiliationsSection from './AffiliationsSection';
import EducationSection from './EducationSection';
import LaboralExperienceSection from './LaboralExperienceSection';
import titleOptions from '@src/content/titleOptions';
import AvatarUpload from './AvatarUpload';
import { useFormValidation } from '@src/hooks';
import sortBy from 'lodash/fp/sortBy';

export type TitleType = {
  title: string;
  institution: string;
  year: number;
};

export type AffiliationType = {
  affiliation: string;
  no: string;
  year: number;
};

export interface LawyerProfileFormValues {
  firstName: string;
  lastName: string;
  bio: Maybe<string>;
  phone: Maybe<string>;
  countryId: string;
  title: string;
  hourlyRate: number;
  legalPractices: Maybe<
    Array<Maybe<Pick<LegalPracticeType, 'description' | 'name' | 'slug'>>>
  >;
  issues: string[];
  educationalBackground: Array<
    Maybe<LawyerEducationalBackgroundInput> | undefined
  >;
  affiliations: Array<Maybe<AffiliationType> | undefined>;
  workExperience: Array<Maybe<LawyerWorkExperienceInput | undefined>>;
  spokenLanguages: Maybe<Array<Maybe<LanguageFragment>>>;
  headshot: Maybe<string>;
}
export interface LawyerProfileFormProps {
  onSubmit: (v: LawyerProfileFormValues) => void;
  initialValues?: Partial<LawyerProfileFormValues>;
  onCancel(): void;
}

const LawyerProfileForm = ({
  onSubmit,
  initialValues,
  onCancel,
}: LawyerProfileFormProps) => {
  const { data } = useGetAllMeetsCountriesQuery();
  const { data: langData } = useGetAllLanguagesQuery();
  const { data: legalPracticesData } = useGetAllActiveLegalPracticesQuery();
  const { t } = useTranslation();
  const { required, validHourlyRate } = useFormValidation();
  const avatarSize = useBreakpointValue({ base: 'xl', sm: '3xl' });
  const allLang = langData?.getAllLanguages ?? [];
  const allLegalPractices =
    legalPracticesData?.getAllActiveLegalPractices ?? [];

  const defaultValues = useMemo(() => {
    return {
      ...initialValues,
      legalPractices: allLegalPractices.filter(
        (item) =>
          item &&
          initialValues?.legalPractices
            ?.map((item) => item?.slug)
            .includes(item.slug)
      ),
      spokenLanguages: allLang.filter(
        (item) =>
          item &&
          initialValues?.spokenLanguages
            ?.map((item) => item?.code)
            ?.includes(item.code)
      ),
    };
  }, [initialValues, allLegalPractices, allLang]);
  const sortedLegalPractices = sortBy(
    (practice) => practice.name,
    allLegalPractices
  );
  return (
    <Form<LawyerProfileFormValues>
      onSubmit={onSubmit}
      mutators={{
        ...arrayMutators,
      }}
      initialValues={defaultValues}
      render={({
        handleSubmit,
        submitting,
        pristine,
        form: {
          mutators: { push },
        },
      }) => (
        <form onSubmit={handleSubmit}>
          <chakra.div pos="relative" w="full" my={6} maxW="3xl" mx="auto">
            <chakra.div
              d="flex"
              flexDir={{ base: 'column', sm: 'row' }}
              alignItems="center"
              w="full"
              px={{ base: 3, sm: 6 }}
              py={{ base: 3, sm: 6, md: 12 }}
            >
              <AvatarUpload
                size={avatarSize}
                name={`${initialValues?.firstName} ${initialValues?.lastName}`}
                src={initialValues?.headshot as string}
              />
              <VStack spacing={3} w="full" mt={4}>
                <Stack direction={['column', 'row']} w="full" spacing="3">
                  <TextField
                    isDisabled
                    fieldProps={{ validate: required() }}
                    name="firstName"
                    label={t('fields.firstName.label')}
                    placeholder={t('fields.firstName.placeholder')}
                  />
                  <TextField
                    isDisabled
                    fieldProps={{ validate: required() }}
                    name="lastName"
                    label={t('fields.lastName.label')}
                    placeholder={t('fields.lastName.placeholder')}
                  />
                </Stack>
                <Stack direction={['column', 'row']} w="full" spacing="3">
                  <Select
                    name="title"
                    label={t('fields.title.label')}
                    placeholder={t('fields.title.placeholder')}
                    fieldProps={{ validate: required() }}
                  >
                    {titleOptions.titleOptions.map((option: string) => {
                      return (
                        <Box key={option} as="option" value={option}>
                          {t(`fields.title_options.${option}`)}
                        </Box>
                      );
                    })}
                  </Select>

                  <Select
                    isDisabled
                    name="countryId"
                    label={t('fields.country.label')}
                    placeholder={t('fields.country.placeholder')}
                    fieldProps={{
                      parse: (value) => value && Number(value),
                    }}
                  >
                    <Box as="option" disabled hidden d="none" value=""></Box>
                    {data?.getAllMeetsCountries?.map((country) => {
                      if (!country) return null;
                      return (
                        <Box as="option" key={country.id} value={country.id}>
                          {country.name}
                        </Box>
                      );
                    })}
                  </Select>
                </Stack>
                <Stack direction={['column', 'row']} w="full" spacing="3">
                  <Field
                    component={Phone}
                    name="phone"
                    label={t('fields.phone.label')}
                    smartCaret={false}
                    placeholder={t('fields.phone.placeholder')}
                  />
                </Stack>
              </VStack>
            </chakra.div>
          </chakra.div>

          <chakra.div pos="relative" w="full">
            <chakra.div
              aria-hidden="true"
              pos="absolute"
              insetX={0}
              top={0}
              h={48}
              bg="linear-gradient(180deg, #D2E3FB 0%, rgba(255, 255, 255, 0) 100%)"
            />

            <chakra.div py={12}>
              <VStack
                pos="relative"
                maxW="3xl"
                mx="auto"
                px={{ base: 3, sm: 6 }}
                spacing={3}
              >
                <TextareaField
                  name="bio"
                  label={t('fields.biography.label')}
                  rows={5}
                  maxLength={750}
                />

                <Control name="hourlyRate">
                  <FormLabel htmlFor="hourlyRate">
                    {t('fields.rate.label')}
                  </FormLabel>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      color="gray.400"
                      fontSize="1.2em"
                      bottom={-6}
                      pl={{ base: 4, sm: 6 }}
                      d="flex"
                      alignItems="center"
                    >
                      <Box as="span" fontSize="sm">
                        USD$
                      </Box>
                    </InputLeftElement>
                    <Field
                      name="hourlyRate"
                      component={AdaptedInput}
                      variant="roo"
                      px={undefined}
                      pl={{ base: 14, sm: 16 }}
                      pr={24}
                      type="number"
                      min={'25'}
                      placeholder={t('fields.rate.placeholder')}
                      validate={validHourlyRate()}
                    />
                    <InputRightElement width="6.5rem" bottom={-6} h="auto">
                      <chakra.span
                        bg="primary"
                        color="white"
                        rounded="md"
                        fontSize="xs"
                        fontWeight="bold"
                        w="full"
                        textAlign="center"
                      >
                        per 1 hour
                      </chakra.span>
                    </InputRightElement>
                  </InputGroup>
                  <Error name="hourlyRate" />
                </Control>
                <Box
                  width="100%"
                  d="flex"
                  justifyContent="center"
                  flexDir="column"
                  fontSize={'xs'}
                  px={4}
                >
                  <Text mb={2} as="strong">
                    {t('lawyer_application.application_fee_description5')}
                  </Text>
                  <Box ml={10}>
                    <ul>
                      <li>
                        <Text mb={2}>
                          {t('lawyer_application.application_fee_description6')}
                        </Text>
                      </li>
                      <li>
                        <Text mb={2}>
                          {t('lawyer_application.application_fee_description7')}
                        </Text>
                      </li>
                    </ul>
                  </Box>
                </Box>
                <SelectMultipleField
                  isSearchable
                  name="legalPractices"
                  options={sortedLegalPractices}
                  label={t('fields.legal_practice.label')}
                  placeholder={t('fields.legal_practice.placeholder')}
                  getOptionKey={(item) => (item ? item.slug : '')}
                  getOptionLabel={(item) =>
                    item ? t(`practice_areas.${item.slug}`, item.name) : ''
                  }
                />

                <SelectMultipleField
                  name="spokenLanguages"
                  isSearchable
                  options={allLang}
                  label={t('fields.language.label')}
                  placeholder={t('fields.language.placeholder')}
                  getOptionKey={(item) => (item ? item.code : '')}
                  getOptionLabel={(item) =>
                    item ? t(`languages.${item.code}`, item.name) : ''
                  }
                />
              </VStack>
            </chakra.div>
          </chakra.div>

          <chakra.div pos="relative" w="full" overflow="hidden">
            <chakra.div
              aria-hidden="true"
              pos="absolute"
              insetX={0}
              top={0}
              h={48}
              bg="linear-gradient(180deg, #D2E3FB 0%, rgba(255, 255, 255, 0) 100%)"
            />

            <EducationSection
              onAdd={() => push('educationalBackground', undefined)}
            />
          </chakra.div>

          {/* <chakra.div pos="relative" w="full" overflow="hidden">
            <chakra.div
              aria-hidden="true"
              pos="absolute"
              insetX={0}
              top={0}
              h={48}
              bg="linear-gradient(180deg, #D2E3FB 0%, rgba(255, 255, 255, 0) 100%)"
            />
            <AffiliationsSection
              onAdd={() => push('affiliations', undefined)}
            />
          </chakra.div> */}

          <chakra.div pos="relative" w="full" overflow="hidden">
            <chakra.div
              aria-hidden="true"
              pos="absolute"
              insetX={0}
              top={0}
              h={48}
              bg="linear-gradient(180deg, #D2E3FB 0%, rgba(255, 255, 255, 0) 100%)"
            />

            <LaboralExperienceSection
              onAdd={() => push('workExperience', undefined)}
            />
          </chakra.div>

          <HStack
            pt={3}
            pb={8}
            spacing={4}
            alignItems="center"
            justifyContent="center"
          >
            <Button onClick={onCancel} isDisabled={submitting} px={10}>
              {t('common.cancel')}
            </Button>
            <Button
              colorScheme="primary"
              disabled={pristine}
              isLoading={submitting}
              type="submit"
              px={10}
            >
              {t('common.save')}
            </Button>
          </HStack>
        </form>
      )}
    />
  );
};

export default LawyerProfileForm;
