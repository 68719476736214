import React from 'react';
import { Box, Heading } from '@chakra-ui/react';
import SidebarLayout from '@src/components/organisms/RooLayout/SidebarLayout';
import { useGetAllLawyerApplicationsQuery } from '@src/apollo/hooks';
import ApplicationsTable from '@src/components/molecules/Admin/ApplicationTable/applicationTable';
import Loader from '@src/components/atoms/Loader';

const ApplicationsPage = () => {
  const { data, loading } = useGetAllLawyerApplicationsQuery();
  if (loading) return <Loader />;
  return (
    <SidebarLayout>
      <Box maxW="6xl" mx="auto" py={16} px={6}>
        <Heading py={4} px={6}>
          Applications
        </Heading>

        {data?.getAllLawyerApplications && (
          <ApplicationsTable
            applications={data?.getAllLawyerApplications || []}
          />
        )}
      </Box>
    </SidebarLayout>
  );
};

export default ApplicationsPage;
