import React from 'react';
import { Box, Text, Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export interface LawyerApplicationResultProps {
  application: ApplicationCreationPayload;
  firstName: string;
}
const ApplicationConfirmation = ({
  application,
  firstName,
}: LawyerApplicationResultProps) => {
  const { t } = useTranslation();

  return (
    <Box maxW={'3xl'} margin="auto">
      <Box spacing={4} alignItems="flex-start" w="100%" my={8} mx="auto">
        <Box
          width={{ base: '372px', md: '680px', lg: '780px' }}
          height={{ base: '466px', md: '250px', lg: '298px' }}
          backgroundImage={{
            base: 'url(/images/confirm-email-mobile.png)',
            md: 'url(/images/confirm-email.png)',
          }}
          backgroundPosition="center"
          backgroundSize="89%"
          backgroundRepeat="no-repeat"
          display="flex"
          margin="auto"
        >
          <Box
            d="flex"
            justifyContent={'center'}
            alignItems="flex-end"
            w="100%"
            flexDir={{ base: 'row', md: 'column' }}
          >
            <Box
              w={{ base: '65%', md: '40%' }}
              mr={{ base: '0px', md: '14%', xl: '14%' }}
              mb={{ base: '12%', md: '0%' }}
            >
              <Text fontSize="lg" fontWeight="bold" mb={{ base: 1, md: 2 }}>
                {t('lawyer_application.congratulations')} {firstName}!
              </Text>
              <Text fontSize="sm" mb={{ base: 1, md: 2 }}>
                {t('lawyer_application.application_created')}
              </Text>
              {!application?.result?.confirmationRequired ? (
                <Button
                  as={'a'}
                  variant="solid"
                  colorScheme="blue"
                  alignSelf="flex-end"
                  href="/application"
                >
                  {t('common.labels.sign_in')}
                </Button>
              ) : (
                <Text fontWeight="bold" color={'yellow.500'}>
                  {t('lawyer_application.please_validate_account')}
                </Text>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ApplicationConfirmation;
