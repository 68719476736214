import React, { useState, useMemo } from 'react';
import { Box, Progress, Text, useInterval } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import { differenceInMilliseconds } from 'date-fns';
import { formatTimeDelta, redirectRatingOrHome } from '@src/utils';
import { useTranslation } from 'react-i18next';
import { useCountdown } from '@src/hooks';
import { useVideoContext } from '@src/context/VideoContext';
import { useViewerQuery } from '@src/apollo/hooks';
export interface ProgressBarProps {
  startTime: Date;
  endTime: Date;
}

const ProgressBar = ({ startTime, endTime }: ProgressBarProps) => {
  const { t } = useTranslation();
  const { locale, meeting_id } = useParams<RoomParamTypes & BaseRouteParams>();
  const { meeting } = useVideoContext();
  const { data } = useViewerQuery();
  const viewer = data?.viewer;

  const timeDelta = useCountdown({
    date: endTime,
    onComplete: () => {
      meeting!.disconnect();
      setTimeout(() => {
        redirectRatingOrHome({
          viewer: viewer,
          homePath: `/${locale}`,
          ratingPath: `/${locale}/meeting/${meeting_id}/done`,
        });
      }, 100);
    },
  });

  const diff = useMemo(() => {
    return differenceInMilliseconds(new Date(endTime), new Date(startTime));
  }, [startTime, endTime]);

  const [progressDelay, setProgressDelay] = useState<number | null>(3000);
  const [progressValue, setProgressValue] = useState<number>(() => {
    const value = Math.ceil((timeDelta.total / diff) * 100);
    if (value <= 0) {
      return 0;
    }
    return value;
  });

  useInterval(() => {
    if (progressValue <= 0) {
      setProgressDelay(null);
      return;
    }
    const value = Math.ceil((timeDelta.total / diff) * 100);
    setProgressValue(value);
  }, progressDelay);

  const timeFormatted = useMemo(
    () => formatTimeDelta(timeDelta, { daysInHours: true }),
    [timeDelta]
  );
  const { minutes, seconds } = timeFormatted;

  const showBookMoreTime = timeDelta.total <= 300000; // 5min

  const completedStyle = {
    _before: {
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      transformOrigin: '0 100%',
      transform: 'skewX(30deg)',
      width: '100%',
      height: '16px',
      boxSizing: 'border-box',
      zIndex: -1,
      bg: showBookMoreTime
        ? 'rgba(226, 81, 65, 0.9)'
        : 'rgba(255, 255, 255, 0.25)',
      borderRadius: '0 2px 1px 0',
      transition: 'all 1.5s ease-in-out',
    },
    _after: {
      content: '""',
      pos: 'absolute',
      bottom: 0,
      right: 0,
      transformOrigin: '0 0',
      transform: 'skewX(-30deg)',
      width: '100%',
      height: '16px',
      boxSizing: 'border-box',
      zIndex: -1,
      bg: showBookMoreTime
        ? 'rgba(226, 81, 65, 0.9)'
        : 'rgba(255, 255, 255, 0.25)',
      borderRadius: '0 1px 2px',
      transition: 'all 1.5s ease-in-out',
    },
  } as any; // eslint-disable-line

  return (
    <Box
      d="flex"
      flexDir="column"
      // maxWidth={{ base: '80px', sm: '110px' }}
      position="relative"
      right="30px"
      maxWidth={{ base: '280px', md: '350px' }}
      w="50%"
    >
      <Box
        d="flex"
        h={8}
        w="100%"
        background="transparent"
        borderRadius="6px"
        overflow="hidden"
      >
        <Box
          maxWidth={{ base: '80px', sm: '110px' }}
          w="full"
          position="relative"
          overflow="hidden"
          display="inline-block"
          {...completedStyle}
        >
          <Text
            color="white"
            fontSize={{ base: 'lg', md: 'xl' }}
            pl={{ base: 3, sm: 6 }}
            lineHeight={8}
            fontWeight={showBookMoreTime ? 'bold' : 'light'}
          >
            {minutes}:{seconds}
          </Text>
        </Box>
        <Box
          position="relative"
          ml="-9px"
          w="full"
          overflow="hidden"
          borderRightRadius="6px"
        >
          <Progress
            position="relative"
            colorScheme={showBookMoreTime ? 'white' : 'blue-gradient'}
            height="full"
            value={progressValue}
            variant="slant"
            zIndex={10}
          />
        </Box>
      </Box>
      {showBookMoreTime && (
        <motion.div
          animate={{ opacity: [1, 0, 1, 0, 1, 0, 1] }}
          transition={{
            duration: 10,
            repeat: 4,
            repeatType: 'loop',
          }}
        >
          <Box
            borderRadius="6px"
            right="10px"
            left="10px"
            my={2}
            background="red.400"
            p={2}
          >
            <Text fontSize={'sm'} color="white">
              {t('meetings.time_up')}
            </Text>
          </Box>
        </motion.div>
      )}
    </Box>
  );
};
export default ProgressBar;
