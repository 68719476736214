import React, { useMemo, useState, useCallback } from 'react';
import sortBy from 'lodash/fp/sortBy';
import {
  isSameMonth,
  isThisMonth,
  subMonths,
  isWithinInterval,
  endOfDay,
  parseISO,
} from 'date-fns';
import {
  Box,
  chakra,
  Spinner,
  Text,
  useMediaQuery,
  VStack,
} from '@chakra-ui/react';
import {
  MeetingFilter,
  FilterOptions,
} from '@src/components/molecules/MeetingHistory/MeetingFilter';
import { getSubtitleForFilter } from '@src/utils/function';
import PaymentHistoryTable from '@src/components/molecules/PaymentHistoryTable';
import PaymentItemMobile from '@src/components/molecules/PaymentHistoryTable/PaymentItemMobile';
import { useMyPurchasesQuery } from '@src/apollo/hooks';
import { useTranslation } from 'react-i18next';
import { useDateFormat } from '@src/hooks';

const PaymentHistory = () => {
  const [filter, setFilter] = useState<FilterOptions>('currentMonth');
  const [range, setRange] = useState<[Date, Date] | null>(null);
  const [isLargerMobile] = useMediaQuery('(min-width: 48em)');
  const { data, loading } = useMyPurchasesQuery();
  const { t } = useTranslation();
  const format = useDateFormat();
  const allPurchases = data?.getMyPurchases;

  const displayPayments = useMemo(() => {
    const parsed = allPurchases?.map((item) => ({
      ...item,
      date: parseISO(item.date),
    }));
    const historyMeetings = sortBy((m) => m.date, parsed).reverse();
    switch (filter) {
      case 'currentMonth':
        return historyMeetings.filter((item) => isThisMonth(item.date));
      case 'pastMonth':
        return historyMeetings.filter((item) =>
          isSameMonth(item.date, subMonths(new Date(), 1))
        );
      case 'pastSixMonths':
        return historyMeetings.filter((item) =>
          isSameMonth(item.date, subMonths(new Date(), 6))
        );
      case 'withinInterval':
        if (range) {
          const [start, end] = range;
          return historyMeetings.filter((item) =>
            isWithinInterval(item.date, { start, end: endOfDay(end) })
          );
        }
        return historyMeetings;
      default:
        return historyMeetings;
    }
  }, [filter, range, allPurchases]);

  const subtitle = useMemo(() => {
    return getSubtitleForFilter(filter, range, format);
  }, [filter, range, format]);

  const onChange = useCallback((value, range: [Date, Date] | undefined) => {
    setFilter(value);
    if (value === 'withinInterval' && range) {
      setRange(range);
    }
  }, []);

  if (loading)
    return (
      <chakra.div d="flex" alignItems="center" justifyContent="center" h="full">
        <Spinner />
      </chakra.div>
    );

  return (
    <Box maxW="5xl" mx="auto" py={8} px={3}>
      <Box d={{ md: 'flex' }} justifyContent={{ md: 'space-between' }}>
        <Text fontSize="lg" textTransform="capitalize" fontWeight="semibold">
          {subtitle}
        </Text>
        <Box alignSelf="flex-end" mt={3}>
          <MeetingFilter onChange={onChange} selected={filter} />
        </Box>
      </Box>
      {isLargerMobile ? (
        <Box d="flex" flexDir="column" pos="relative">
          <Box overflowX="auto" insetX={0}>
            <Box
              mt={6}
              py={2}
              verticalAlign="middle"
              display="inline-block"
              minW="full"
            >
              <Box
                boxShadow="md"
                overflowY="hidden"
                overflowX="hidden"
                borderBottomWidth="1px"
                borderColor="gray.300"
                rounded={{ sm: 'lg' }}
              >
                <PaymentHistoryTable payments={displayPayments} />
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <VStack my={5} spacing={4}>
          {displayPayments.length <= 0 && (
            <Box py={4}>{t('my_payments.no_payments')}</Box>
          )}
          {displayPayments.map((item) => (
            <PaymentItemMobile key={item.id} {...item} />
          ))}
        </VStack>
      )}
    </Box>
  );
};

export default PaymentHistory;
