import React, { useContext } from 'react';
import { Route, Router, Switch, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import App from '@src/App';
import { languages } from '@src/config/i18n';
import { ability } from '@src/config/defineAbility';
import {
  LanguageContext,
  Auth0Provider,
  LawyerContextProvider,
  AbilityContext,
} from '@src/context';
import { AuthModalContextProvider } from '@src/context';
import { RoomTokenProvider } from './context/RoomTokenContext';
import AuthCallback from './pages/Auth/Callback';

const history = createBrowserHistory();

history.listen(() => {
  window.scrollTo(0, 0);
});

function LegarooProvider() {
  const languageContext = useContext(LanguageContext);
  return (
    <Router history={history}>
      <Auth0Provider
        clientID={process.env.REACT_APP_AUTH0_CLIENT_ID as string}
        domain={process.env.REACT_APP_AUTH0_DOMAIN as string}
        redirectUri={`${window.location.origin}${process.env.REACT_APP_AUTH0_REDIRECT}`}
        audience={process.env.REACT_APP_AUTH0_AUDIENCE}
        responseType={process.env.REACT_APP_AUTH0_RESPONSE_TYPE}
        scope={process.env.REACT_APP_AUTH0_SCOPE}
      >
        <AuthModalContextProvider>
          <RoomTokenProvider>
            <AbilityContext.Provider value={ability}>
              <Switch>
                <Route exact path="/auth/callback" component={AuthCallback} />
                <Route
                  path={`/:locale(${languages.join('|')})`}
                  render={() => {
                    return (
                      <LawyerContextProvider>
                        <App />
                      </LawyerContextProvider>
                    );
                  }}
                />
                <Route
                  render={({ location }) => {
                    return (
                      <Redirect
                        to={`/${languageContext.language.code}${location.pathname}`}
                      />
                    );
                  }}
                />
              </Switch>
            </AbilityContext.Provider>
          </RoomTokenProvider>
        </AuthModalContextProvider>
      </Auth0Provider>
    </Router>
  );
}

export default LegarooProvider;
