import React from 'react';
import { Box, chakra } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import PayoutLineItem from './PayoutLineItem';

const thStyle = {
  px: 2,
  py: 3,
  bg: 'gray.50',
  textAlign: 'left',
  fontSize: 'xs',
  fontWeight: 'medium',
  color: 'gray.500',
  textTransform: 'uppercase',
  letterSpacing: '0.05em',
};

export interface PayoutHistoryTableProps {
  payouts:
    | (
        | Maybe<
            {
              __typename?: 'PayoutNode' | undefined;
            } & Pick<PayoutNode, 'id' | 'description' | 'amount' | 'date'>
          >
        | undefined
      )[]
    | undefined;
}

const PayoutHistoryTable = ({ payouts }: PayoutHistoryTableProps) => {
  const { t } = useTranslation();
  return (
    <Box className="divide-y" d="table" as="table" minW="100%">
      <thead>
        <tr>
          <Box sx={thStyle} px={2} as="th">
            {t('common.transaction_id')}
          </Box>
          <Box sx={thStyle} as="th">
            {t('common.description')}
          </Box>
          <Box sx={thStyle} as="th">
            {t('common.date')}
          </Box>
          <Box sx={thStyle} as="th">
            {t('common.amount_payed')}
          </Box>
        </tr>
      </thead>
      <Box className="divide-y" bg="white" d="table-row-group" as="tbody">
        {!payouts && (
          <tr>
            <chakra.td colSpan={5} alignItems="center" p={6}>
              <chakra.div
                d="flex"
                alignItems="center"
                justifyContent="center"
                color="gray.800"
              >
                {t('payouts.no_payouts')}
              </chakra.div>
            </chakra.td>
          </tr>
        )}
        {payouts?.map?.((payout) => (
          <PayoutLineItem key={payout?.id} payout={payout} />
        ))}
      </Box>
    </Box>
  );
};

export default PayoutHistoryTable;
