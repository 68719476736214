import React, { useCallback } from 'react';
import { HStack, IconButton } from '@chakra-ui/react';
// import { ReactComponent as FBIcon } from '@src/icons/facebook-icon.svg';
import { ReactComponent as GoogleIcon } from '@src/icons/google-icon.svg';
import useAuth0 from '@src/hooks/useAuth0';

const AuthSocialButtons = () => {
  const { authorize } = useAuth0();
  const onAuthorize = useCallback(
    (connection) => {
      authorize({
        connection,
      });
    },
    [authorize]
  );

  return (
    <HStack ml={3} spacing={2}>
      {/* <IconButton
        aria-label="Signin with FB"
        color="facebook.400"
        size="lg"
        shadow="base"
        icon={<FBIcon />}
        onClick={() => onAuthorize('facebook')}
      /> */}
      <IconButton
        size="lg"
        aria-label="Signin with Google"
        shadow="base"
        icon={<GoogleIcon />}
        onClick={() => onAuthorize('google-oauth2')}
      />
    </HStack>
  );
};

export default AuthSocialButtons;
