import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import DateFormat from '@src/components/atoms/DateFormat';
import { formatMoney } from '@src/utils';

export type PayoutLineProps = {
  payout:
    | Pick<PayoutNode, 'id' | 'description' | 'amount' | 'date'>
    | undefined
    | null;
};

const PayoutLineItem = ({ payout }: PayoutLineProps) => {
  return (
    <Box as="tr">
      <Box
        as="td"
        textAlign="left"
        px={3}
        py={4}
        whiteSpace="nowrap"
        fontSize="sm"
      >
        {payout?.id}
      </Box>

      <Box as="td" px={2} py={4}>
        <Box d="flex" alignItems="left">
          <Box>
            <Text fontSize="sm" color="gray.900">
              {payout?.description}
            </Text>
          </Box>
        </Box>
      </Box>

      <Box as="td" px={2} py={4} whiteSpace="nowrap">
        <DateFormat date={new Date(payout?.date)} />
      </Box>

      <Box as="td" px={2} py={4} whiteSpace="nowrap">
        <Box d="flex" alignItems="center" flexDirection="column">
          <Text fontSize="sm" fontWeight="medium">
            {formatMoney(payout?.amount)}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default PayoutLineItem;
