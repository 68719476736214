import React from 'react';
import { chakra, Text, Icon, Flex, Button, Spinner } from '@chakra-ui/react';
import { Redirect, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { parseISO, differenceInMinutes } from 'date-fns';
import MeetingStepper from '@src/components/molecules/Meetings/PaymentStepper';
import LawyerNameCard from '@src/components/molecules/LawyerNameCard';
import { ReactComponent as ArrowForward } from '@src/icons/arrow-forward.svg';
import Link from '@src/components/atoms/Link';
import MeetingTimeBox from '@src/components/molecules/Meetings/MeetingTimeBox';
import { useGetLawyerQuery } from '@src/apollo/hooks';
import { formatMoney } from '@src/utils';

export default function ThankyouPage() {
  const { t } = useTranslation();
  const { state } = useLocation<{
    appointment: AppointmentType;
    payWithCardLast4: string;
    lawyerId?: string;
  }>();
  const { locale } = useParams<BaseRouteParams>();
  const { data, loading } = useGetLawyerQuery({
    variables: { lawyerId: Number(state?.lawyerId) },
    skip: !state?.lawyerId,
  });
  if (!state) {
    return <Redirect to={`/${locale}`} />;
  }

  const { appointment, payWithCardLast4 } = state;
  const lawyer = data?.getLawyer;

  const startsAt = parseISO(appointment.times.startsAt);
  const endsAt = parseISO(appointment.times.endsAt);
  const duration = differenceInMinutes(endsAt, startsAt);
  return (
    <chakra.div
      borderColor="blue.50"
      backgroundPosition="center"
      w="full"
      mx="auto"
      minHeight="100vh"
      marginBottom={20}
    >
      <chakra.div maxW="5xl" mx="auto" px={6} marginTop="70px">
        <chakra.div
          d="flex"
          justifyContent={{ base: 'center', lg: 'space-between' }}
          alignItems="center"
          pb={6}
        >
          <Text fontWeight="800">
            {t('meetings.meeting_has_been_scheduled')}
          </Text>
          <MeetingStepper step={3} />
        </chakra.div>
      </chakra.div>
      <chakra.div
        w="full"
        pos="relative"
        d="flex"
        zIndex={0}
        _before={{
          content: '""',
          width: '100%',
          height: { base: '30%', lg: '60%' },
          position: 'absolute',
          zIndex: -1,
          top: 0,
          left: 0,
          background:
            'linear-gradient(180deg, #D2E3FB 0%, rgba(255, 255, 255, 0) 100%);',
        }}
      >
        <chakra.div
          width="100%"
          maxW="5xl"
          mx="auto"
          d="flex"
          flexDir={{ base: 'column', lg: 'row' }}
        >
          <chakra.div
            mx="auto"
            w="full"
            px={6}
            width={{ base: '100%', lg: '60%' }}
          >
            <chakra.div
              pt={{ base: 8, lg: 12 }}
              mb={6}
              d="flex"
              alignItems="center"
              justifyContent={{ base: 'center', lg: 'flex-start' }}
            >
              {loading && <Spinner />}
              {lawyer && <LawyerNameCard lawyer={lawyer} />}
            </chakra.div>
            <MeetingTimeBox date={startsAt} duration={duration} />
          </chakra.div>
          <chakra.div width={{ sm: '100%', lg: '40%' }}>
            <chakra.div p="36px" d="flex" flexDir="column">
              <Flex
                p={4}
                justifyContent="space-between"
                width="100%"
                borderBottomWidth={'1px'}
                borderBottomColor={'blue.200'}
                borderStyle={'dashed'}
                flexDir={'column'}
              >
                <Text color="gray.600" fontSize="sm">
                  {t('meetings.have_been_charged')}
                </Text>
                {appointment.purchaseInfo.map((info: PurchaseType | null) => {
                  if (info) {
                    return (
                      <Text key={info?.description} as="strong">
                        {formatMoney(info.details.total)}
                      </Text>
                    );
                  } else {
                    return (
                      <Text as="strong">
                        {t('meetings.no_payment_information')}
                      </Text>
                    );
                  }
                })}
              </Flex>

              <Flex
                p={4}
                flexDir="column"
                width="100%"
                borderBottomWidth={'1px'}
                borderBottomColor={'blue.200'}
                borderStyle={'dashed'}
              >
                <chakra.div d="flow" textStyle="secondary">
                  {t('meetings.from_card_ending_with')}
                  <Text as="strong">{payWithCardLast4}</Text>
                </chakra.div>
                <chakra.div d="flow" alignItems="center">
                  <Link
                    to="/my-meetings/history"
                    fontSize="sm"
                    textStyle="strongYellow"
                  >
                    {t('meetings.view_payment_history')}
                    <Icon as={ArrowForward} ml="5px" textStyle="strongYellow" />
                  </Link>
                </chakra.div>
              </Flex>

              <Link
                to="/"
                p={4}
                color="gray.600"
                textDecoration="underline"
                _hover={{ textDecoration: 'underline' }}
              >
                {t('meetings.terms_and_conditions')}
              </Link>
              <Button
                as={Link}
                to="/my-meetings"
                ml={4}
                mt={2}
                colorScheme="primary"
                alignSelf="center"
                fontSize="sm"
              >
                {t('meetings.my_schedule')}
              </Button>
            </chakra.div>
          </chakra.div>
        </chakra.div>
      </chakra.div>
    </chakra.div>
  );
}
