import React from 'react';
import {
  Alert as ChakraAlert,
  AlertDescription,
  AlertTitle,
  chakra,
  ChakraProps,
  CloseButton,
  UseToastOptions,
} from '@chakra-ui/react';
import { CheckIcon, InfoIcon, WarningIcon } from './icons';

const STATUSES = {
  info: { icon: InfoIcon },
  warning: { icon: WarningIcon },
  success: { icon: CheckIcon },
  error: { icon: WarningIcon },
};

export type ToastOptions = Omit<UseToastOptions, 'render'> & {
  onClose?(): void;
};

export type AlertProps = Omit<ToastOptions, 'position'> &
  Omit<ChakraProps, 'position' | 'pos' | 'id'>;

export const Alert = (props: AlertProps) => {
  const {
    status = 'success',
    variant = 'roo',
    id,
    title,
    isClosable,
    onClose,
    description,
    ...rest
  } = props;
  const { icon: BaseIcon } = STATUSES[status];
  return (
    <ChakraAlert
      status={status}
      variant={variant}
      id={id as string | undefined}
      alignItems="start"
      borderRadius="md"
      boxShadow="lg"
      paddingRight={8}
      textAlign="left"
      width="auto"
      rounded="xl"
      mt={'60px'}
      {...rest}
    >
      <chakra.span display="inherit" mr={3} w={8} h={8} rounded="xl">
        <BaseIcon w="100%" h="100%" />
      </chakra.span>
      <chakra.div flex="1">
        {title && <AlertTitle>{title}</AlertTitle>}
        {description && (
          <AlertDescription display="block">{description}</AlertDescription>
        )}
      </chakra.div>
      {isClosable && (
        <CloseButton
          onClick={onClose}
          position="absolute"
          color="#6381A9"
          right={2}
          top={2}
        />
      )}
    </ChakraAlert>
  );
};

Alert.displayName = 'SuccessMessage';
