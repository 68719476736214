import React, { useMemo, useState } from 'react';
import { Row, useAsyncDebounce } from 'react-table';
import { Box, Input } from '@chakra-ui/react';

// Component for Global Filter
export function GlobalFilter({ globalFilter, setGlobalFilter }: any) {
  const [value, setValue] = useState(globalFilter);

  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <Box>
      <Input
        value={value || ''}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        size="sm"
        variant="flushed"
        placeholder=" Enter value "
        display="inline"
      />
    </Box>
  );
}

// Component for Default Column Filter
export function DefaultFilterForColumn({
  column: {
    filterValue,
    preFilteredRows: { length },
    setFilter,
  },
}: any) {
  return (
    <Input
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      variant="underlined"
      size="xs"
      fontSize={'xs'}
      fontWeight="400"
      placeholder={`Search ${length} records..`}
      //   style={{ marginTop: '10px' }}
    />
  );
}

// Component for Custom Select Filter
export function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}: any) {
  // Use preFilteredRows to calculate the options
  const options = useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row: Row) => {
      options.add(row.values[id]);
    });
    return [...options?.values()];
  }, [id, preFilteredRows]);

  // UI for Multi-Select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option as string}>
          {option as string}
        </option>
      ))}
    </select>
  );
}
