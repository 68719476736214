/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { FormControl, FormLabel, Box, Text } from '@chakra-ui/react';

export const Phone = ({
  label,
  isDisabled,
  input,
  meta,
  formControlProps,
  ...props
}: any) => {
  const showError = (meta: any) => meta.touched && meta.invalid;
  const isError = showError({ meta });

  return (
    <FormControl
      isInvalid={isError}
      isDisabled={isDisabled}
      outline="none"
      appearance="none"
      d="inline-flex"
      verticalAlign="top"
      flexDirection="column"
      padding={3}
      paddingTop={8}
      backgroundColor="white"
      paddingLeft={4}
      {...formControlProps}
      rounded="md"
      boxShadow="0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%)"
    >
      {!!label && <FormLabel htmlFor={input.name}>{label}</FormLabel>}
      <Box marginLeft={3}>
        <PhoneInput
          className={isDisabled ? 'disabled' : ''}
          disabled={isDisabled}
          countryCallingCodeEditable={true}
          {...input}
          {...props}
          isRequired
          international={true}
        />
        {meta.error && meta.touched && (
          <Text color="red.500" fontSize="xs" fontWeight="bold">
            {meta.error}
          </Text>
        )}
      </Box>
    </FormControl>
  );
};
