import React, { useContext, useEffect, useState } from 'react';
import LogRocket from 'logrocket';
import CustomRoute from '@src/Router';
import { useViewerQuery } from './apollo/hooks';
import { AbilityContext, LanguageContext } from '@src/context';
import { defineAbilityFor } from '@src/config/defineAbility';
import LawyerApplication from '@src/pages/LawyerApplication';
import Lawyer from '@src/pages/Lawyer';
import Directory from '@src/pages/Directory';
import Bookings from '@src/pages/Booking';
import MeetingRoom from '@src/pages/MeetingRoom';
import MyMeetings from '@src/pages/MyMeetings';
import MyPayments from '@src/pages/MyPayments';
import Admin from '@src/pages/Admin';
import IndexPage from '@src/pages';
import Profile from '@src/pages/Profile';
import PrivateRoute from './PrivateRoute';
import WorkingHours from './pages/WorkingHours';
import PayoutStatement from './pages/PayoutStatement';
import ContactUs from './pages/ContactUs';
import PrivacyPolicy from './pages/StaticPages/privacyPolicy';
import LawyerPrivacyPolicy from './pages/StaticPages/privacyPolicyLawyer';
import Terms from './pages/StaticPages/termsAndConditions';
import LawyerTerms from './pages/StaticPages/termsAndConditionsLawyer';
import LawyerAccountAlert from '@molecules/LawyerAccountAlert';
import FreshChat from './components/atoms/FreshChat';
import { isLawyer } from '@src/utils';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
if (process.env.NODE_ENV === 'production') {
  LogRocket.init('qbqxfn/legaroo-meets');
}
const BlogLink = () => {
  window.location.replace('https://meets.legaroo.com/blog');
  return null;
};

const App: React.FC = () => {
  const [showLawyerNotConnected, setShowLawyerNotConnected] = useState(false);
  const { data, loading } = useViewerQuery();
  const { t } = useTranslation();
  const ability = useContext(AbilityContext);
  const { changeLanguage } = useContext(LanguageContext);

  const viewer = data?.viewer;

  useEffect(() => {
    if (viewer && process.env.NODE_ENV === 'production') {
      LogRocket.identify(viewer?.email, {
        name: `${viewer?.profile.firstName} ${viewer?.profile.lastName}`,
        email: viewer?.email,
        roles: viewer?.roles?.join(',') || '',
      });
    }
    if (viewer && viewer?.preferredLanguage) {
      changeLanguage(viewer?.preferredLanguage);
    }
  }, [viewer, changeLanguage]);

  useEffect(() => {
    if (!loading) {
      defineAbilityFor(ability, viewer);
    }
  }, [ability, viewer, loading]);

  useEffect(() => {
    if (isLawyer(viewer) && !viewer?.hasConnectedAccount) {
      setShowLawyerNotConnected(true);
    }
  }, [loading, viewer]);

  return (
    <>
      <Helmet>
        <title>{t('seo.title')}</title>
        <meta name="description" content={t('seo.general_description')} />
        <meta name="keywords" content={t('seo.keywords')} />
      </Helmet>
      {showLawyerNotConnected && <LawyerAccountAlert />}
      {process.env.NODE_ENV === 'production' && <FreshChat />}
      <CustomRoute path="/" exact Component={IndexPage} />
      <CustomRoute path="/lawyers" Component={Lawyer} />
      <CustomRoute path="/directory" Component={Directory} />
      <CustomRoute path="/application" Component={LawyerApplication} />
      <CustomRoute path="/booking" Component={Bookings} />
      <CustomRoute path="/contact" Component={ContactUs} />
      <CustomRoute path="/privacy-policy" Component={PrivacyPolicy} />
      <CustomRoute path="/blog" Component={BlogLink} />

      <CustomRoute
        path="/lawyer-privacy-policy"
        Component={LawyerPrivacyPolicy}
      />
      <CustomRoute path="/terms" Component={Terms} />
      <CustomRoute path="/lawyer-terms" Component={LawyerTerms} />
      <CustomRoute path="/callback" exact hardRedirect="/" />
      <PrivateRoute path="/meeting/:meeting_id">
        <MeetingRoom />
      </PrivateRoute>
      <PrivateRoute path="/my-meetings">
        <MyMeetings />
      </PrivateRoute>
      <PrivateRoute path="/my-payments">
        <MyPayments />
      </PrivateRoute>

      <PrivateRoute path="/profile">
        <Profile />
      </PrivateRoute>

      <PrivateRoute
        authorize={(ability) => ability.can('create', 'time_slots')}
        path="/payouts"
      >
        <PayoutStatement />
      </PrivateRoute>
      <PrivateRoute
        path="/admin"
        authorize={(ability) => ability.can('approve', 'application')}
      >
        <Admin />
      </PrivateRoute>
      <PrivateRoute
        path="/availability"
        authorize={(ability) => ability.can('create', 'time_slots')}
      >
        <WorkingHours />
      </PrivateRoute>
    </>
  );
};

export default App;
