import React from 'react';
import {
  Avatar,
  Box,
  Button,
  chakra,
  Heading,
  Icon,
  Tag,
  Text,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react';
import { useHistory, useParams } from 'react-router-dom';
import { useToast } from '@src/hooks';
import RooLayout from '@src/components/organisms/RooLayout';
import { ReactComponent as LocationIcon } from '@src/icons/location.svg';
import { useTranslation } from 'react-i18next';
import { useGetLawyerBySlugQuery } from '@src/apollo/hooks';
import Loader from '@src/components/atoms/Loader';
import { formatMoney } from '@src/utils';
import FieldLabel from '@src/components/atoms/FieldLabel';
import { Helmet } from 'react-helmet';
export default function Lawyer() {
  const { t } = useTranslation();
  const toast = useToast();
  const { slug, locale } = useParams<{ slug: string } & BaseRouteParams>();
  const avatarSize = useBreakpointValue({ base: 'xl', sm: '3xl' });
  const history = useHistory();
  const { data, loading } = useGetLawyerBySlugQuery({
    variables: { lawyerSlug: slug },
    skip: !slug,
  });
  const lawyer = data?.getLawyerBySlug;
  const copyToClipboard = (path: string) => {
    navigator.clipboard.writeText(`${window.location.origin}${path}`);
    toast.success({
      title: t('common.copied_to_clipboard'),
      duration: 9000,
      isClosable: true,
    });
  };
  if (loading) return <Loader />;
  if (!lawyer) {
    return <div>no lawyer</div>;
  }
  return (
    <RooLayout theme="skyBlue">
      <Helmet>
        <title>{`${lawyer.firstName} ${lawyer.lastName} | Legaroo Meets`}</title>
        <meta
          name="description"
          content={lawyer.bio || t('seo.lawyer_description')}
        />
        <link
          rel="canonical"
          href={`${t('seo.canonical.lawyers')}/profile/${lawyer.slug}`}
        />
      </Helmet>
      <chakra.div maxW="3xl" mx="auto" py={8} px={{ base: 3, sm: 6 }} mt={12}>
        <chakra.div d="flex" flexDir={{ base: 'column', md: 'row' }}>
          <chakra.div
            mb={{ base: 4, sm: 0 }}
            flexShrink={0}
            mr={{ sm: 8 }}
            d={{ base: 'flex', sm: 'block' }}
            flexDir="column"
            alignItems="center"
          >
            <Avatar
              name={`${lawyer.firstName} ${lawyer.lastName}`}
              size={avatarSize}
              src={lawyer.headshot as string}
            />
          </chakra.div>
          <chakra.div
            alignSelf="center"
            d={{ sm: 'flex' }}
            alignItems={{ sm: 'center' }}
            justifyContent={{ sm: 'space-between' }}
            w="full"
          >
            <chakra.div textAlign={{ base: 'center', md: 'left' }}>
              <Heading
                as="h4"
                fontWeight="light"
                fontSize={{ base: '2xl', sm: '3xl' }}
              >
                {`${lawyer.firstName} ${lawyer.lastName}`}
              </Heading>
              <Text fontSize="xl" color="gray.600" fontWeight="normal">
                {t(`fields.title_options.${lawyer.title}`)}
              </Text>
              <Text fontWeight="light" color="gray.600" fontSize="sm">
                {lawyer.user.email}
              </Text>
              <Text fontSize="xs" color="gray.600" fontWeight="light" mt={2}>
                <Icon as={LocationIcon} w={4} h={4} mr={1} mt={-1} />
                {lawyer.location.name}
              </Text>
            </chakra.div>
            <chakra.div
              d="flex"
              flexDir="column"
              mt={{ base: 4, sm: 0 }}
              pl={2}
            >
              <Button
                onClick={() =>
                  history.push(`/${locale}/booking/new/${lawyer.id}`)
                }
                variant="outline"
                colorScheme="blue"
                fontWeight="medium"
                fontSize="sm"
              >
                {t('lawyer_card.schedule_meeting')}
              </Button>
              <Button
                onClick={() =>
                  copyToClipboard(`/${locale}/lawyers/profile/${lawyer.slug}`)
                }
                mt={2}
                colorScheme="blue"
                fontWeight="medium"
                fontSize="sm"
              >
                {t('lawyer_card.copy_profile')}
              </Button>
            </chakra.div>
          </chakra.div>
        </chakra.div>
      </chakra.div>
      <chakra.div pos="relative" pt={12} pb={16} w="full">
        <chakra.div
          aria-hidden="true"
          pos="absolute"
          insetX={0}
          top={0}
          h={48}
          bg="linear-gradient(180deg, #D2E3FB 0%, rgba(255, 255, 255, 0) 100%)"
        />
        <chakra.div maxW="3xl" mx="auto" pos="relative" px={{ base: 3, sm: 6 }}>
          <chakra.div>
            <Text mt={4} color="gray.600" fontWeight="light" fontSize="lg">
              {lawyer.bio}
            </Text>
          </chakra.div>
          <Box as="dl" mt={8}>
            <Box d={{ sm: 'flex' }} alignItems={{ sm: 'flex-start' }}>
              <FieldLabel flexShrink={{ sm: 0 }} d={{ base: 'inline-block' }}>
                <Box as="dt" fontWeight="semibold">
                  {t('fields.rate.label')}
                </Box>
                <Box as="dd" d="inline-block">
                  {formatMoney(lawyer.hourlyRate)}
                </Box>
                <chakra.span
                  ml={4}
                  px={3}
                  bg="primary"
                  color="white"
                  rounded="md"
                  fontSize="xs"
                  fontWeight="semibold"
                  w="full"
                  textAlign="center"
                >
                  {t('common.per_hour')}
                </chakra.span>
              </FieldLabel>

              <Box ml={{ sm: 12 }} mt={{ base: 6, sm: 0 }}>
                <Box as="dt" fontWeight="semibold">
                  {t('fields.legal_practice.label')}
                </Box>
                <dd>
                  <Box as="ul" mt={1} lineHeight={8}>
                    {lawyer.legalPractices.map((legalPractice) => (
                      <Box as="li" mr={2} d="inline" key={legalPractice?.slug}>
                        <Tag
                          bg="blue.100"
                          rounded="full"
                          textTransform="capitalize"
                          fontSize="sm"
                          color="black"
                          fontWeight="normal"
                        >
                          {t(
                            `practice_areas.${legalPractice?.slug}`,
                            legalPractice?.name
                          )}
                        </Tag>
                      </Box>
                    ))}
                  </Box>
                </dd>
              </Box>
            </Box>
            <Box mt={4}>
              <Box as="dt" fontWeight="semibold">
                {t('fields.language.label')}
              </Box>
              <dd>
                <Box as="ul" mt={1} lineHeight={8}>
                  {lawyer.spokenLanguages.map((language) => (
                    <Box as="li" d="inline" mr={2} key={language?.code}>
                      <Tag
                        rounded="full"
                        textTransform="capitalize"
                        fontSize="sm"
                        bg="blue.100"
                        color="black"
                        fontWeight="normal"
                      >
                        {language
                          ? t(`languages.${language.code}`, language.name)
                          : ''}
                      </Tag>
                    </Box>
                  ))}
                </Box>
              </dd>
            </Box>

            <Box border="1px" borderColor="skyBlue.100" mt={8} rounded="md">
              <Box pt={4} pb={6} px={{ base: 3, sm: 6 }}>
                <Box fontWeight="bold" fontSize="xl" mt={1}>
                  {t('common.education')}
                </Box>
                <VStack
                  as="dl"
                  align="initial"
                  spacing={{ base: 6, sm: 4 }}
                  mt={4}
                >
                  {lawyer.educationalBackground.map((item) => (
                    <Box
                      key={`${item?.title}-${item?.institution}-${item?.graduationYear}`}
                      d={{ sm: 'grid' }}
                      gridTemplateColumns={{ sm: 'repeat(3, minmax(0, 1fr))' }}
                      gridGap={{ sm: 4 }}
                    >
                      <FieldLabel as="dt" fontSize="sm">
                        <Box fontWeight="semibold">
                          {t('fields.year.label')}
                        </Box>
                        {item?.graduationYear}
                      </FieldLabel>
                      <FieldLabel
                        as="dd"
                        fontSize="sm"
                        mt={{ base: 1, sm: 0 }}
                        gridColumn="span 2 / span 2"
                      >
                        <Text fontWeight="semibold">{item?.institution}</Text>
                        {item?.title}
                      </FieldLabel>
                    </Box>
                  ))}
                </VStack>
              </Box>

              <Box
                pt={4}
                pb={6}
                px={{ base: 3, sm: 6 }}
                borderTop="1px"
                borderTopColor="skyBlue.100"
              >
                <Box fontWeight="bold" fontSize="xl" mt={1}>
                  {t('profile.laboral_experience')}
                </Box>

                <VStack
                  as="dl"
                  align="initial"
                  spacing={{ base: 6, sm: 4 }}
                  mt={4}
                >
                  {lawyer.workExperience.map((item) => (
                    <Box
                      key={`${item?.title}-${item?.companyName}-${item?.startYear}`}
                      d={{ sm: 'grid' }}
                      gridTemplateColumns={{ sm: 'repeat(3, minmax(0, 1fr))' }}
                      gridGap={{ sm: 4 }}
                    >
                      <FieldLabel as="dt" fontSize="sm">
                        <Box fontWeight="semibold">
                          {t('fields.from.label')} - {t('fields.to.label')}
                        </Box>
                        {item?.startYear} -{' '}
                        {item?.endYear ?? t('common.current')}
                      </FieldLabel>
                      <FieldLabel
                        as="dd"
                        fontSize="sm"
                        mt={{ base: 1, sm: 0 }}
                        gridColumn="span 2 / span 2"
                      >
                        <Text fontWeight="semibold">{item?.companyName}</Text>
                        {item?.title}
                      </FieldLabel>
                    </Box>
                  ))}
                </VStack>
              </Box>
            </Box>
          </Box>
        </chakra.div>
      </chakra.div>
    </RooLayout>
  );
}
