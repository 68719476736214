/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { languages } from '@src/i18n/Languages';
import { useSetPreferredLanguageMutation } from '@src/apollo/hooks';

const findLanguage = (lang: string) => {
  return languages.find(({ code }: { code: string }) => {
    return code === lang;
  });
};

const LanguageContext = React.createContext({
  language: languages[0],
  changeLanguage: (lang: string) => {},
});

function LanguageContextProvider(props: { children: React.ReactNode }) {
  const { i18n } = useTranslation();
  const [setPreferredLanguage] = useSetPreferredLanguageMutation();

  const [language, setLang] = React.useState(() => {
    const current = localStorage.getItem('i18nextLng') || i18n.language;
    const foundLanguage = findLanguage(current);
    return foundLanguage ? foundLanguage : languages[0];
  });
  const history = useHistory();

  const changeLanguage = useCallback(
    (language: string) => {
      i18n.changeLanguage(language, (err: string) => {
        if (err) return console.log('something went wrong loading', err);
        const foundLanguage = findLanguage(language);
        if (foundLanguage) {
          setLang(foundLanguage);
          setPreferredLanguage({
            variables: {
              language,
            },
          });
          const url = history.location.pathname.split('/');
          url[1] = foundLanguage.code;
          history.push(url.join('/'));
        }
      });
    },
    [i18n, setPreferredLanguage, history]
  );

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {props.children}
    </LanguageContext.Provider>
  );
}
export { LanguageContext, LanguageContextProvider };
