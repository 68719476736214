import React from 'react';

import { Form, Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Alert,
  AlertIcon,
  VStack,
  HStack,
  Button,
  Stack,
} from '@chakra-ui/react';
import { TextField, DatePicker, Phone } from '@src/components/atoms/Fields';
import { useFormValidation } from '@src/hooks';
export interface UserProfileFormValues {
  firstName: string;
  lastName: string;
  dob: Date;
  phone: string;
}

export interface ProfileFormProps {
  onSubmit: (v: UserProfileFormValues) => void;
  // eslint-disable-next-line
  initialValues?: any;
  editable?: boolean;
  onCancel(): void;
}

const MAX_DATE = new Date();

const UserProfileForm = ({
  initialValues,
  onSubmit,
  editable,
  onCancel,
}: ProfileFormProps) => {
  const { t } = useTranslation();
  const isDisable = !editable;
  const { validPhone } = useFormValidation();
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, submitting, submitError }) => (
        <Box as="form" onSubmit={handleSubmit} p={{ base: 4, md: 10 }}>
          {submitError && (
            <Alert status="error" mb={6}>
              <AlertIcon />
              {submitError}
            </Alert>
          )}

          <VStack spacing={4}>
            <Stack direction={['column', 'row']} w="full" spacing={4}>
              <TextField
                isDisabled={isDisable}
                name="firstName"
                label="First Name"
              />
              <TextField
                isDisabled={isDisable}
                name="lastName"
                label="Last Name"
              />
            </Stack>
            <Field
              component={Phone}
              isDisabled={isDisable}
              name="phone"
              label={t('fields.phone.label')}
              fieldProps={{ validate: validPhone() }}
            />
            <DatePicker
              isDisabled={isDisable}
              name="dob"
              label={t('common.birthday')}
              dateFormatCalendar="MMMM"
              showYearDropdown
              yearDropdownItemNumber={80}
              scrollableYearDropdown
              maxDate={MAX_DATE}
            />
          </VStack>
          {editable && (
            <HStack
              pt={6}
              spacing={4}
              alignItems="center"
              justifyContent="center"
            >
              <Button disabled={submitting} px={10} onClick={onCancel}>
                {t('common.cancel')}
              </Button>
              <Button
                colorScheme="orange"
                isLoading={submitting}
                type="submit"
                px={10}
              >
                {t('common.save')}
              </Button>
            </HStack>
          )}
        </Box>
      )}
    />
  );
};

export default UserProfileForm;
