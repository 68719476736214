import { useEffect, useState } from 'react';
import { useVideoContext } from '@src/context/VideoContext';
import { RemoteParticipant } from '@src/video';

export default function useIsHostConnected() {
  const { meeting } = useVideoContext();
  const [isHostConnected, setIsHostConnected] = useState(false);

  useEffect(() => {
    if (meeting) {
      const participants = Array.from(meeting.participants?.values() ?? []);
      const isHostConnected = participants?.some((participant) => {
        const role = participant.identity.split('_')[1];
        return role === 'lawyer';
      });

      if (isHostConnected) {
        setIsHostConnected(true);
      }

      const participantConnected = (participant: RemoteParticipant) => {
        const role = participant.identity.split('_')[1];
        if (role === 'lawyer') {
          setIsHostConnected(true);
        }
      };
      meeting.on('participantConnected', participantConnected);
      return () => {
        meeting.off('participantConnected', participantConnected);
      };
    }
  }, [meeting]);

  return isHostConnected;
}
