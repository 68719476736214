import { useContext } from 'react';
import { SelectedParticipantContext } from '@src/context';
import { useVideoContext } from '@src/context/VideoContext';

import useDominantSpeaker from './useDominantSpeaker';
import useParticipants from './useParticipants';
import useScreenShareParticipant from './useScreenShareParticipant';

export default function useMainParticipant() {
  const { selectedParticipant } = useContext(SelectedParticipantContext);
  const screenShareParticipant = useScreenShareParticipant();
  const dominantSpeaker = useDominantSpeaker();
  const participants = useParticipants();

  const { meeting } = useVideoContext();
  const localParticipant = meeting?.localParticipant;

  const remoteScreenShareParticipant =
    screenShareParticipant !== localParticipant ? screenShareParticipant : null;

  // The participant that is returned is displayed in the main video area. Changing the order of the following
  // variables will change the how the main speaker is determined.
  return (
    selectedParticipant ||
    remoteScreenShareParticipant ||
    dominantSpeaker ||
    participants[0] ||
    localParticipant
  );
}
