import React, { useCallback, useState, useRef } from 'react';
import Cropper, { ReactCropperElement } from 'react-cropper';
import {
  Box,
  Avatar,
  Icon,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
} from '@chakra-ui/react';
import { ReactComponent as EditIcon } from '@src/icons/edit.svg';
import {
  GetMyLawyerProfileDocument,
  useGenerateAvatarPresignedUrlMutation,
  useUpdateLawyerProfileMutation,
  ViewerDocument,
} from '@src/apollo/hooks';
import { useTranslation } from 'react-i18next';
import { useToast } from '@src/hooks';
import { avatarFormatFilename } from '@src/utils';
export interface AvatarUploadProps {
  size?: string;
  name: string;
  src: string;
}

export default function AvatarUpload({ size, name, src }: AvatarUploadProps) {
  const { t } = useTranslation();
  const toast = useToast();
  const cropperRef = useRef<ReactCropperElement>(null);
  const [updateProfile] = useUpdateLawyerProfileMutation({
    refetchQueries: [
      { query: GetMyLawyerProfileDocument },
      { query: ViewerDocument },
    ],
    awaitRefetchQueries: true,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isCroppable, setIsCroppable] = useState(false);
  const [selectedFile, setSelectedFile] = useState<
    (File & { preview: string }) | null
  >();
  const previewModal = useDisclosure();
  const [presignedUrl] = useGenerateAvatarPresignedUrlMutation();

  const onCrop = useCallback(async () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    if (!isCroppable || !selectedFile || !cropper) {
      return;
    }
    const croppedCanvas = cropper.getCroppedCanvas();
    const dataUrl = croppedCanvas.toDataURL();
    const fileFormat = selectedFile.name.split('.').pop() ?? 'jpeg';
    const filename = avatarFormatFilename(name, fileFormat);
    setIsLoading(true);

    try {
      const res: Response = await fetch(dataUrl);
      const blob: Blob = await res.blob();
      const newFile = new File([blob], filename, { type: selectedFile.type });

      const { data } = await presignedUrl({ variables: { name: filename } });
      if (data?.generateAvatarPresignedUrl) {
        const options = {
          method: 'PUT',
          headers: {
            'Content-Type': newFile.type,
          },
          body: newFile,
        };
        await fetch(data.generateAvatarPresignedUrl, options);
        const avatarUrl = `${process.env.REACT_APP_S3_BUCKET}/avatars/avatar-${filename}`;
        const response = await updateProfile({
          variables: { input: { headshot: avatarUrl } },
        });

        if (!response.data?.updateLawyer?.successful) {
          response.data?.updateLawyer?.messages?.forEach((error) => {
            toast.warning({
              title: t('common.error'),
              description: error?.message,
              isClosable: true,
            });
          });
          return;
        }

        toast.success({
          title: t('common.success'),
          description: t('profile.toast.updated_profile.title'),
          isClosable: true,
        });
      }
      setIsLoading(false);
      previewModal.onClose();
    } catch (err) {
      toast.warning({ title: 'Error', description: err.message });
      setIsLoading(false);
    }
  }, [
    isCroppable,
    selectedFile,
    previewModal,
    presignedUrl,
    toast,
    updateProfile,
    name,
    t,
  ]);

  const onChange = useCallback(
    async (e) => {
      setSelectedFile(null);
      if (!e.target.value) return;
      const file = e.target.files[0];
      setSelectedFile(
        Object.assign(file, { preview: URL.createObjectURL(file) })
      );
      previewModal.onOpen();
    },
    [previewModal]
  );

  return (
    <>
      <Box mr={4} flexShrink={0} pos="relative" overflow="hidden">
        <Avatar size={size} name={name} src={src}>
          <Box
            as="span"
            pos="absolute"
            bottom={0}
            right={0}
            d="inline-flex"
            alignItems="center"
            justifyContent="center"
            h={{ base: 6, sm: 10 }}
            w={{ base: 6, sm: 10 }}
            rounded="lg"
            border="2px"
            borderColor="white"
            bg="secondary"
          >
            <Icon as={EditIcon} h={{ base: 3, sm: 5 }} w={{ base: 3, sm: 5 }} />
          </Box>
        </Avatar>
        <Box
          as="label"
          htmlFor="user-photo"
          pos="absolute"
          inset={0}
          w="full"
          h="full"
          cursor="pointer"
        >
          <Box
            as="input"
            onChange={onChange}
            onClick={(event) => {
              // eslint-disable-next-line
              // @ts-ignore
              event.target.value = null;
            }}
            type="file"
            name="user-photo"
            pos="absolute"
            inset={0}
            h="full"
            w="full"
            opacity={0}
            cursor="pointer"
            rounded="md"
            accept="image/jpeg,image/png"
          />
        </Box>
      </Box>
      <Modal
        isOpen={previewModal.isOpen}
        onClose={previewModal.onClose}
        closeOnOverlayClick={!isLoading}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            borderBottom="1px"
            borderBottomColor="gray.300"
            fontSize="md"
          >
            {t('profile.crop_profile_picture')}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Cropper
              src={selectedFile?.preview}
              style={{ maxHeight: 400, width: '100%' }}
              aspectRatio={1}
              guides={false}
              ready={() => setIsCroppable(true)}
              viewMode={1}
              ref={cropperRef}
            />
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="primary"
              onClick={onCrop}
              isLoading={isLoading}
              fontSize="sm"
            >
              {t('profile.set_new_profile_picture')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
