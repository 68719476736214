import React, { useCallback } from 'react';
import { Avatar, Box, Icon, Text, Tag, Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as TimeIcon } from '@src/icons/clock.svg';
import DateFormat from '@src/components/atoms/DateFormat';
import { formatDuration, generateID } from '@src/utils';
import { useParams } from 'react-router-dom';
import { useRoomTokenContext } from '@src/context/RoomTokenContext';
import { useVideoContext } from '@src/context/VideoContext';
import { useGetAppointmentByRoomQuery } from '@src/apollo/hooks';
import Loader from '@atoms/Loader';

const MeetingWith = ({
  viewer,
  onClose,
}: {
  viewer: User;
  onClose?: () => void;
}) => {
  const { t } = useTranslation();
  const { getToken, loading } = useRoomTokenContext();
  const { connect, isConnecting, isAcquiringLocalStreams } = useVideoContext();
  const { meeting_id } = useParams<RoomParamTypes>();

  const disableButton = loading || isAcquiringLocalStreams || isConnecting;

  const handleJoin = useCallback(async () => {
    const userId = generateID();
    const role = viewer.roles?.includes('lawyer') ? 'lawyer' : 'user';
    const result = await getToken(
      `${userId}_${role}_${viewer.profile?.firstName} ${viewer.profile?.lastName}`,
      meeting_id
    );
    if (result.token) {
      connect(result.token);
    }
  }, [viewer, connect, getToken, meeting_id]);

  const { data, loading: meetingInfoLoading } = useGetAppointmentByRoomQuery({
    variables: {
      roomId: meeting_id,
    },
  });
  if (meetingInfoLoading) return <Loader />;

  const imUser = viewer?.email === data?.getAppointmentByRoom?.user?.email;
  const profile = data?.getAppointmentByRoom?.user?.profile;
  const host = data?.getAppointmentByRoom?.host;
  const participant = {
    firstName: imUser ? host?.firstName : profile?.firstName,
    lastName: imUser ? host?.lastName : profile?.lastName,
    headshot: imUser ? host?.headshot : profile?.avatar,
  };
  const status = data?.getAppointmentByRoom?.meeting?.status;

  const startAt = new Date(data?.getAppointmentByRoom?.times.startsAt);
  const endsAt = new Date(data?.getAppointmentByRoom?.times.endsAt);
  const isCanceled = status === 'cancelled';

  return (
    <Box
      bg="white"
      boxShadow="base"
      rounded="xl"
      minW={{ lg: 'xs' }}
      zIndex="2"
      position={{ base: 'fixed', lg: 'unset' }}
      bottom={{ base: '100', lg: 'unset' }}
      right={{ base: '10%', lg: 'unset' }}
      width="80%"
    >
      <Box p={{ base: 2, lg: 8 }} d="flex" alignItems="center" flexDir="column">
        {onClose && (
          <Button
            width="40px"
            borderRadius="20px"
            colorScheme="blue"
            position="absolute"
            top="20px"
            right="20px"
            onClick={onClose}
            boxShadow="0px 0px 18px 2px rgba(0, 0, 0, 0.12);"
          >
            X
          </Button>
        )}

        <Box d="flex" alignItems="center" p={8}>
          {participant?.headshot && (
            <Avatar
              mt={4}
              alignSelf="flex-start"
              name={participant.firstName}
              src={participant.headshot}
            />
          )}
          <Box ml={3}>
            <Text fontSize="sm" as="strong" color="blue.500">
              {t('home.meeting_with')}:
            </Text>
            <Text fontSize="lg" mt={0} fontWeight="thin" color="primary">
              {participant?.firstName} {participant?.lastName}
            </Text>
            <Text
              fontSize="sm"
              color="#6C6E70"
              transform="translateY(0.25rem)"
              fontWeight="light"
              w="max-content"
              whiteSpace="nowrap"
              d="flex"
              alignItems="center"
            >
              <Icon
                as={TimeIcon}
                w={{ base: 4, md: 6 }}
                h={{ base: 4, md: 6 }}
                mr={2}
              />
              <DateFormat date={startAt} format="p" /> -{' '}
              <DateFormat date={endsAt} format="p" />
              <Tag
                ml={3}
                variant="outline"
                colorScheme="blue"
                rounded="xl"
                textTransform="capitalize"
              >
                {formatDuration(
                  data?.getAppointmentByRoom?.meeting.duration || 0
                )}
              </Tag>
            </Text>

            {!isCanceled && (
              <Button
                mt={6}
                px={8}
                colorScheme="primary"
                onClick={handleJoin}
                isLoading={loading || isConnecting}
                isDisabled={disableButton}
              >
                {t('home.join_meeting')}
              </Button>
            )}
            {isCanceled && (
              <Box mt={4}>
                <Text fontWeight="bold" color="red.500">
                  {t('meetings.meeting_canceled')}
                </Text>
                <Button
                  as="a"
                  mt={2}
                  px={8}
                  colorScheme="primary"
                  href="/"
                  isLoading={loading || isConnecting}
                  isDisabled={disableButton}
                >
                  {t('common.home')}
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MeetingWith;
