import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import {
  Box,
  chakra,
  Spinner,
  Text,
  useMediaQuery,
  Button,
  Avatar,
  VStack,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from '@chakra-ui/react';
import {
  useGetMyPayoutsQuery,
  useGetConnectLinkMutation,
  useViewerQuery,
} from '@src/apollo/hooks';
import { useTranslation } from 'react-i18next';
import PayoutHistoryTable from '@src/components/molecules/PayoutHistoryTable';
import AppointmentsTable from '@src/components/molecules/AppointmentsTable';
import PayoutItemMobile from '@src/components/molecules/PayoutHistoryTable/PayoutItemMobile';
import { usePathIndex } from '@src/hooks';

const PayoutHistory = () => {
  const [isLargerMobile] = useMediaQuery('(min-width: 48em)');
  const { data: viewerData, loading: dataLoading } = useViewerQuery();
  const { data: transferData, fetchMore } = useGetMyPayoutsQuery({
    variables: {
      first: 100,
    },
    fetchPolicy: 'cache-and-network',
  });

  const { t } = useTranslation();
  const pageSize = 100;

  const { path } = useRouteMatch();
  const { index, onChange } = usePathIndex({
    paths: [{ path, exact: true }, { path: `${path}/scheduled` }],
    prefixLocale: false,
  });

  const [
    getConnectLinkMutation,
    { data: connectedLink, loading: connectedLinkLoading },
  ] = useGetConnectLinkMutation();

  useEffect(() => {
    getConnectLinkMutation();
  }, [getConnectLinkMutation]);

  if (connectedLinkLoading)
    return (
      <chakra.div d="flex" alignItems="center" justifyContent="center" h="full">
        <Spinner />
      </chakra.div>
    );

  const pageInfo = transferData?.getMyPayouts?.pageInfo;
  const nodes = transferData?.getMyPayouts?.edges?.map((edge) => edge?.node);
  return (
    <Box maxW="5xl" mx="auto" py={8} px={3}>
      <Box d={{ md: 'flex' }} justifyContent="space-between" w="100%" mb={4}>
        <Box d="flex" rounded="md" backgroundColor="skyBlue.50" padding={4}>
          <Avatar
            src={viewerData?.viewer?.profile?.avatar as string}
            name={viewerData?.viewer?.profile?.firstName as string}
          />
          <Box d="flex" flexDir="column" ml={4}>
            <Text fontSize="secondary">
              {viewerData?.viewer?.profile?.firstName}{' '}
              {viewerData?.viewer?.profile?.lastName}
            </Text>
          </Box>
        </Box>
        <Box>
          <Button
            as="a"
            target="_blank"
            colorScheme="primary"
            href={connectedLink?.createStripeLoginLink?.result?.url}
          >
            {t('payouts.open_stripe')}
          </Button>
        </Box>
      </Box>

      <Tabs isManual isLazy index={index} onChange={onChange}>
        <TabList
          maxW="5xl"
          mx="auto"
          flexDir={{ base: 'column', md: 'row' }}
          alignItems={{ base: 'center', md: 'normal' }}
        >
          <Tab>{t('payouts.payouts')}</Tab>
          <Tab>{t('my_meetings.scheduled_transfers')}</Tab>
        </TabList>

        <TabPanels
          pos="relative"
          _before={{
            content: '""',
            pos: 'absolute',
            insetX: 0,
            h: { base: 36, lg: 56 },
          }}
        >
          <TabPanel pos="relative">
            <Box
              d="flex"
              flexDir="column"
              justifyContent={{ md: 'space-between' }}
            >
              <Box
                backgroundColor="white"
                rounded="md"
                paddingX={2}
                paddingY={4}
              >
                <Text textStyle="secondary">
                  {t('my_payments.pending_explanation')}
                </Text>
              </Box>

              <Box alignSelf="flex-start" mt={3} w="100%">
                {isLargerMobile ? (
                  <Box d="flex" flexDir="column" pos="relative">
                    <Box overflowX="auto" insetX={0}>
                      <Box
                        mt={6}
                        py={2}
                        verticalAlign="middle"
                        display="inline-block"
                        minW="100%"
                      >
                        <Box
                          boxShadow="md"
                          overflowY="hidden"
                          overflowX="hidden"
                          borderBottomWidth="1px"
                          borderColor="gray.300"
                          rounded={{ sm: 'lg' }}
                        >
                          {nodes?.length === 0 && (
                            <Box py={4}>{t('my_payments.no_payments')}</Box>
                          )}
                          {nodes?.length !== 0 && (
                            <PayoutHistoryTable payouts={nodes || []} />
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  <VStack my={5} spacing={4}>
                    {!nodes && <Box py={4}>{t('my_payments.no_payments')}</Box>}
                    {nodes &&
                      nodes.map((item) => (
                        <PayoutItemMobile key={item?.id} payout={item} />
                      ))}
                  </VStack>
                )}
              </Box>
              <Box d="flex" justifyContent="center" mt={4}>
                {dataLoading && <Spinner size="lg" opacity={0.5} />}
                {!dataLoading && (
                  <Waypoint
                    onEnter={() => {
                      if (pageInfo?.hasNextPage) {
                        fetchMore({
                          variables: {
                            after: pageInfo.endCursor,
                            first: pageSize,
                          },
                        });
                      }
                    }}
                  >
                    <div>{pageInfo?.hasNextPage && <Spinner />}</div>
                  </Waypoint>
                )}
              </Box>
            </Box>
          </TabPanel>
          <TabPanel pos="relative">
            <Box>
              <Box
                backgroundColor="white"
                rounded="md"
                paddingX={2}
                paddingY={4}
                mb={10}
              >
                <Text textStyle="secondary">
                  {t('my_payments.scheduled_explanation')}
                </Text>
              </Box>
              <Box
                boxShadow="md"
                overflowY="hidden"
                overflowX="hidden"
                borderBottomWidth="1px"
                borderColor="gray.300"
                rounded={{ sm: 'lg' }}
              >
                <AppointmentsTable type="pending" />
              </Box>
            </Box>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default PayoutHistory;
