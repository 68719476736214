import React from 'react';
import { Box, Heading, Text, Button, Image } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useSigninModal } from '@src/context';
import Link from '@src/components/atoms/Link';

export const HeroSection: React.FC = () => {
  const { t } = useTranslation();
  const signInModal = useSigninModal();

  return (
    <Box
      w="full"
      height={{ base: 'auto', md: '500px', xl: '700px', xxl: '1200px' }}
      position="relative"
      backgroundColor="white"
      backgroundPosition={{ sm: 'right', lg: 'bottom' }}
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      backgroundImage={{
        base: 'none',
        md: "url('/images/hero-bg-man.png')",
      }}
    >
      <Box
        maxW="5xl"
        mx="auto"
        py={{ base: 10, md: 20 }}
        px={6}
        d="flex"
        flexDir={{ base: 'column', md: 'row' }}
        justifyContent="space-between"
      >
        <Box
          d="flex"
          flexDirection="column"
          alignItems="left"
          justifyContent="flex-end"
          w={{ base: '100%', md: '45%', lg: '40%' }}
          data-testid="landing-hero"
        >
          <Heading
            as="h2"
            lineHeight={{ base: 'none', md: 'base' }}
            fontSize={{ base: '2.2rem', md: '4xl' }}
            fontWeight="bold"
            textAlign={{ base: 'center', md: 'left' }}
            color="black"
            p={4}
          >
            {t('lawyer_landing.header')}
            <br />
            {t('lawyer_landing.for')}{' '}
            <Text as="span" className="text-decoration-skip-ink">
              {t('lawyer_landing.lawyers')}
            </Text>
          </Heading>
          <Text
            p={4}
            pt={2}
            color="black"
            fontWeight="bold"
            textAlign={{ base: 'center', md: 'left' }}
          >
            {t('lawyer_landing.sub_header')}
          </Text>
          <Text
            p={4}
            pt={2}
            color="black"
            textAlign={{ base: 'center', md: 'left' }}
          >
            {t('lawyer_landing.header_description')}
          </Text>
          <Box
            p={4}
            pt={0}
            justifyContent={{ base: 'center', md: 'left' }}
            d="flex"
            width="100%"
            flexDirection="column"
            alignItems={{ base: 'center', md: 'flex-start' }}
          >
            <Button
              as={Link}
              colorScheme="orange"
              variant="rooLarge"
              size="lg"
              fontSize="sm"
              mt={{ base: '2', md: '6' }}
              textTransform="uppercase"
              to="/application"
            >
              {t('common.labels.apply_now')}
            </Button>
            <Box
              as="span"
              textStyle="secondary"
              textAlign="right"
              py={{ base: 2, md: 4 }}
            >
              {t('lawyer_landing.already_member')}
              <Button
                ml={1}
                pb={{ base: 1, md: 0 }}
                color="blue.400"
                variant="unstyled"
                fontWeight="bold"
                textStyle="secondary"
                onClick={signInModal.open}
              >
                {t('common.labels.sign_in')}
              </Button>
              <Box as="span" d={{ base: 'inline', md: 'none' }} fontSize="3xl">
                &#8594;
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          d="flex"
          mt={{ base: -7, md: 'auto' }}
          mx={{ base: -6, md: 'auto' }}
          minH={{ base: '555px', md: 'auto' }}
          bg="red"
          backgroundSize="cover"
          backgroundPosition="right"
          backgroundImage={{
            base: "url('/images/hero-bg-man.png')",
            md: 'none',
          }}
          className="clip-image-bottom-right-lawyers"
        >
          <Image
            src="/images/hero-atoms.png"
            w={{ base: '320px', md: '400px' }}
            m="auto"
            ml={{ base: '4', md: 'auto' }}
            pt={{ base: 16, md: 'auto' }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default HeroSection;
