import React, { useState } from 'react';
import { chakra } from '@chakra-ui/system';
import Footer from '@src/components/organisms/RooLayout/Layout/Footer';
import Header from '@src/components/organisms/RooLayout/Layout/Header';
import { Form } from 'react-final-form';

import {
  Heading,
  HStack,
  Link,
  Icon,
  SimpleGrid,
  Text,
  VisuallyHidden,
  VStack,
  Button,
} from '@chakra-ui/react';
import { useFormValidation } from '@src/hooks';

import { TextareaField, TextField } from '@src/components/atoms/Fields';

import { useTranslation } from 'react-i18next';
import { ReactComponent as FBIcon } from '@src/icons/facebook-icon.svg';
import { ReactComponent as InIcon } from '@src/icons/in-icon.svg';

export const contactWidgetURL =
  'https://legaroohelp.freshdesk.com/widgets/feedback_widget/new?&widgetType=embedded&screenshot=No&searchArea=no&captcha=yes';

export default function ContactUs() {
  const [successSent, setSuccessSent] = useState(false);
  const { t } = useTranslation();
  const { required } = useFormValidation();

  const onSubmit = (values: any) => {
    const string = JSON.stringify(values);
    fetch(`${process.env.REACT_APP_REST_URL}/support/email`, {
      method: 'POST',
      headers: new Headers({
        Authorization: 'Bearer contact',
        'Content-Type': 'application/json',
      }),
      body: string,
    })
      .then(() => {
        setSuccessSent(true);
      })
      .catch((err) => console.log(err));
  };

  return (
    <chakra.div bg="white">
      <Header
        theme={{
          color: 'gray.900',
          backgroundColor: 'gray.200',
          colorScheme: 'blue',
        }}
      />
      <chakra.div as="main" minH="100vh">
        <chakra.div pb={52} bg="gray.200">
          <chakra.div py={16}>
            <chakra.div
              pos="relative"
              maxW="6xl"
              mx="auto"
              px={{ base: 4, sm: 6, lg: 8 }}
            >
              <Heading
                textAlign="center"
                fontWeight="extrabold"
                fontSize={{ base: '4xl', sm: '5xl', lg: '6xl' }}
                letterSpacing="-0.025em"
              >
                {t('contact.heading')}
              </Heading>
              <Text mt={6} fontSize="xl" color="gray.700" textAlign="center">
                {t('contact.sub_heading')}
              </Text>
            </chakra.div>
          </chakra.div>
        </chakra.div>
        <chakra.div mb={12}>
          <chakra.div mt={-52}>
            <chakra.div
              pos="relative"
              maxW="6xl"
              mx="auto"
              pb={12}
              px={{ base: 4, sm: 6, lg: 8 }}
            >
              <chakra.div bg="white" rounded="2xl" shadow="xl">
                <SimpleGrid columns={{ base: 1, lg: 3 }}>
                  <chakra.div
                    pos="relative"
                    overflow="hidden"
                    py={10}
                    px={{ base: 6, sm: 10 }}
                    p={{ xl: 12 }}
                    roundedTopLeft="xl"
                    roundedBottomLeft={{ lg: 'xl' }}
                    roundedTopRight={{ base: 'xl', lg: 0 }}
                    bg="linear-gradient(to left, rgb(9, 92, 215), rgb(7, 71, 166))"
                  >
                    <Heading
                      as="h3"
                      fontSize="lg"
                      fontWeight="medium"
                      color="white"
                    >
                      {t('contact.contact_information')}
                    </Heading>

                    <chakra.div as="dl" mt={6}>
                      <chakra.div as="dt">
                        <VisuallyHidden>
                          {' '}
                          {t('contact.phone_number')}
                        </VisuallyHidden>
                      </chakra.div>
                      <chakra.div
                        as="dd"
                        d="flex"
                        fontSize="base"
                        color="gray.50"
                      >
                        <Icon
                          flexShrink={0}
                          w={6}
                          h={6}
                          color="blue.200"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          {/* <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"></svg> */}
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                          />
                        </Icon>
                        <chakra.span ml={3}>+1 202 8688657</chakra.span>
                      </chakra.div>

                      <chakra.div as="dt">
                        <VisuallyHidden>Email</VisuallyHidden>
                      </chakra.div>
                      <chakra.div
                        mt={6}
                        as="dd"
                        d="flex"
                        fontSize="base"
                        color="gray.50"
                      >
                        <Icon
                          flexShrink={0}
                          w={6}
                          h={6}
                          color="blue.200"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                          />
                        </Icon>
                        <chakra.span ml={3}>support@legaroo.com</chakra.span>
                      </chakra.div>
                    </chakra.div>

                    <HStack mt={8} spacing={12}>
                      <Link
                        href="https://facebook.com/legaroo"
                        target="_blank"
                        color="blue.200"
                        _hover={{ color: 'blue.100' }}
                      >
                        <VisuallyHidden>Facebook</VisuallyHidden>
                        <Icon as={FBIcon} w={7} h={7} />
                      </Link>
                      <Link
                        href="https://cr.linkedin.com/company/legaroo"
                        target="_blank"
                        color="blue.200"
                        _hover={{ color: 'blue.100' }}
                      >
                        <VisuallyHidden>Linked in</VisuallyHidden>
                        <Icon as={InIcon} w={7} h={7} />
                      </Link>
                    </HStack>
                  </chakra.div>
                  <chakra.div
                    gridColumn={{ lg: 'span 2 / span 2' }}
                    py={10}
                    px={{ base: 6, sm: 10 }}
                    p={{ xl: 12 }}
                  >
                    <Form
                      onSubmit={onSubmit}
                      render={({ handleSubmit, submitting }) => (
                        <form
                          onSubmit={handleSubmit}
                          className="StripeCheckoutForm"
                        >
                          <VStack spacing={6} alignItems="initial">
                            <TextField
                              label={t('fields.fullname.label')}
                              height="80px"
                              name="name"
                              fieldProps={{ validate: required() }}
                            />

                            <TextField
                              label={t('fields.email.label')}
                              height="80px"
                              name="from"
                              fieldProps={{ validate: required() }}
                            />

                            <TextareaField
                              placeholder={t('fields.message.label')}
                              height="80px"
                              name="message"
                              isRequired
                            />

                            {!successSent && (
                              <Button
                                type="submit"
                                variation="solid"
                                colorScheme="primary"
                                isLoading={submitting}
                              >
                                Send
                              </Button>
                            )}
                            {successSent && (
                              <Text
                                background="green.200"
                                color="green.800"
                                rounded="md"
                                padding={4}
                              >
                                {t('common.contact_thank_you')}
                              </Text>
                            )}
                          </VStack>
                        </form>
                      )}
                    />
                  </chakra.div>
                </SimpleGrid>
              </chakra.div>
            </chakra.div>
          </chakra.div>
        </chakra.div>
      </chakra.div>
      <Footer />
    </chakra.div>
  );
}
