import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  chakra,
  SkeletonCircle,
  SkeletonText,
  useBreakpointValue,
  VStack,
  Text,
} from '@chakra-ui/react';
import { Helmet } from 'react-helmet';
import startCase from 'lodash/fp/startCase';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  DirectoryHero,
  SearchHeader,
  LawyerCard,
  Lawyer,
} from '@molecules/Directory';
import { orderBy, uniqBy } from 'lodash';
import {
  useGetCountryQuery,
  useLawyersDirectoryQuery,
} from '@src/apollo/hooks';
import { lawyerSortOptions } from '@src/content/config/lawyerConfig';

const resultsPerPage = 25;

export interface ParamTypes {
  locale?: string;
  country?: string;
  area?: string;
}

const Directory: React.FC = () => {
  const [sortByOption, setSort] = useState('price_low_high');
  const [practiceAreaFilter, setPracticeAreasFilter] = useState<{
    practiceArea: string[];
  }>({ practiceArea: [] });
  const [filterOpen, setFilterOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [displayLawyers, setDisplayLawyers] = useState<Lawyer[]>(
    [] as Lawyer[]
  );
  const { country, area, locale } = useParams<ParamTypes>();
  const { t } = useTranslation();
  const btnSize = useBreakpointValue({ base: 'md', lg: 'long' });
  const { data, loading } = useLawyersDirectoryQuery({
    variables: {
      filters: {
        countryCode: country,
        ...(area && {
          legalPractices: [area],
        }),
      },
    },
    skip: !country,
    fetchPolicy: 'no-cache',
  });

  const { data: countryData } = useGetCountryQuery({
    variables: { countryCode: country as string },
    skip: !country,
  });

  const allLawyers = data?.getLawyersDirectory ?? [];

  const allLegalPractices = useMemo(() => {
    return uniqBy(
      allLawyers.map((item) => item?.legalPractices).flat(),
      'slug'
    ) as LegalPracticeType[];
  }, [allLawyers]);

  useEffect(() => {
    const total = Math.ceil(allLawyers.length / resultsPerPage);
    setTotalPages(total);
  }, [allLawyers]);

  useEffect(() => {
    let displayLawyers = allLawyers;
    const sortSelected = lawyerSortOptions.find((sortOrder) => {
      return sortOrder.name === sortByOption;
    });
    if (sortSelected) {
      displayLawyers = orderBy(
        displayLawyers,
        sortSelected.by,
        sortSelected.order
      );
    }
    if (practiceAreaFilter.practiceArea.length > 0) {
      displayLawyers = displayLawyers.filter((item) =>
        item?.legalPractices.some((legalPractice) =>
          practiceAreaFilter.practiceArea.includes(
            legalPractice?.slug as string
          )
        )
      );
    }
    displayLawyers = displayLawyers.slice(0, currentPage * resultsPerPage);
    setDisplayLawyers(displayLawyers as Lawyer[]);
  }, [allLawyers, practiceAreaFilter, sortByOption, currentPage]);

  const onPracticeAreaChange = useCallback((values) => {
    setPracticeAreasFilter(values);
    setFilterOpen(false);
  }, []);

  const onSortByChange = useCallback((value) => {
    setSort(value);
    setFilterOpen(false);
  }, []);

  const showMore = totalPages > currentPage;

  const pageTitle = useMemo(() => {
    const mainTitle = [
      'Legaroo Meets ',
      t('seo.directory_title'),
      t(`seo.country.${country}`),
      ' ',
    ];
    if (area) {
      mainTitle.push(t(`seo.specialty_in`));
      mainTitle.push(t(`practice_areas.${area}`, startCase(area)));
    }

    return mainTitle;
  }, [t, area, country]);

  return (
    <div className="internal-page ">
      <Helmet>
        <title>{pageTitle}</title>
        <link
          rel="canonical"
          href={`http://meets.legaroo.com/${locale}/directory/${country}/${area}`}
        />
      </Helmet>
      <DirectoryHero />
      <Box
        backgroundColor="blue.50"
        backgroundPosition="center"
        w="full"
        h="auto"
        minHeight="100vh"
        zIndex={2}
      >
        <Box maxW="6xl" mx="auto" px={6}>
          <Box
            minH="sm"
            position="relative"
            top={-120}
            d="flex"
            w="full"
            flexDirection="column"
            justifyContent="top"
            bgColor="white"
            padding={{ base: 6, md: 50 }}
            rounded={15}
            shadow={'0px 0px 40px rgba(0, 0, 0, 0.15)'}
          >
            <SearchHeader
              selectedSortBy={sortByOption}
              onSortByChange={onSortByChange}
              filterOpen={filterOpen}
              setFilterOpen={setFilterOpen}
              showingAmount={displayLawyers.length}
              totalItems={allLawyers.length}
              activeCountry={countryData?.getCountryByCode?.name}
              onPracticeAreaChange={onPracticeAreaChange}
              selectedPracticeArea={practiceAreaFilter}
              allLegalPractices={allLegalPractices}
              activeArea={area}
            />
            {loading ? (
              <chakra.div
                mt={8}
                d="flex"
                flexDirection={{ base: 'column', lg: 'row' }}
                boxShadow="0px 0px 18px 2px rgba(0, 0, 0, 0.12);"
                borderRadius={8}
                p={{ base: 6, md: 8, lg: 10 }}
              >
                <SkeletonCircle size="20" />
                <SkeletonText mt="4" noOfLines={4} spacing="4" />
              </chakra.div>
            ) : displayLawyers.length > 0 ? (
              <Box mt={8} opacity={filterOpen ? '0.3' : '1'}>
                <VStack spacing={{ base: 6, lg: 12 }} alignItems="initial">
                  {displayLawyers.map((lawyer, index) => {
                    return <LawyerCard lawyer={lawyer} key={index} />;
                  })}
                </VStack>
                {showMore && (
                  <Box
                    mt={{ base: 6, lg: 10 }}
                    d="flex"
                    justifyContent="center"
                  >
                    <Button
                      variant="outline"
                      colorScheme="orange"
                      size={btnSize}
                      fontSize={{ base: 'sm', lg: 'md' }}
                      px={8}
                      onClick={() => {
                        setCurrentPage(currentPage + 1);
                      }}
                    >
                      {t('common.labels.load_more')}
                    </Button>
                  </Box>
                )}
              </Box>
            ) : (
              <chakra.div
                d="flex"
                alignItems="center"
                justifyContent="center"
                h="full"
                mt={24}
              >
                <Text fontSize={{ base: 'md', sm: 'lg' }} fontWeight="semibold">
                  {t('directory.no_lawyers_found', {
                    country: countryData?.getCountryByCode?.name,
                  })}
                </Text>
              </chakra.div>
            )}
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default Directory;
