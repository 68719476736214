import { useEffect, useState } from 'react';
import { useVideoContext } from '@src/context/VideoContext';

type RoomStateType = 'disconnected' | 'connected' | 'reconnecting';

export default function useRoomState() {
  const { meeting } = useVideoContext();
  const [state, setState] = useState<RoomStateType>('disconnected');

  useEffect(() => {
    if (meeting) {
      const setRoomState = () => setState(meeting.state as RoomStateType);
      setRoomState();
      meeting
        .on('disconnected', setRoomState)
        .on('reconnected', setRoomState)
        .on('reconnecting', setRoomState);
      return () => {
        meeting
          .off('disconnected', setRoomState)
          .off('reconnected', setRoomState)
          .off('reconnecting', setRoomState);
      };
    }
  }, [meeting]);

  return state;
}
