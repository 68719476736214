import React, { useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { Form, Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Text,
  Stack,
  VStack,
  Button,
  Checkbox,
  Icon,
} from '@chakra-ui/react';
import {
  TextField,
  Select,
  Phone,
  PasswordField,
} from '@src/components/atoms/Fields';
import { useGetAllMeetsCountriesQuery } from '@src/apollo/hooks';
import { stripePromise } from '@src/utils';
import titleOptions from '@src/content/titleOptions';
import { ReactComponent as CheckIcon } from '@src/icons/circle-check.svg';

// import PaymentMethod from './ApplicationPaymentMethod';
import {
  LawyerApplicationProps,
  LawyerProfileFormValues,
} from './LawyerApplicationType';
import { useFormValidation } from '@src/hooks';

const LawyerApplicationForm = ({
  viewer,
  onSaveData,
}: LawyerApplicationProps) => {
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const { data } = useGetAllMeetsCountriesQuery();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // const childRef = useRef<any>(null);
  const { t } = useTranslation();
  const { validPhone } = useFormValidation();

  const onSubmit = (values: LawyerProfileFormValues) => {
    // checkPaymentMethod().then((method) => {
    const withDefaultValues = {
      ...values,
      spokenLanguages: '{"codes":["es"]}',
      workingHours:
        '[{name: "monday", from: 8, to: 15},{name: "tuesday", from: 8, to: 15}, {name: "wednesday", from: 8, to: 15},{name: "thursday", from: 8, to: 15},{name: "friday", from: 8, to: 15}]',
      legalPractices: '{"slugs": ["administrative-law"]}',
    };
    const applicationValues = {
      input: {
        ...withDefaultValues,
      },
      // paymentMethod: method,
    };
    window.scrollTo(0, 0);
    onSaveData(applicationValues);
    // });
  };

  let initialValues = {};

  if (viewer && viewer.profile) {
    initialValues = {
      firstName: viewer.profile.firstName,
      lastName: viewer.profile.lastName,
      email: viewer.email,
    };
  }

  // const checkPaymentMethod = async () => {
  //   if (childRef && childRef.current) {
  //     return await childRef.current.processMethod();
  //   }
  // };
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={(values) => {
        const errors: any = {};
        if (!values.phone) {
          errors.phone = 'Requiered Field!';
        }
        return errors;
      }}
      render={({ handleSubmit, submitting }) => (
        <Box as="form" p={{ base: 4, md: 10 }} onSubmit={handleSubmit}>
          <Box mb={4} display="flex" flexDir={{ base: 'column', md: 'row' }}>
            <Box mr={4} display="flex" flexDir="column">
              <Text fontWeight="bold" mb={4}>
                {t('lawyer_application.step1_title')}
              </Text>
              <Text whiteSpace="pre-wrap">
                {t('lawyer_application.description')}
              </Text>
            </Box>
            <Box
              as="img"
              position="relative"
              height="40%"
              width="40%"
              marginTop={'-190px'}
              marginRight="-40px"
              alt="laptop-during-call"
              src="/images/computer_application.png"
              display={{ base: 'none', md: 'inherit' }}
            />
          </Box>
          <Box pb={8}>
            <Text
              textAlign="center"
              fontSize="2xl"
              textTransform="uppercase"
              fontWeight="bold"
            >
              {t('lawyer_application.easy_steps')}
            </Text>
            <Box width="96px" height="6px" bg={'yellow.500'} margin="auto">
              {' '}
            </Box>
          </Box>
          <Box pb={8} textTransform="uppercase">
            <Text
              textAlign="left"
              fontSize="2xl"
              textTransform="uppercase"
              fontWeight="bold"
              color={'gray.300'}
            >
              {t('lawyer_application.step1')}
              {'  '}
              <Text as="span" color={'gray.500'}>
                {t('lawyer_application.basic_information')}
              </Text>
            </Text>
          </Box>

          <VStack spacing={4} alignItems="flex-start">
            <Stack direction={['column', 'row']} w="full">
              <TextField
                isRequired
                isDisabled={viewer ? true : false}
                name="firstName"
                label={t('fields.firstName.label')}
              />
              <TextField
                isRequired
                isDisabled={viewer ? true : false}
                name="lastName"
                label={t('fields.lastName.label')}
              />
            </Stack>
            <Stack direction={['column', 'row']} w="full">
              <TextField
                isRequired
                isDisabled={viewer ? true : false}
                type="email"
                name="email"
                label={t('fields.email.label')}
              />
              <Field
                component={Phone}
                name="phone"
                label={t('fields.phone.label')}
                smartCaret={false}
                placeholder={t('fields.phone.placeholder')}
                fieldProps={{ validate: validPhone() }}
              />
            </Stack>
            {!viewer && (
              <PasswordField
                isRequired
                placeholder={t('fields.password.placeholder')}
                label={t('fields.password.label')}
              />
            )}
            <Stack direction={['column', 'row']} w="full">
              <Select
                name="title"
                label={t('fields.title.label')}
                placeholder={t('fields.title.placeholder')}
                isRequired
              >
                {titleOptions.titleOptions.map((option: string) => {
                  return (
                    <Box key={option} as="option" value={option}>
                      {t(`fields.title_options.${option}`)}
                    </Box>
                  );
                })}
              </Select>

              <Select
                name="countryId"
                label={t('fields.country.label')}
                fieldProps={{ parse: (value) => value }}
                placeholder={t('fields.country.placeholder')}
                isRequired
              >
                <Box as="option" disabled hidden d="none" value=""></Box>
                {data?.getAllMeetsCountries?.map((country) => {
                  if (!country) return null;
                  return (
                    <Box as="option" key={country.id} value={country.code}>
                      {country.name}
                    </Box>
                  );
                })}
              </Select>
            </Stack>
          </VStack>
          <Box width="100%" justifyContent="center" py={12}>
            <Text color="black" fontSize="2xl" as="strong">
              {t('lawyer_application.application_fee')}
              <br />
            </Text>
            <Box
              display="flex"
              flexDir={{ base: 'column', md: 'row' }}
              width="100%"
              padding={4}
              m="auto"
              justifyContent="space-between"
            >
              <Box width={{ base: '100%', md: '45%' }}>
                <Icon
                  color={'yellow.300'}
                  boxSize="25px"
                  mr={2}
                  as={CheckIcon}
                  position="relative"
                  left="-35px"
                  top="18px"
                />
                <Text mb={6} textAlign="justify">
                  {t('lawyer_application.application_fee_description3')}
                </Text>
              </Box>

              <Box width={{ base: '100%', md: '45%' }} textAlign="justify">
                <Icon
                  color={'yellow.300'}
                  boxSize="25px"
                  w={'25px'}
                  mr={2}
                  as={CheckIcon}
                  position="relative"
                  left="-35px"
                  top="18px"
                />

                <Text mb={6}>
                  {t('lawyer_application.application_fee_description4')}
                </Text>
                <Box ml={10}>
                  <ul>
                    <li>
                      <Text mb={6}>
                        {t('lawyer_application.application_fee_description6')}
                      </Text>
                    </li>
                    <li>
                      <Text mb={6}>
                        {t('lawyer_application.application_fee_description7')}
                      </Text>
                    </li>
                  </ul>
                </Box>
              </Box>
            </Box>

            <Elements stripe={stripePromise}>
              <Box d="flex" flexDir="column">
                {/* <PaymentMethod ref={childRef} /> */}
                <Box width="100%" py={8} margin="auto">
                  <Text color="black" fontSize="2xl" mb={6} as="strong" mt={12}>
                    {t('lawyer_application.disclaimer')}
                  </Text>
                  <Text fontSize="sm">
                    {t('lawyer_application.disclaimer_description')}
                  </Text>
                </Box>
                <Box
                  d="flex"
                  justifyContent="center"
                  padding={2}
                  my={2}
                  borderRadius={16}
                >
                  <Checkbox
                    checked={acceptedTerms}
                    mr={2}
                    borderColor={'blue.500'}
                    onChange={(e) => setAcceptedTerms(e.target.checked)}
                  >
                    <Text color={'gray.500'}>
                      {t('lawyer_application.by_applying')}{' '}
                      <Text
                        as="a"
                        href="/lawyer-terms"
                        target="_blank"
                        color={'blue.500'}
                        fontWeight="semibold"
                      >
                        {t('common.termsOfUse')}
                      </Text>{' '}
                      &amp;{' '}
                      <Text
                        as="a"
                        href="/lawyer-privacy-policy"
                        target="_blank"
                        color={'blue.500'}
                        fontWeight="semibold"
                      >
                        {t('common.privacy_policy')}
                      </Text>
                    </Text>
                  </Checkbox>
                </Box>
                <Button
                  alignSelf="center"
                  colorScheme="orange"
                  isLoading={submitting}
                  px={10}
                  disabled={!acceptedTerms}
                  type="submit"
                >
                  {t('lawyer_application.create_application')}
                </Button>
              </Box>
            </Elements>
          </Box>
        </Box>
      )}
    />
  );
};

export default LawyerApplicationForm;
