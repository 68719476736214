import React from 'react';
import {
  ReasonsSection,
  HeroSection,
  HowItWorkSection,
} from '@components/molecules/LawyerLandingSection';
import Layout from '@src/components/organisms/RooLayout/Layout';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
const LawyerLanding: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Layout theme="light">
      <Helmet>
        <title>{t('seo.lawyer_title')}</title>
        <meta name="description" content={t('seo.lawyer_description')} />
        <link rel="canonical" href={t('seo.canonical.lawyers')} />
      </Helmet>
      <div className="landing-page ">
        <HeroSection />
        <HowItWorkSection />
        <ReasonsSection />
      </div>
    </Layout>
  );
};

export default LawyerLanding;
